.languageSetting {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px 0px;
  border-bottom: 1px solid #dedfe3;
}

.settingsActionGroup {
  display: flex;
  align-items: center;
}

.removeBtn:hover {
  background-color: #f0f0f0;
}

.removeBtn {
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  padding: 10px 10px;
  border-radius: 100%;
  margin-left: 20px;
}
