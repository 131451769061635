@import '../../foundations/theme/variables';

.container {
  display: flex;

  .backgroundCircle {
    stroke: var(--progress-circle-color-bg-progress-circle-high-contrast);
  }

  .loader {
    position: relative;
    display: inline-block;
    color: var(--progress-circle-color-bg-progress-circle-primary-active);
    transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transform: rotate(-100deg);
    width: 20px;

    .circle {
      stroke: currentColor;
      -webkit-transition: stroke-dashoffset 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      transition: stroke-dashoffset 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }
}
