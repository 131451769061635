.tiptap {
  padding: 1rem 2rem;
  min-height: 300px;
  outline: none;

  &:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.01);
  }

  > * + * {
    margin-top: 0.75em;
  }

  h1 {
    font-size: 2em;
    font-weight: bold;
  }

  h2 {
    font-size: 1.5em;
    font-weight: bold;
  }

  h3 {
    font-size: 1.25em;
    font-weight: bold;
  }

  h4 {
    font-size: 1.1em;
    font-weight: bold;
  }

  p {
    margin: 1em 0;
    line-height: 1.6;
  }

  ul,
  ol {
    padding: 0 1rem;

    li {
      margin: 0.5em 0;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  code {
    background-color: #2d2d2d;
    color: #fff;
    padding: 0.2rem 0.4rem;
    border-radius: 0.25rem;
    font-size: 0.9em;
    font-family: 'Courier New', Courier, monospace;
  }

  pre {
    background: #2d2d2d;
    color: #fff;
    padding: 1rem;
    border-radius: 0.5rem;
    font-family: 'Courier New', Courier, monospace;
    margin: 1rem 0;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.9em;
    }
  }

  blockquote {
    border-left: 3px solid #e2e8f0;
    padding-left: 1rem;
    margin: 1rem 0;
    color: #4a5568;
    font-style: italic;
  }

  hr {
    border: none;
    border-top: 2px solid #e2e8f0;
    margin: 2rem 0;
  }

  a {
    color: #6c5ce7;
    text-decoration: underline;

    &:hover {
      color: darken(#6c5ce7, 10%);
    }
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  img {
    max-width: 100%;
    height: auto;
    margin: 1rem 0;
  }

  .article-link {
    color: #6c5ce7;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: darken(#6c5ce7, 10%);
    }
  }
}

// For placeholder text when editor is empty
.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
