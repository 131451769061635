@import '@pray/shared/components/foundations';

.translation {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  gap: 6px;

  width: auto;
  height: 24px;

  background: #F2F3F4;
  border-radius: 100px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.translationIcon {
  width: 16px;
  height: 12px;
  left: 0px;
  top: 0px;
}

span.translationExtra {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 6px;
  gap: 10px;
  height: 24px;

  background: #DEDFE3;
  border-radius: 100px;

  color: #0B0C0D;
}