@import '@pray/shared/components/foundations';

a.tab,
button.tab {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 24px;
  margin: 4px 12px;
  border-radius: $radius_small;
  text-transform: none;

  &.active {
    background: #f2f2f2;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    margin-right: 12px;
  }

  &:not([disabled]) {
    &:hover {
      background: #f2f3f4;
    }
  }

  &[disabled] {
    outline: none;
    background: transparent;
  }
}
