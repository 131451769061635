.container {
  position: absolute;
  bottom: 24px;
  right: 24px;
  min-width: 348px;
  background-color: white;
  z-index: 10;

  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.16));
  border-radius: 6px;

  .header {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    background: #0b0c0d;
    color: white;

    .title {
      display: flex;
      align-items: center;

      img,
      svg {
        margin-left: 8px;
      }
    }

    .buttons {
      display: flex;
      align-items: center;
    }
  }

  &.minimized {
    .header {
      border-radius: 6px;
    }

    .buttons {
      .minimizeMaximizeButton {
        transform: rotate(180deg);
      }
    }
  }

  .error {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffefed;
    border-bottom: 1px solid #fed7d2;
    padding: 0px 12px;

    & > span {
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: #5a0a00;
    }

    button {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .body {
    max-height: 278px;
    overflow-y: auto;

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 55px;
      padding: 0 8px;

      &:not(:last-of-type) {
        border-bottom: 1px solid #d0d1d5;
      }

      &:hover {
        background-color: rgba(203, 205, 210, 0.2);
      }

      .info {
        display: flex;
        align-items: center;

        .icon {
          background-color: #f9ce8e;
          border-radius: 2px;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 8px 0 4px;
        }
      }

      .rowStatus {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 48px;
      }
    }
  }
}
