a.backButton,
button.backButton {
  /* State=Default */

  /* Auto layout */
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 4px 8px 4px 4px;
  gap: 16px;

  position: inherit;
  width: auto;
  height: 32px;
  left: 20px;
  top: 20px;
  margin-bottom: 16px;

  background: #ffffff;
  color: #0b0c0d;
  border-radius: 6px;

  &:hover {
    background: #f2f3f4;
  }

  &:active {
    background: #dedfe3;
  }
}
