:root {
  /* colors */
  --color-black: #000000;
  --color-white: #ffffff;
  --color-blue-100: #c5d0fb;
  --color-blue-200: #90a9f8;
  --color-blue-300: #638cf8;
  --color-blue-400: #387af0;
  --color-blue-50: #f1f3fe;
  --color-blue-500: #2261d3;
  --color-blue-600: #184087;
  --color-blue-700: #082559;
  --color-blue-800: #061e4c;
  --color-blue-900: #010b23;
  --color-gold-100: #f9ce8e;
  --color-gold-200: #e3af4a;
  --color-gold-300: #b38937;
  --color-gold-400: #947333;
  --color-gold-50: #fdf2e5;
  --color-gold-500: #775a22;
  --color-gold-600: #4d3a13;
  --color-gold-700: #2d2008;
  --color-gold-800: #241a05;
  --color-gold-900: #120b02;
  --color-gray-100: #dedfe3;
  --color-gray-200: #a6a8ad;
  --color-gray-300: #8c8f96;
  --color-gray-400: #71747b;
  --color-gray-50: #f2f3f4;
  --color-gray-500: #56585e;
  --color-gray-600: #3a3c40;
  --color-gray-700: #1f2024;
  --color-gray-800: #18191c;
  --color-gray-900: #0b0c0d;
  --color-green-100: #b6e2cb;
  --color-green-200: #85c2a1;
  --color-green-300: #52b27f;
  --color-green-400: #1cab5f;
  --color-green-50: #ecf9ed;
  --color-green-500: #188149;
  --color-green-600: #0b5b30;
  --color-green-700: #04341a;
  --color-green-800: #052916;
  --color-green-900: #010f08;
  --color-orange-100: #f7c1b5;
  --color-orange-200: #f68f6f;
  --color-orange-300: #e57038;
  --color-orange-400: #c95b22;
  --color-orange-50: #fdf1ef;
  --color-orange-500: #a34515;
  --color-orange-600: #6b2d0d;
  --color-orange-700: #441803;
  --color-orange-800: #381100;
  --color-orange-900: #1b0601;
  --color-pink-100: #fdbcc8;
  --color-pink-200: #fb839f;
  --color-pink-300: #fa5686;
  --color-pink-400: #ea3979;
  --color-pink-50: #fef0f2;
  --color-pink-500: #da1b60;
  --color-pink-600: #84153d;
  --color-pink-700: #5c0523;
  --color-pink-800: #48051b;
  --color-pink-900: #210109;
  --color-purple-100: #d2c7f9;
  --color-purple-200: #b097fc;
  --color-purple-300: #9872f8;
  --color-purple-400: #8854f2;
  --color-purple-50: #f4f2fe;
  --color-purple-500: #7c32e6;
  --color-purple-600: #541aa8;
  --color-purple-700: #360a76;
  --color-purple-800: #2c0665;
  --color-purple-900: #12022f;
  --color-red-100: #fcbebe;
  --color-red-200: #fa8686;
  --color-red-300: #fa6061;
  --color-red-400: #ea4345;
  --color-red-50: #fef0f0;
  --color-red-500: #e71d20;
  --color-red-600: #aa0e0f;
  --color-red-700: #670405;
  --color-red-800: #540304;
  --color-red-900: #220101;
  --color-system-crome-focus: #5e9ed6;
  --color-system-crome-highlight: #accef7;
  --color-tuquoise-100: #ade1df;
  --color-tuquoise-200: #7ebebe;
  --color-tuquoise-300: #4ba9a8;
  --color-tuquoise-400: #239998;
  --color-tuquoise-50: #dcf9f9;
  --color-tuquoise-500: #1a7a79;
  --color-tuquoise-600: #144d4c;
  --color-tuquoise-700: #042f2e;
  --color-tuquoise-800: #042525;
  --color-tuquoise-900: #010e0e;

  --opacity-10-on-dark: #ffffff1a;
  --opacity-10-on-light: #0b0c0d1a;
  --opacity-5-on-dark: #ffffff0d;
  --opacity-5-on-light: #0b0c0d0d;

  /* numbers */
  --font-wide: 0.03125rem;

  --font-line-height-1: 1rem; // 16px
  --font-line-height-2: 1.25rem; // 20px
  --font-line-height-3: 1.5rem; // 24px
  --font-line-height-4: 1.75rem; // 28px
  --font-line-height-5: 2rem; // 32px
  --font-line-height-6: 2.5rem; // 40px
  --font-line-height-7: 3rem; // 48px
  --font-line-height-8: 3.5rem; // 56px

  --font-size-1: 0.75rem; // 12px
  --font-size-2: 0.875rem; // 14px
  --font-size-3: 1rem; // 16px
  --font-size-4: 1.25rem; // 20px
  --font-size-5: 1.5rem; // 24px
  --font-size-6: 1.75rem; // 28px
  --font-size-7: 2rem; // 32px
  --font-size-8: 2.5rem; // 40px
  --font-size-9: 3rem; // 48px
  --font-size-10: 4rem; // 64px
  --font-size-11: 4.75rem; // 76px
  --font-size-12: 6rem; // 96px
  --font-size-13: 7.5rem; // 120px

  --font-weight-regular: 400; // normal
  --font-weight-medium: 500; // medium
  --font-weight-bold: 700; // bold

  --radius-small: 0.375rem; // 6px
  --radius-medium: 0.75rem; // 12px
  --radius-large: 1.5rem; // 24px
  --radius-round: 624.9375rem; // 9999px

  --scale-4: 0.25rem; // 4px
  --scale-6: 0.375rem; // 6px
  --scale-8: 0.5rem; // 8px
  --scale-12: 0.75rem; // 12px
  --scale-16: 1rem; // 16px
  --scale-20: 1.25rem; // 20px
  --scale-24: 1.5rem; // 24px
  --scale-32: 2rem; // 32px
  --scale-40: 2.5rem; // 40px
  --scale-56: 3.5rem; // 56px
  --scale-64: 4rem; // 64px
  --scale-72: 4.5rem; // 72px
  --scale-180: 11.25rem; // 180px
}