@import '@pray/shared/components/foundations';

.contentTable tr.dragging {
  td {
    background: rgba(238, 238, 240, 0.6);
  }
}

.contentTable tr {
  box-sizing: border-box;

  align-items: center;
  padding: 16px 4px;

  width: 1160px;
  height: 76px;

  background: #ffffff;
  border-bottom: 1px solid #e7e7e7;
}

a.actionButton,
button.actionButton {
  background-color: #fff;
  border-radius: 50%;
  padding: 8px;
}

a.publishButton,
a.unpublishButton,
button.publishButton,
button.unpublishButton {
  margin-left: 12px;
  padding: 8px;
  width: 90px;
}

a.publishButton,
button.publishButton {
  color: #fff;
  background-color: #0b0c0d !important;
}

.customTooltip {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 8px 8px 8px;
  gap: 8px;

  position: absolute;

  width: 296px !important;
  height: auto;
  left: 350px;
  top: 212px;

  background: #ffffff !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 1px 2px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}