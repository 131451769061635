@import '@pray/shared/components/foundations';

.episodeInfo {
  width: 100%;
  box-sizing: border-box;
}

.episodeInfoMinimized {
  display: flex;
  flex-direction: column;
  padding: 0;
  z-index: 10;
  flex: 1;
}

.title {
  z-index: 1;
  margin-bottom: 5px;
  @include typography_h2;
  @include multiline_ellipsis(1);
}

.episodeInfoMinimized .title {
  margin-bottom: 2px;
  @include typography_subhead_medium;
}

.contentSeriesTitle {
  @include typography_subhead_small;
  @include multiline_ellipsis(1);
  z-index: 1;
}

.episodeInfoMinimized .contentSeriesTitle {
  @include typography_caption_medium;
}

@media (min-width: 1024px) {
  .episodeInfoMinimized {
    flex: 1;
  }

  .title {
    @include typography_h1;
  }
}

@media (min-width: 1400px) {
  .episodeInfoMinimized {
    flex: 1;
  }

  .title {
    @include typography_hero;
  }
}
