@import '../variables';

.typography {
  font-family: var(--font-family-default);
  font-style: normal;
  font-weight: normal;
  display: inline-block;

  // variant styles

  &.caption_small {
    @include typography_caption_small;
  }

  &.caption_link {
    @include typography_caption_link;
  }

  &.caption_medium {
    @include typography_caption_medium;
  }

  &.footnote,
  &.footnote_link {
    @include typography_footnote;
  }

  &.button_small {
    @include typography_button_small;
  }

  &.button_medium {
    @include typography_button_medium;
  }

  &.button_large {
    @include typography_button_large;
  }

  &.button_full {
    @include typography_button_full;
  }

  &.subhead_small {
    @include typography_subhead_small;
  }

  &.subhead_medium {
    @include typography_subhead_medium;
  }

  &.body_small {
    @include typography_body_small;
  }

  &.body,
  &.body_link {
    @include typography_body;
  }

  &.headline_small {
    @include typography_headline_small;
  }

  &.headline_medium {
    @include typography_headline_medium;
  }

  &.headline_large {
    @include typography_headline_large;
  }

  &.h2 {
    @include typography_h2;
  }

  &.h1 {
    @include typography_h1;
  }

  &.hero {
    @include typography_hero;
  }

  &.display_small {
    @include typography_display_small;
  }

  &.display_medium {
    @include typography_display_medium;
  }

  &.display_medium {
    @include typography_display_medium;
  }

  &.daily_prayer,
  &.transcript {
    font-weight: bold;
    font-size: 26px;
    line-height: 40px;

    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  }

  // colors

  &.text_primary,
  &.text_primary * {
    color: var(--text-primary-color);
  }

  &.text_secondary,
  &.text_secondary * {
    color: var(--text-secondary-color);
  }

  &.text_tertiary,
  &.text_tertiary * {
    color: var(--text-tertiary-color);
  }

  &.text_featured,
  &.text_featured * {
    color: var(--pray-featured-color);
  }

  &.text_muted,
  &.text_muted * {
    color: var(--text-muted-color);
  }

  &.text_disabled,
  &.text_disabled * {
    color: var(--color-text-disabled);
  }

  &.attention {
    color: var(--text-error-color);
  }

  &.success {
    color: var(--text-success-color);
  }

  &.heading-5xl {
    font-weight: var(--type-scale-heading-5xl-font-weight);
    font-size: var(--type-scale-heading-5xl-font-size);
    line-height: var(--type-scale-heading-5xl-line-height);
  }

  &.heading-4xl {
    font-weight: var(--type-scale-heading-4xl-font-weight);
    font-size: var(--type-scale-heading-4xl-font-size);
    line-height: var(--type-scale-heading-4xl-line-height);
  }

  &.heading-3xl {
    font-weight: var(--type-scale-heading-3xl-font-weight);
    font-size: var(--type-scale-heading-3xl-font-size);
    line-height: var(--type-scale-heading-3xl-line-height);
  }

  &.heading-2xl {
    font-weight: var(--type-scale-heading-2xl-font-weight);
    font-size: var(--type-scale-heading-2xl-font-size);
    line-height: var(--type-scale-heading-2xl-line-height);
  }

  &.heading-xl {
    font-weight: var(--type-scale-heading-xl-font-weight);
    font-size: var(--type-scale-heading-xl-font-size);
    line-height: var(--type-scale-heading-xl-line-height);
  }

  &.heading-lg {
    font-weight: var(--type-scale-heading-lg-font-weight);
    font-size: var(--type-scale-heading-lg-font-size);
    line-height: var(--type-scale-heading-lg-line-height);
  }

  &.heading-md {
    font-weight: var(--type-scale-heading-md-font-weight);
    font-size: var(--type-scale-heading-md-font-size);
    line-height: var(--type-scale-heading-md-line-height);
  }

  &.heading-sm {
    font-weight: var(--type-scale-heading-sm-font-weight);
    font-size: var(--type-scale-heading-sm-font-size);
    line-height: var(--type-scale-heading-sm-line-height);
  }

  &.body-lg {
    font-weight: var(--type-scale-body-lg-font-weight);
    font-size: var(--type-scale-body-lg-font-size);
    line-height: var(--type-scale-body-lg-line-height);
  }

  &.body-md {
    font-weight: var(--type-scale-body-md-font-weight);
    font-size: var(--type-scale-body-md-font-size);
    line-height: var(--type-scale-body-md-line-height);
  }

  &.body-sm {
    font-weight: var(--type-scale-body-sm-font-weight);
    font-size: var(--type-scale-body-sm-font-size);
    line-height: var(--type-scale-body-sm-line-height);
  }
}

a,
button {
  .body_link,
  .caption_link,
  .footnote_link {
    text-decoration-line: underline;

    &:hover {
      opacity: 0.8;
    }
  }
}
