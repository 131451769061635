@import '../variables';

:root {
  // Font Family
  --font-family-default: 'Satoshi', sans-serif;

  // Letter Spacing
  --letter-spacing-button: 0.031rem; /* 0.5px */

  // Text Transformations
  --text-transform-button: uppercase;

  // Text Decoration
  --text-decoration-link: underline;
}

@media (min-width: $xs) {
  :root {
    // Mobile/Heading/5xl
    --heading-5xl-weight: var(--font-weight-bold);
    --heading-5xl-font-size: var(--font-size-8);
    --heading-5xl-line-height: var(--font-line-height-7);

    // Mobile/Heading/4xl
    --heading-4xl-weight: var(--font-weight-bold);
    --heading-4xl-font-size: var(--font-size-7);
    --heading-4xl-line-height: var(--font-line-height-6);

    // Mobile/Heading/3xl
    --heading-3xl-weight: var(--font-weight-bold);
    --heading-3xl-font-size: var(--font-size-5);
    --heading-3xl-line-height: var(--font-line-height-4);

    // Mobile/Heading/2xl
    --heading-2xl-weight: var(--font-weight-bold);
    --heading-2xl-font-size: var(--font-size-5);
    --heading-2xl-line-height: var(--font-line-height-4);

    // Mobile/Heading/xl
    --heading-xl-weight: var(--font-weight-bold);
    --heading-xl-font-size: var(--font-size-4);
    --heading-xl-line-height: var(--font-line-height-3);

    // Mobile/Heading/lg
    --heading-lg-weight: var(--font-weight-bold);
    --heading-lg-font-size: var(--font-size-3);
    --heading-lg-line-height: var(--font-line-height-3);

    // Mobile/Heading/md
    --heading-md-weight: var(--font-weight-medium);
    --heading-md-font-size: var(--font-size-2);
    --heading-md-line-height: var(--font-line-height-2);

    // Mobile/Heading/sm
    --heading-sm-weight: var(--font-weight-medium);
    --heading-sm-font-size: var(--font-size-1);
    --heading-sm-line-height: var(--font-line-height-1);

    // Mobile/Body/lg
    --body-lg-weight: var(--font-weight-regular);
    --body-lg-font-size: var(--font-size-3);
    --body-lg-line-height: var(--font-line-height-3);

    // Mobile/Body/md
    --body-md-weight: var(--font-weight-regular);
    --body-md-font-size: var(--font-size-2);
    --body-md-line-height: var(--font-line-height-2);

    // Mobile/Body/sm
    --body-sm-weight: var(--font-weight-regular);
    --body-sm-font-size: var(--font-size-1);
    --body-sm-line-height: var(--font-line-height-1);
  }
}

@media (min-width: $md) {
  :root {
    // Tablet & Desktop/Heading/5xl
    --heading-5xl-weight: var(--font-weight-bold);
    --heading-5xl-font-size: var(--font-size-9);
    --heading-5xl-line-height: var(--font-line-height-8);

    // Tablet & Desktop/Heading/4xl
    --heading-4xl-weight: var(--font-weight-bold);
    --heading-4xl-font-size: var(--font-size-8);
    --heading-4xl-line-height: var(--font-line-height-7);

    // Tablet & Desktop/Heading/3xl
    --heading-3xl-weight: var(--font-weight-bold);
    --heading-3xl-font-size: var(--font-size-7);
    --heading-3xl-line-height: var(--font-line-height-6);

    // Tablet & Desktop/Heading/2xl
    --heading-2xl-weight: var(--font-weight-bold);
    --heading-2xl-font-size: var(--font-size-6);
    --heading-2xl-line-height: var(--font-line-height-5);

    // Tablet & Desktop/Heading/xl
    --heading-xl-weight: var(--font-weight-bold);
    --heading-xl-font-size: var(--font-size-5);
    --heading-xl-line-height: var(--font-line-height-4);

    // Tablet & Desktop/Heading/lg
    --heading-lg-weight: var(--font-weight-bold);
    --heading-lg-font-size: var(--font-size-4);
    --heading-lg-line-height: var(--font-line-height-3);

    // Tablet & Desktop/Heading/md
    --heading-md-weight: var(--font-weight-medium);
    --heading-md-font-size: var(--font-size-3);
    --heading-md-line-height: var(--font-line-height-3);

    // Tablet & Desktop/Heading/sm
    --heading-sm-weight: var(--font-weight-medium);
    --heading-sm-font-size: var(--font-size-2);
    --heading-sm-line-height: var(--font-line-height-2);

    // Tablet & Desktop/Body/lg
    --body-md-weight: var(--font-weight-regular);
    --body-md-font-size: var(--font-size-3);
    --body-md-line-height: var(--font-line-height-3);

    // Tablet & Desktop/Body/md
    --body-md-weight: var(--font-weight-regular);
    --body-md-font-size: var(--font-size-2);
    --body-md-line-height: var(--font-line-height-2);

    // Tablet & Desktop/Body/sm
    --body-sm-weight: var(--font-weight-regular);
    --body-sm-font-size: var(--font-size-1);
    --body-sm-line-height: var(--font-line-height-1);
  }
}
