@import '@pray/shared/components/foundations';

.cookieBanner {
  background-color: $bg_tertiary;
  position: fixed;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: min(448px, calc(100vw - 32px));
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px -4px 32px rgba(0, 0, 0, 0.64), 0px 12px 40px rgba(0, 0, 0, 0.64);
  border-radius: $radius_medium;
  z-index: 9999999999;

  .content {
    padding: 24px 16px;

    a {
      color: $text_primary;
      text-decoration: underline;
    }

    .title {
      margin-bottom: 8px;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;

      button {
        flex: 1;
        color: $text_primary;

        &:not(:last-of-type) {
          margin-right: 16px;
        }
      }
    }
  }
}

@media (min-width: $screen_medium) {
  .cookieBanner {
    bottom: 24px;
    right: 24px;
    left: unset;
    transform: none;
    max-width: min(448px, calc(100vw - 64px));

    .content {
      padding: 24px;

      .buttons {
        justify-content: flex-start;

        button {
          max-width: 153px;
        }
      }
    }
  }
}
