@import '@pray/shared/components/foundations';

.type {
  padding: 6px 18px;
  border-radius: 100px;
  cursor: pointer;

  &.selected {
    background-color: $pray_secondary;
    border: 1px solid $pray_secondary;
    color: $text_tertiary;
  }

  &.unselected {
    background-color: $pray_primary;
    border: 1px solid $pray_secondary;
    color: $text_primary;
  }
}
