@import '../../foundations';

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
}

.container {
  position: relative;

  .dropdown {
    position: absolute;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: $radius_small;
    padding: 12px;
    min-width: 100%;
    z-index: 101;

    .items {
      min-width: 288px;
      height: 180px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px;
        min-height: 45px;
        cursor: pointer;

        &:hover {
          background-color: #eeeef0;
        }

        .image {
          width: 32px;
          height: 32px;
          border-radius: $radius_huge;
          margin-right: 8px;
        }

        .title {
          flex: 1;
          text-transform: none;
          text-align: left;
          @include typography_headline_small;
          @include multiline_ellipsis(1);
        }
      }
    }
  }
}
