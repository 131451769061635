@import '@pray/shared/components/foundations';

.modal {
  padding: 0;
  background: rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(80px);
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: env(safe-area-inset-bottom);
  width: 100%;
  transform: none;
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;

  div {
    margin: unset;
    max-width: unset;
  }

  .topCloseButton {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .container {
    margin-top: 80px;

    .coverWrap {
      img {
        max-width: 192px;
        margin: 0 auto;
        aspect-ratio: 1;
        object-fit: cover;
        border-radius: $radius_small;
      }
    }

    .contentWrap {
      padding: 8px;
      margin-top: 8px;

      .title {
        @include typography_subhead_medium;
        @include multiline_ellipsis(1);
        display: block !important;
        padding: 0 16px;
      }

      .subtitle {
        margin-top: 2px;
        color: rgba(255, 255, 255, 0.7);
        @include multiline_ellipsis(1);
        @include typography_footnote;
      }
    }
  }

  .options {
    max-width: fit-content;
    margin: 0 auto;
    margin-top: 8px;
    padding-bottom: 64px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    .option {
      height: 56px;
      box-sizing: border-box;
      padding-left: 24px;
      text-transform: none;

      display: flex;
      align-items: center;
      justify-content: flex-start;

      @include typography_subhead_small;

      .iconWrap {
        display: flex;
        width: 24px;
        margin-right: 16px;

        svg {
          max-height: 24px;
          max-width: 24px;
        }
      }
    }
  }

  .closeButtonWrap {
    position: fixed;
    left: 0;
    right: 0;
    bottom: env(safe-area-inset-bottom);
    padding: 16px 0;
  }
}

@media (max-height: 600px) {
  .modal {
    .options {
      padding-bottom: 16px;

      .option {
        height: 48px;
      }
    }
  }

  .closeButtonWrap {
    display: none;
  }
}

@media (max-height: 700px) {
  .modal {
    .container {
      .coverWrap {
        img {
          max-width: 120px;
        }
      }
    }
  }
}
