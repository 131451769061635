@import "../../foundations";

.loader {
  background-image: conic-gradient(transparent 25%, $pray_secondary);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  mask: radial-gradient(circle 4px, transparent 98%, $pray_secondary 100%);
  animation: rotate 1.2s linear infinite;
  margin: 0 auto;
}
@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
