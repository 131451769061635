.controls {
  text-align: center;
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 350px;
  margin: 0 auto;
}

.controlsMinimized {
  justify-content: flex-end;
  height: 100%;
  padding-right: 16px;
}

.iconWrapper,
.playPauseButton {
  width: auto !important;
  height: auto !important;
  max-width: 96px;
}

.iconWrapper:hover,
.iconWrapper:focus,
.playPauseButton:hover,
.playPauseButton:focus {
  background: initial;
  opacity: initial;
}

@media (min-width: 1024px) {
  .controlsMinimized {
    justify-content: space-evenly;
  }

  .controlsMinimized .playPauseButton {
    width: 64px !important;
    height: 64px !important;
  }
}
