@import '@pray/shared/components/foundations';

.option {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  border: 3px solid #ffffff33;
  border-radius: 12px;
  background-color: $pray_primary;
  color: $text_primary;
  cursor: pointer;

  &:hover,
  &.selected {
    border-color: $pray_featured;
  }

  &.featured {
    position: relative;
    padding-top: 32px;

    &::before {
      content: 'Most Popular';
      font-size: 13px;
      font-weight: 500;
      color: #0b0c0d;

      position: absolute;
      top: -2px;
      left: -2px;

      padding: 4px 8px;
      border-radius: 12px 0px;
      background-color: $pray_featured;
    }
  }
}
