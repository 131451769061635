@import '@pray/shared/components/foundations';

.button {
  display: inline-flex;

  .userInfo {
    display: none;
  }

  .profileIcon {
    svg {
      margin-top: 4px;
    }
  }
}

.menu {
  position: absolute;
  top: 64px;
  right: 16px;
  background-color: $bg_secondary;
  color: $text_secondary;
  padding: 0 16px;
  border-radius: $radius_small;
  z-index: 100;

  &:hover {
    background-color: #1f2024;
  }

  button {
    font-weight: normal;
    text-transform: none;
  }
}

@media (min-width: $screen_medium) {
  .button {
    .userInfo {
      display: flex;
      align-items: center;
    }

    .profileIcon {
      display: none;
    }

    span {
      text-transform: none;
    }

    svg {
      margin-left: 8px;
    }

    &:hover {
      background-color: #1f2024;
    }
  }
}
