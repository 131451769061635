@import '@pray/shared/components/foundations';

.container {
  background: rgba(0, 0, 0, 0.18);

  .modal {
    width: auto;
    padding: 32px;
    background-color: $pray_primary;

    .viewport {
      margin: 0;
      width: 538px;

      .title {
        margin-bottom: 16px;
        @include typography_h2;
      }

      .formField {
        margin-top: 20px;

        &:first-of-type {
          margin-top: 8px;
        }

        [data-id='inputWrap'] {
          & > div {
            z-index: 2;
            color: #6c6e73;
          }

          &[class*='disabled'] {
            background-color: #eeeef0;
          }
        }
      }

      .buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 32px;

        button {
          min-width: 92px;
          margin-left: 16px;
        }
      }
    }
  }
}
