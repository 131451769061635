@import '@pray/shared/components/foundations';

span.rss {
  background-color: #000000;
  border-radius: 4px;
  padding: 1px 5px;
  margin-left: 6px;
  @include typography_button_small;
  font-weight: 700;
  color: #ffffff;
}
