@import '@pray/shared/components/foundations';

.container {
  margin: -2rem 0;
  padding-top: 2rem;
  max-width: 84rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.sectionHeader {
  margin-top: 1rem;
}

.sectionTitle {
  @include typography_heading_lg;
  font-weight: 700 !important;
}

.tabs {
  display: flex;
  gap: 0.5rem;
}

.tab {
  border-radius: 9999px;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.2s;
  background-color: #f3f3f3;
  color: #56585e;
}

.activeTab {
  @extend .tab;
  background-color: black;
  color: white;
}
