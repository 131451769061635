@import '../../foundations';

.container {
  flex: 1;

  .inputBase {
    display: flex;
    align-items: center;
    gap: 8px;

    position: relative;
    min-height: 54px;

    background-color: var(--textinput-color-bg-primary-default);
    border: 1px solid var(--textinput-color-border-primary-default);
    border-radius: var(--radius-small);
    padding: var(--spacing-2xs) 12px;

    &:focus-within {
      outline: 2px solid var(--textinput-color-border-focus);
      outline-offset: 2px;
    }

    &:not(.disabled) {
      &:hover::before,
      &:focus-within::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: var(--radius-small);
        outline: 2px solid var(--textinput-color-border-primary-active);
      }
    }

    &.error {
      border: 1px solid var(--textinput-color-text-error);

      &:focus-within {
        outline: 2px solid var(--textinput-color-text-error);
        outline-offset: 2px;
      }

      &:not(.disabled) {
        &:hover::before,
        &:focus-within::before {
          outline: 2px solid var(--textinput-color-text-error);
        }
      }
    }

    .inputWrap {
      flex: 1;
      display: flex;
      flex-direction: column;

      .labelRow {
        display: flex;
        align-items: center;
        gap: 4px;

        .label {
          color: var(--textinput-color-text-label-weak);
          @include typography_heading_sm;
        }

        .required {
          color: var(--textinput-color-text-error);
          @include typography_heading_sm;
        }
      }

      input {
        color: var(--textinput-color-text-input-primary);
        font-family: var(--font-family-default);
        border: none;
        outline: none;
        background: transparent;
        z-index: 1;

        &::placeholder {
          color: var(--textinput-color-text-input-weak);
        }
      }
    }

    &.disabled {
      border-color: var(--textinput-color-border-primary-disabled);
      background-color: var(--textinput-color-bg-primary-disabled);
      color: var(--textinput-color-text-input-disabled);

      .labelRow {
        .label {
          color: var(--textinput-color-text-label-disabled);
        }

        svg * {
          stroke: var(--textinput-color-text-label-disabled);
        }
      }

      input {
        color: var(--textinput-color-text-input-disabled);

        &::placeholder {
          color: var(--textinput-color-text-input-disabled);
        }
      }
    }
  }

  .footer {
    display: flex;
    gap: 8px;
    margin-top: 8px;

    .footerCol {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .description {
        color: var(--textinput-color-text-input-weak);
      }
    }

    .info {
      margin-left: auto;
      text-align: right;
      white-space: nowrap;
    }
  }
}

span.errorMessage {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;

  font-size: var(--font-size-2);
  font-weight: var(--font-weight-medium);
  color: var(--textinput-color-text-error);
}
