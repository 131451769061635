a.button,
button.button {
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid;

  span {
    font-weight: 700;
  }

  & > * {
    &:not(:last-child) {
      margin-right: 4px;
    }

    &:not(:first-child) {
      margin-left: 4px;
    }
  }
}
