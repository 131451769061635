.radioButtonGroup {
  margin-top: 16px;
}

.radioButton {
  display: block;
  margin-bottom: 20px;
}

.labelContainer {
  display: flex;
  align-items: center;
}

.label {
  font-size: 16px !important;
  font-weight: 500;
}

span.labelDescription {
  display: flex;
  margin-left: 34px;
  margin-top: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.radio {
  appearance: none;
  border: none;
  margin-right: 14px;

  &::before {
    content: '';
    display: block;
    height: 20px;
    width: 20px;
    background-image: url(./assets/radio-unchecked.svg);
  }

  &:disabled::before {
    background-image: url(./assets/radio-disabled-unchecked.svg);
  }

  &:checked::before {
    background-image: url(./assets/radio-checked.svg);
  }

  &:disabled:checked::before {
    background-image: url(./assets/radio-disabled.svg);
  }
}
