.translationSettings {
  background: #fff;
  border-radius: 4px;
  margin-top: 30px;
}

.headlineWrapper {
  display: flex;
  flex-direction: column;
}

.languageList {
  margin-top: 36px;
}

.languageName {
  font-weight: 500;
}

.addLanguageBtn {
  margin-top: 20px;
  padding: 10px 5px;
  background: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.addLanguageLabel {
  color: #b38937;
}

.addLanguageBtn:hover {
  background: #e0e0e0;
}
