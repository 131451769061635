.periodicTable {
  margin-top: 48px;
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(10, 105px);
  grid-template-rows: repeat(3, 156px);
  grid-template-areas:
    'E01 --- --- --- --- --- --- --- --- E22'
    'E02 E04 E05 E08 E09 E12 E13 E16 E17 E21'
    'E03 E06 E07 E10 E11 E14 E15 E18 E19 E20';
}

.groupElement {
  transition: all 200ms ease-out;
  transform: scale(1);
  z-index: 0;

  &:global(.no-scaling):hover {
    cursor: default;
  }

  &:not(:global(.no-scaling)):hover {
    transform: scale(1.8);
    position: relative;
    z-index: 1;
    cursor: pointer;
    box-sizing: border-box;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.6);
    border-radius: 12px;
  }

  &.isSelected {
    opacity: 1;
  }

  &.isDisabled {
    opacity: 0.3;

    &:hover {
      transform: none;
    }
  }
}
