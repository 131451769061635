@import '@pray/shared/components/foundations';

.timePickerContainer {
  position: relative;
  width: 100%;

  .timePickerInputContainer {
    position: relative;

    .timePickerIcon {
      position: absolute;
      right: 10px;
      top: 16px;
    }

    &.timePickerInputDisabled {
      .timePickerIcon {
        opacity: 0.3;
      }
    }
  }

  .timePickerPopover {
    position: absolute;

    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0px 0px 40px 0px #45474533;

    width: 100%;
    max-width: 300px;
    padding: 10px;

    display: flex;
    gap: 8px;

    .pickerList {
      width: 100%;
      height: 100%;
      max-height: 220px;
      overflow-y: auto;
      @include hide_scrollbar;

      button {
        width: 100%;
        height: 44px;
        @include typography_subhead_small;

        &:hover {
          background-color: #f2f3f4;
        }

        &.selected {
          background-color: #0b0c0d;
          color: #fff;
        }
      }
    }
  }
}

.directionUp {
  .timePickerPopover {
    bottom: 72px;
  }
}
