.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}
.modal {
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  animation-name: modal-animation;
  animation-duration: 0.35s;
}
.viewport {
  max-width: 380px;
  margin: 0 auto;
}
.full .viewport {
  max-width: unset;
  margin-top: -24px;
}
.close {
  display: flex;
  justify-content: flex-end;
}
.close img {
  cursor: pointer;
}
.full {
  padding: 0;
}
.full .close {
  position: relative;
  top: 24px;
  right: 24px;
}

@media (min-width: 768px) {
  .container {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .modal {
    background-color: white;
    box-shadow: 0px 1px 4px rgba(25, 37, 77, 0.16), 0px 4px 8px rgba(25, 37, 77, 0.12);
    border-radius: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: unset;
    width: 500px;
    padding: 32px 40px;
  }
  .full {
    width: unset;
    padding: 0;
    transform: unset;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .full {
    width: unset;
    padding: 0;
    transform: unset;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .viewport {
    max-width: unset;
  }
}

@keyframes modal-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
