.video-js {
  video {
    border-radius: 4px;
  }

  .vjs-control-bar {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
