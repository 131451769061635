@import '@pray/shared/components/foundations';

.timePickerWrap {
  position: relative;
  border: 1px solid $border_field;
  background-color: $bg_field;
  box-sizing: border-box;
  border-radius: $radius_small;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.timePickerWrap:hover {
  border-color: $border_field_hover;
}
.timePickerWrapDisabled {
  border-color: #a6a8ad;
  background-color: #f2f3f4;
  cursor: not-allowed;
  opacity: 0.5;
}
.timePickerInputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.timePicker {
  border: none;
  font-family: Satoshi;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.18px;
  color: $text_primary;
  outline: none;
  padding-left: 12px;
  margin-top: 16px;
}
// Hide the default clock icon on the input
// As we have our own default icon
.timePicker::-webkit-calendar-picker-indicator {
  display: none;
}
.timePicker.withLabel {
  opacity: 0;
}
.floating .timePicker.withLabel {
  opacity: 1;
}
.placeholder {
  position: absolute;
  z-index: 1;
  text-align: left;
  font-family: Satoshi;
  font-size: 18px;
  line-height: 24px;
  left: 12px;
  letter-spacing: -0.18px;
  color: $text_muted;
  transition: ease-in-out 100ms;
  top: 12px;
}

.floating .placeholder {
  font-size: 13px;
  top: 1px;
}

.indicator {
  display: flex;
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
}
