@import '../../foundations';

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.inputBase {
  border: none;
  flex: 1;

  font-family: Satoshi, sans-serif;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.18px;
  color: $text_primary;
}
