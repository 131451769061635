@import '../../foundations';

.tooltip {
  padding: 4px 12px;
  color: $pray_primary;
  background-color: $pray_secondary;
  border-radius: $radius_small;
  text-transform: initial;
  position: absolute;
  transform: translateY(calc(-100% - 16px));
  font-size: small;
  font-weight: normal;

  &::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
    bottom: -8px;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 8px solid $pray_secondary;
  }
}
