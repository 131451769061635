@import '@pray/shared/components/foundations';

span.title {
  @include multiline_ellipsis(1);
  letter-spacing: normal;
  font-weight: 500;
  font-size: 16px;
  color: #0b0c0d;
}

span.description {
  color: #71747B;
  font-weight: 500;
  letter-spacing: normal;
  font-size: 14px;
  @include typography_footnote;
  @include multiline_ellipsis(1);
}