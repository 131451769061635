.selectionCard {
  position: relative;

  &.disabled {
    opacity: 0.5;
  }

  .checkbox {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .checkbox:checked + .body {
    margin: 0;
    border: 2px solid var(--color-border-primary);

    .icon {
      display: block;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 10px;

    height: 100%;

    margin: 1px;
    padding: 20px;
    border-radius: 12px;
    border: 1px solid var(--color-border-secondary);

    .icon {
      width: 20px;
      height: 20px;

      position: absolute;
      top: 10px;
      right: 10px;

      display: none;

      & * {
        fill: var(--color-text-primary);
        stroke: var(--color-text-primary-inverse);
      }
    }

    .title {
      color: var(--color-text-primary);
      font-weight: var(--font-weight-medium);
    }

    .text {
      color: var(--color-text-secondary);
    }
  }
}
