@import '@pray/shared/components/foundations';

button.backButton {
  display: inline-flex;
  align-items: center;
  margin-left: -16px;
  margin-bottom: 16px;

  * {
    @include typography_headline_small;
    font-weight: 500;
    text-transform: none;
    color: #636363;
  }

  svg {
    margin-right: 8px;
  }
}

.section {
  $sectionHeight: calc(100vh - 350px);
  min-height: $sectionHeight;
  margin-bottom: 0;
  position: relative;

  .dropzone {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

span.status {
  display: inline-flex;
  align-items: center;

  &::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #a6a8ad;
    margin-right: 6px;
    border-radius: 100%;
  }

  &.published {
    &::before {
      background-color: #048848;
    }
  }
}
