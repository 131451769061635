@import '@pray/shared/components/foundations';

.table {
  border-spacing: 0;

  * {
    padding: 0;
    margin: 0;
  }

  thead {
    tr {
      th {
        border-bottom: 1px solid #c7c7c7;

        &:first-child {
          padding-left: 10px;
        }

        &:not(.checkbox) {
          .container {
            .contents {
              padding: 6px;
            }

            &.sortable {
              padding-right: 36px;

              .contents:hover {
                color: #0b0c0d;
                background-color: rgba(170, 170, 170, 0.2);
                cursor: pointer;
              }
            }
          }
        }

        .container {
          display: flex;
          align-items: center;
          text-align: left;
          min-height: 56px;

          .contents {
            display: flex;
            align-items: center;
            border-radius: 6px;
            color: #6c6e73;

            span {
              font-weight: 500;
            }

            &.sorted {
              padding-right: 26px;
              font-weight: 700;
              color: #0b0c0d;
              position: relative;
            }
          }

          .extra {
            margin-left: 32px;
          }

          .indicator {
            position: absolute;
            right: 6px;
          }
        }
      }
    }
  }

  tbody {
    tr {
      &.selected {
        td {
          background-color: rgba(227, 175, 74, 0.15);
        }
      }

      td {
        &:first-child {
          padding-left: 10px;
        }

        &:last-child {
          padding-right: 10px;
        }

        &.rowActionsCell {
          .container {
            .contents {
              flex: 1;
              display: flex;
              justify-content: flex-end;
            }
          }
        }

        .rowActions {
          display: flex;
          gap: 8px;

          button {
            background-color: #fff;
          }

          & > * {
            display: flex;
            align-items: center;

            &:not(.visible) {
              visibility: hidden;
            }
          }
        }
      }

      &:not(.selected):hover {
        td {
          background: rgba(238, 238, 240, 0.6);

          &:first-child {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }

          &:last-child {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }

          .rowActions > * {
            visibility: visible;
          }
        }
      }

      td {
        .container {
          min-height: 56px;
          display: flex;
          align-items: center;

          .contents {
            display: flex;
            align-items: center;
            padding: 12px 0;
          }
        }
      }
    }
  }

  thead tr th,
  tbody tr td {
    border-bottom: 1px solid #e7e7e7;

    &.checkbox {
      width: 44px;
      padding: 0 12px;
    }
  }
}
