@import '@pray/shared/components/foundations';

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  color: #707070;

  svg {
    stroke: $text_primary;
  }
}
