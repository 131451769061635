@import '@pray/shared/components/foundations';

span.contentStatus {
  padding: 4px 8px;
  border-radius: 100px;
  text-transform: uppercase;
  @include typography_button_small;
  font-weight: 700;
  gap: 4px;
  height: 24px;

  &.published,
  &.active {
    background-color: var(--color-bg-publish);
    color: var(--color-text-publish);
  }

  &.scheduled {
    background-color: var(--color-bg-schedule);
    color: var(--color-text-schedule);
  }

  &.draft,
  &.private,
  &.inactive {
    background-color: #f2f3f4;
  }
}

