@import '@pray/shared/components/foundations';

.formField {
  [data-id='inputWrap'] {
    & > div {
      z-index: 2;
      color: #6c6e73;
    }

    &[class*='disabled'] {
      background-color: #eeeef0;
    }
  }
}

.selected {
  [data-id='inputWrap'] {
    padding-left: 58px;
  }
}

/* Custom scrollbar styles */
.scrollBarCustom {
  scrollbar-width: thin;
  scrollbar-color: #7b7b7b #1e1e1e; /* thumb and track color */
}

/* For Webkit browsers */
.scrollBarCustom::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.scrollBarCustom::-webkit-scrollbar-track {
  background: #1e1e1e; /* color of the tracking area */
}

.scrollBarCustom::-webkit-scrollbar-thumb {
  background-color: #1e1e1e; /* color of the scroll thumb */
  border-radius: 4px; /* roundness of the scroll thumb */
  border: 2px solid #2d3748; /* creates padding around scroll thumb */
}
