// brand colors
$pray_primary: var(--color-pray_primary);
$pray_secondary: var(--color-pray_secondary);
$pray_featured: var(--color-pray_featured);

// background
$bg_primary: var(--color-bg_primary);
$bg_secondary: var(--color-bg_secondary);
$bg_secondary_opacity_50: var(--color-bg_secondary_opacity_50);
$bg_tertiary: var(--color-bg_tertiary);
$bg_field: var(--color-bg_field);

// overlays
$overlay_bg_primary: var(--color-overlay_bg_primary);
$overlay_bg_secondary: var(--color-overlay_bg_secondary);
$overlay_bg_featured: var(--color-overlay_bg_featured);

// text
$text_primary: var(--color-text_primary);
$text_secondary: var(--color-text_secondary);
$text_tertiary: var(--color-text_tertiary);
$text_featured: var(--color-text_featured);
$text_muted: var(--color-text_muted);

// borders
$border_divider: var(--color-border_divider);
$border_highlight: var(--color-border_highlight);
$border_covers: var(--color-border_covers);
$border_field: var(--color-border_field);
$border_field_hover: var(--color-border_field_hover);

// status
$attention: var(--color-attention);
$success: var(--color-success);

// icons
$icon_primary: var(--color-icon_primary);
$icon_secondary: var(--color-icon_secondary);
