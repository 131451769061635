.actions {
  display: flex;
  justify-content: space-between;
  height: 48px;
  margin-top: 16px;
}

.actionButton {
  padding: 0;
}

.actionIcon {
  width: 24px;
  height: 24px;
}

@media (min-width: 1024px) {
  .actions.minimized {
    margin-top: 8px;
    margin-right: 16px;
    align-items: center;
    justify-content: flex-end;

    & > * {
      &:not(:last-child) {
        margin-right: 8px;
      }

      &:not(:first-child) {
        margin-left: 8px;
      }
    }
  }

  .minimized .actionIcon {
    width: auto;
    height: auto;
    max-width: 24px;
  }
}

@media (min-width: 1400px) {
  .actions {
    align-items: center;
    justify-content: flex-end;
    margin-top: initial;

    a.subscribeButton {
      display: flex;
      align-items: center;

      &:hover {
        opacity: 0.8;
      }

      svg {
        margin-left: 8px;
      }
    }

    &:not(.minimized) .actionButton {
      margin: 0 12px;
    }
  }
}
