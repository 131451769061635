@import '@pray/shared/components/foundations';

.section {
  margin-top: 24px;
}

.grid {
  margin-top: 20px;
  display: grid;
  grid-template-areas: 'form phoneView';
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 36px;
  grid-area: form;
}

.phoneView {
  grid-area: phoneView;
  margin-top: 45px;
  margin-left: 64px;
}

.audioSection {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 32px;

  &__url {
    padding: 10px;
    margin-top: 16px;
    color: #6c6e73;
    border: 1px solid #0b0c0d33;
    border-radius: $radius_small;
    max-width: 665px;

    strong {
      color: $pray_secondary;
      font-weight: 700;
    }

    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        width: 90%;
        word-wrap: break-word;
      }

      button {
        padding: $spacing_small;
        border-radius: $radius_small;

        &:active {
          transform: translateY(1px);
        }

        &:hover {
          background-color: #eeeef0;
        }
      }

      svg {
        color: #3c3c3d;
      }
    }
  }
}

.audioSection.oneItem {
  grid-template-columns: 1fr;
}

.formFieldWithButton {
  display: flex;
  align-items: center;

  & > div {
    width: 100%;
    margin-right: 16px;
  }

  .button {
    margin-left: 16px;
  }
}

.formField {
  [data-id='inputWrap'] {
    & > div {
      color: #6c6e73;
    }

    &[class*='disabled'] {
      background-color: #eeeef0;
    }
  }

  .selectField {
    top: 4px;
    height: auto;
  }

  .label {
    @include typography_subhead_medium;
    display: block;
    font-weight: 700;
  }

  .description {
    @include typography_headline_small;
    display: block;
    color: #6c6e73;
    margin-top: 4px;
    margin-bottom: 16px;
  }

  .coverArtField {
    margin-bottom: 42px;
  }
}

@media (min-width: $screen_medium) {
  .formField {
    .label {
      @include typography_headline_small;
    }

    .description {
      @include typography_headline_small;
    }
  }
}
