.date-range-picker-container {
  $range-bg-color: #f2f3f4;
  $selected-bg-color: #0b0c0d;
  font-family: Satoshi, sans-serif;

  .react-datepicker__header {
    background: none;
    border: none;
  }

  .react-datepicker__day {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    height: 36px;
    width: 36px;
    margin: 0;
    border-radius: 100%;
  }

  .react-datepicker__day-names {
    display: flex;
    margin-top: 0px;
  }

  .react-datepicker__day-name {
    width: 100%;
    height: 36px;
    margin: 0;
  }

  .react-datepicker__day--in-range,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-selecting-range:hover {
    color: #0b0c0d;
    background-color: $range-bg-color !important;
    border-radius: 0 !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--range-start,
  .react-datepicker__day--range-start:hover,
  .react-datepicker__day--range-end,
  .react-datepicker__day--range-end:hover,
  .react-datepicker__day--in-selecting-range-start,
  .react-datepicker__day--in-selecting-range-start:hover,
  .react-datepicker__day--in-selecting-range-end,
  .react-datepicker__day--in-selecting-range-end:hover {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    color: #fff;
    background-color: $selected-bg-color !important;
    border-radius: 100% !important;
    position: relative;
    z-index: 1;
  }

  .react-datepicker__week {
    display: flex;
    margin: 4px 0;
  }

  .react-datepicker__day-name {
    font-size: 16px;
    font-weight: 700;
    color: #71747b;
    padding: 4px 0;
  }

  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end {
    &::after {
      content: '';
      background-color: $range-bg-color;
      position: absolute;
      top: 0;
      width: 16px;
      height: 100%;
      z-index: -1;
    }

    span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-color: $selected-bg-color !important;
      border-radius: 100%;
    }
  }

  .react-datepicker__day--range-start::after {
    right: 0;
  }

  .react-datepicker__day--range-end::after {
    left: 0;
  }

  .react-datepicker__day--outside-month {
    visibility: hidden;
  }
}
