@mixin hide_scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}

@mixin multiline_ellipsis($lines) {
  display: -webkit-inline-box !important;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
}

// RESPONSIVENESS UTILITY CLASSES

$screen_medium: 768px;
$screen_large: 1024px;
$screen_xlarge: 1400px;

// breakpoints
$xs: 0px;
$sm: 490px;
$md: 768px;
$lg: 1040px;
$xl: 1440px;
