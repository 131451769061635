@import '@pray/shared/components/foundations';

.calendarHeader {
  width: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .chevronButtons {
    padding: 4px;

    &:active,
    &:hover {
      background-color: #cbcdd299;
      border-radius: 100%;
    }
  }

  .monthYearWrapper {
    .headerMonthYear {
      font-size: 17px !important;
      line-height: 22px !important;
      font-weight: 700 !important;
    }

    .monthSelect,
    .yearSelect {
      -webkit-appearance: none;
      -moz-appearance: none;
      text-overflow: '';
      margin: 0 5px;
    }
  }
}
