@import '@pray/shared/components/foundations';

.headerWrap {
  padding: 0px 16px;
  z-index: 80;

  * {
    transition: all ease 0.1s;
  }

  .rightButtons {
    display: flex;
    gap: 8px;
  }
}

@media (min-width: $xs) {
  .container {
    padding: 0 16px 16px;

    .content {
      height: 100%;
      width: 100%;
    }
  }
}

@media (min-width: $sm) {
  .container {
    padding: 0 24px 24px;
  }
}

@media (min-width: $md) {
  .container {
    padding: 0 48px 48px;
  }

  .headerWrap {
    padding: 1px 48px 0;
    margin-top: 36px;
    position: sticky;
    top: -1px;
  }

  .stickyHeader {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    background-color: #ffffff;

    // title text
    .headerTitle {
      @include typography_hero;
    }

    hr {
      visibility: hidden;
    }
  }
}
