@import '@pray/shared/components/foundations';

body {
  background-color: $bg_primary;
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: 100vh;
  padding-top: min(120px, 10vh);
  box-sizing: border-box;

  text-align: center;
  color: $text_primary;
  background-color: $bg_primary;
  background-image: url('./assets/covers.png');
  background-repeat: no-repeat;
  background-position: top center;

  .logo {
    top: 24px;
    position: absolute;
  }

  .main {
    padding: 24px;

    .header {
      max-width: 560px;
      margin: 0 auto;

      .title {
        @include typography_hero;
        display: block;
      }

      .subtitle {
        font-weight: 500;
        font-size: 22px;
        line-height: 30px;
        margin-top: 8px;
      }

      .description {
        @include typography_subhead_small;
        margin-top: 16px;
        text-align: center;
      }
    }

    .terms {
      @include typography_caption_medium;
      text-align: center;
      max-width: 252px;
      margin-top: 24px;

      a {
        text-decoration: underline;
        color: inherit;
      }
    }

    .email {
      display: flex;
      flex-direction: column;
      max-width: 375px;
      margin: 0 auto;
      margin-top: 24px;
      text-align: left;

      & > * {
        &:not(:last-child) {
          margin-bottom: 8px;
        }

        &:not(:first-child) {
          margin-top: 8px;
        }
      }

      .input {
        flex: 1;
        background-color: transparent;
      }
    }

    .socialButtons {
      max-width: 400px;
      margin: 0 auto;
    }

    .confirmation {
      @include typography_headline_small;
      display: block;
      margin: 40px auto;
      max-width: 400px;

      span {
        color: $pray_featured;
        white-space: nowrap;
      }
    }

    .didntReceive {
      @include typography_body;
      color: $text_secondary;
      display: block;
      margin-bottom: 24px;
    }

    .resendButton {
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  .footer {
    flex: 1;
    width: 100%;
    background-image: url('./assets/phones-mobile.png');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;

    .gradient {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 77.97%);
      display: block;
      height: 214px;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

@media (min-width: $screen_medium) {
  .page {
    .main {
      .header {
        .title {
          @include typography_display_small;
        }

        .description {
          margin-top: 32px;
        }
      }

      .email {
        flex-direction: row;
        max-width: 560px;

        & > * {
          &:not(:last-child) {
            margin-bottom: 0;
            margin-right: 8px;
          }

          &:not(:first-child) {
            margin-top: 0;
            margin-left: 8px;
          }
        }

        button {
          white-space: nowrap;
          max-height: 54px;
        }
      }

      .terms {
        max-width: initial;
      }
    }

    .footer {
      background-image: url('./assets/phones.png');
      background-position: bottom;
      background-size: contain;
      margin-top: auto;
      width: 100%;
      height: 50vh;
      max-height: 550px;
    }
  }
}

@media (max-height: 640px) {
  .page {
    .main {
      .header {
        .title {
          margin-bottom: 16px;
        }

        .subtitle {
          font-size: 18px;
          line-height: 26px;
        }
      }
    }

    .footer {
      min-height: 250px;
    }
  }
}
