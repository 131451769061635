@import '@pray/shared/components/foundations';

.container {
  display: flex;
  align-items: center;
}

.image {
  width: 64px;
  height: 64px;
  object-fit: cover;
  border-radius: $radius_small;
}

.image.layoutV2 {
  width: 132px;
  height: 102px;
}

.circle {
  border-radius: $radius_huge;
}

span.error {
  display: flex;
  align-items: center;
  gap: 4px;
}
