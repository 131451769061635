// CSS variables for both light and dark theme
:root {
  /* DESIGN TOKENS */

  // Text colors - Light mode
  --light-color-text-primary: var(--color-gray-900);
  --light-color-text-secondary: var(--color-gray-500);
  --light-color-text-tertiary: var(--color-gray-400);
  --light-color-text-disabled: var(--color-gray-400);
  --light-color-text-inverse: var(--color-white);
  --light-color-text-error: var(--color-red-600);
  --light-color-text-success: var(--color-green-500);
  --light-color-text-info: var(--color-blue-500);
  --light-color-text-alert: var(--color-orange-400);

  // Text colors - Dark mode
  --dark-color-text-primary: var(--color-white);
  --dark-color-text-secondary: var(--color-gray-200);
  --dark-color-text-disabled: var(--color-gray-600);
  --dark-color-text-inverse: var(--color-gray-900);
  --dark-color-text-error: var(--color-red-200);
  --dark-color-text-success: var(--color-green-200);
  --dark-color-text-info: var(--color-blue-200);
  --dark-color-text-alert: var(--color-orange-200);

  // Other colors
  --pray-featured-color: var(--color-gold-200);
}

// CSS variables for dark theme
:root,
.theme-dark {
  /* BUTTON */

  // button default
  --button-default-color: var(--color-white);

  // button primary
  --button-primary-color: var(--color-gray-900);
  --button-primary-border-color: var(--color-gray-900);
  --button-primary-background-color: var(--color-white);

  // button secondary
  --button-secondary-color: var(--color-white);
  --button-secondary-border-color: var(--color-white);
  --button-secondary-background-color: transparent;

  /* TEXT */
  --text-primary-color: var(--dark-color-text-primary);
  --text-secondary-color: var(--dark-color-text-secondary);
  --text-muted-color: var(--dark-color-text-disabled);
  --text-success-color: var(--dark-color-text-success);
  --text-error-color: var(--dark-color-text-error);

  /* SELECT */
  --select-color-bg: var(--color-gray-800);
  --select-color-bg-hover: var(--color-gray-900);

  /* BACKGROUND */
  --background-primary-color: var(--color-white);

  // FROM DS WEB ===============================================================

  --color-bg-comp: var(--color-gray-800);
  --color-bg-destructive: var(--color-red-300);
  --color-bg-disabled: var(--color-gray-900);
  --color-bg-draft: var(--color-gray-700);
  --color-bg-destructive-default: #e71d20;
  --color-bg-featured: #ffffff;
  --color-bg-highlight: var(--color-system-crome-highlight);
  --color-bg-inverse-primary: var(--color-white);
  --color-bg-inverse-secondary: var(--color-gray-50);
  --color-bg-nav: var(--color-gray-900);
  --color-bg-primary: var(--color-gray-900);
  --color-bg-primary-overlay: var(--opacity-5-on-dark);
  --color-bg-publish: var(--color-green-700);
  --color-bg-schedule: var(--color-purple-700);
  --color-bg-secondary: var(--color-gray-800);
  --color-bg-selected-primary: var(--color-white);
  --color-bg-selected-secondary: var(--color-gray-50);
  --color-bg-tertiary: var(--color-gray-700);
  --color-bg-unpublished: #fcbebe;
  --color-bg-unselected-primary: var(--color-gray-800);
  --color-bg-unselected-secondary: var(--color-gray-700);

  --color-border-disabled: var(--color-gray-700);
  --color-border-focus: var(--color-system-crome-focus);
  --color-border-primary: var(--color-white);
  --color-border-secondary: var(--color-gray-500);
  --color-border-tertiary: var(--color-gray-700);
  --color-border-weak: var(--color-gray-600);

  --color-icon-alert: var(--color-orange-200);
  --color-icon-disabled: var(--color-gray-500);
  --color-icon-error: var(--color-red-200);
  --color-icon-featured: var(--color-gold-200);
  --color-icon-info: var(--color-blue-200);
  --color-icon-inverse-primary: #ffffff;
  --color-icon-primary: var(--color-white);
  --color-icon-primary-disabled: var(--color-gray-500);
  --color-icon-secondary: var(--color-gray-100);
  --color-icon-success: var(--color-green-200);

  --color-text-alert: var(--color-orange-200);
  --color-text-disabled: var(--color-gray-500);
  --color-text-draft: var(--color-gray-200);
  --color-text-error: var(--color-red-200);
  --color-text-info: var(--color-blue-200);
  --color-text-primary: var(--color-white);
  --color-text-primary-inverse: var(--color-gray-900);
  --color-text-publish: var(--color-green-200);
  --color-text-schedule: var(--color-purple-200);
  --color-text-secondary: var(--color-gray-100);
  --color-text-success: var(--color-green-200);
  --color-text-tertiary: var(--color-gray-200);
  --color-text-unpublished: #670405;
}

// CSS variables for light theme
.theme-light {
  /* BUTTON */

  // button default
  --button-default-color: var(--color-gray-900);

  // button primary
  --button-primary-color: var(--color-white);
  --button-primary-border-color: var(--color-gray-900);
  --button-primary-background-color: var(--color-gray-900);

  // button secondary
  --button-secondary-color: var(--color-gray-900);
  --button-secondary-border-color: var(--color-gray-900);
  --button-secondary-background-color: transparent;

  // button tertiary
  --button-tertiary-color: var(--color-gray-900);
  --button-tertiary-border-color: var(--color-gray-900);
  --button-tertiary-background-color: var(--color-white);

  // button danger
  --button-danger-color: var(--color-white);
  --button-danger-border-color: var(--color-bg-destructive-default);
  --button-danger-background-color: var(--color-bg-destructive-default);

  /* TEXT */
  --text-primary-color: var(--light-color-text-primary);
  --text-secondary-color: var(--light-color-text-secondary);
  --text-tertiary-color: var(--light-color-text-tertiary);
  --text-muted-color: var(--light-color-text-disabled);
  --text-success-color: var(--light-color-text-success);
  --text-error-color: var(--light-color-text-error);

  /* CHIP */
  --chip-color-bg-primary-disabled: var(--color-gray-50);
  --chip-color-bg-primary-selected-default: var(--color-gray-900);
  --chip-color-bg-primary-selected-hover: var(--color-gray-600);
  --chip-color-bg-primary-unselected-default: var(--color-gray-50);
  --chip-color-bg-primary-unselected-hover: var(--color-gray-100);
  --chip-color-text-primary-selected: var(--color-white);
  --chip-color-text-primary-unselected: var(--color-gray-900);
  --chip-color-text-primary-disabled: var(--color-gray-300);
  --chip-color-border-focus: var(--color-system-crome-focus);

  /* SELECT */
  --select-color-bg: var(--color-white);
  --select-color-bg-hover: var(--color-gray-100);

  /* BACKGROUND */
  --background-primary-color: var(--color-gray-900);

  // FROM DS WEB ===============================================================

  --color-bg-comp: var(--color-white);
  --color-bg-destructive: var(--color-red-500);
  --color-bg-disabled: var(--color-gray-50);
  --color-bg-draft: var(--color-gray-50);
  --color-bg-featured: #e3af4a33;
  --color-bg-highlight: var(--color-system-crome-highlight);
  --color-bg-inverse-primary: var(--color-black);
  --color-bg-inverse-secondary: var(--color-gray-800);
  --color-bg-nav: var(--color-gray-900);
  --color-bg-primary: var(--color-gray-50);
  --color-bg-primary-overlay: var(--opacity-5-on-light);
  --color-bg-publish: var(--color-green-100);
  --color-bg-schedule: var(--color-purple-100);
  --color-bg-secondary: var(--color-gray-100);
  --color-bg-selected-primary: var(--color-gray-900);
  --color-bg-selected-secondary: var(--color-gray-600);
  --color-bg-tertiary: var(--color-gray-200);
  --color-bg-unselected-primary: var(--color-gray-50);
  --color-bg-unselected-secondary: var(--color-gray-100);

  --color-border-disabled: var(--color-gray-200);
  --color-border-focus: var(--color-system-crome-focus);
  --color-border-primary: var(--color-gray-900);
  --color-border-secondary: var(--color-gray-300);
  --color-border-tertiary: var(--color-gray-100);
  --color-border-weak: var(--color-gray-50);

  --color-icon-alert: var(--color-orange-500);
  --color-icon-disabled: var(--color-gray-200);
  --color-icon-error: var(--color-red-600);
  --color-icon-featured: var(--color-gold-200);
  --color-icon-info: var(--color-blue-500);
  --color-icon-inverse-primary: var(--color-white);
  --color-icon-primary: var(--color-gray-700);
  --color-icon-primary-disabled: var(--color-gray-100);
  --color-icon-secondary: var(--color-gray-500);
  --color-icon-success: var(--color-green-500);

  --color-text-alert: var(--color-orange-400);
  --color-text-disabled: var(--color-gray-300);
  --color-text-draft: var(--color-gray-500);
  --color-text-error: var(--color-red-600);
  --color-text-info: var(--color-blue-500);
  --color-text-primary: var(--color-gray-900);
  --color-text-primary-inverse: var(--color-white);
  --color-text-publish: var(--color-green-700);
  --color-text-schedule: var(--color-purple-700);
  --color-text-secondary: var(--color-gray-500);
  --color-text-success: var(--color-green-500);
  --color-text-tertiary: var(--color-gray-400);
}
