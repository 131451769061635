@import '@pray/shared/components/foundations';

.modal {
  text-align: center;

  [data-modal] {
    padding: 24px;
  }

  .image {
    width: 242px;
    height: 242px;
    display: block;
    margin: 40px auto;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: $radius_small;
  }
}

@media (min-width: $md) {
  .modal {
    [data-modal] {
      max-width: 500px;
      background-color: var(--color-bg_secondary);
    }
  }
}
