@import '@pray/shared/components/foundations';

.formField {
  [data-id='inputWrap'] {
    & > div {
      z-index: 2;
      color: #6c6e73;
    }

    &[class*='disabled'] {
      background-color: #eeeef0;
    }
  }
}

.selected {
  [data-id='inputWrap'] {
    padding-left: 58px;
  }
}
