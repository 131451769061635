@import '@pray/shared/components/foundations';

.container {
  background: rgba(0, 0, 0, 0.18);

  .modal {
    width: auto;
    padding: 32px;
    background-color: $pray_primary;

    .viewport {
      margin: 0;
      width: 538px;

      .title {
        margin-bottom: 16px;
        @include typography_h2;
      }

      .buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 32px;

        button {
          min-width: 92px;
          margin-left: 16px;
        }
      }
    }
  }
}

.cropper {
  position: relative;
  height: 45vh;
}

.zoomRange {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  background: #eee;
  width: 100%;
  cursor: pointer;

  &::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid $pray_secondary;
    background: $pray_secondary;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid $pray_secondary;
    background: $pray_secondary;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}

.controls:hover input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(55, 58, 75, 0.1);
  border-radius: 50%;
}

.controls:hover input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.1);
}
