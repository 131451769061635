#externalSystemContainer {
  width: 100%;
  background-color: darkgrey;
  padding: 5px 0 5px 20px;
}

.panel-collapse {
  visibility: visible !important;
}

.notification-zone {
  position: fixed;
  width: 400px;
  z-index: 99999;
  right: 20px;
  bottom: 80px;
}

.control-button {
  border-radius: 17px;
  padding: 5px 10px;
  border-color: grey;
}

.esdev-app .revision-panel .panel-body {
  min-height: 48px;
  height: unset !important;
}

.esdev-app .revision-panel .no-revision-items {
  top: 50%;
  transform: translateY(-50%);
}

#changeHistoryLink {
  cursor: pointer;
}

#stripoSettingsContainer {
  height: 100%;
}

a.sripo-watermark {
  display: none !important;
}

.esdev-app .collapse.in {
  visibility: visible;
}
