@import '@pray/shared/components/foundations';

a.pill,
button.pill {
  padding: 8px 24px;
  @include typography_subhead_small;
  font-weight: 500;
  text-transform: none;
  background-color: #f2f3f4;
  border-radius: 100px;
  margin-right: 8px;

  &.active {
    background-color: $pray_secondary;
    color: $text_secondary;
  }
}
