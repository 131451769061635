@import '@pray/shared/components/foundations';

.container {
  margin-top: 16px;
  background-color: rgba(11, 12, 13, 0.05);
  border-radius: $radius_small;
  padding: 12px;

  .image {
    max-width: 100%;
    object-fit: contain;
  }

  .info {
    margin-top: 20px;

    .title {
      @include typography_headline_small;
      font-weight: 500;
    }

    .subtitle {
      @include multiline_ellipsis(3);
      @include typography_body_small;
      color: $text_tertiary;
      margin-top: 8px;
    }
  }
}

@media (min-width: $screen_medium) {
  .container {
    display: flex;
    align-items: center;

    .image {
      max-width: 163px;
      margin-right: 20px;
      float: left;
    }

    .info {
      margin-top: initial;
      max-width: 443px;

      .subtitle {
        display: block;
      }
    }

    .clear {
      clear: both;
    }
  }
}
