@import '@pray/shared/components/foundations';

.magicLoginWrap {
  span {
    color: $text_primary !important;
  }
}

.page {
  display: flex;
  flex-direction: column;
  background-color: #fff;

  flex: 1;
  height: 100vh;
  max-height: -webkit-fill-available;
}

.container {
  flex: 1;
  display: flex;
  min-height: 0;
  position: relative;
}

.main {
  flex: 1;
  overflow-y: auto;
}

@media (min-width: $xs) {
  [data-audioplayer] {
    [data-minimizedplayer] {
      background: #2c2e33;
      bottom: 8px;
    }

    [data-artistname],
    [data-contenttitle] {
      color: white;
    }
  }

  [data-videomodal] {
    [data-modal] {
      [data-closebutton] {
        margin: 16px 20px;
        padding: 4px;

        svg > * {
          fill: black;
        }
      }
    }
  }
}

@media (min-width: $lg) or (min-width: $screen_large) {
  [data-audioplayer] {
    [data-minimizedplayer] {
      bottom: 0;
    }
  }
}
