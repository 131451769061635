@import '@pray/shared/components/foundations';

.group-chat {
  --sendbird-font-family-default: Satoshi;
  --sendbird-light-primary-500: var(--color-pray_featured);
  --sendbird-light-primary-400: var(--color-pray_featured);
  --sendbird-light-primary-300: var(--color-pray_featured);
  --sendbird-light-primary-100: var(--color-text-muted);
  --sendbird-light-ondark-01: var(--color-pray_secondary);

  margin-top: 16px;
  border: 1px solid var(--sendbird-light-onlight-04);
  border-radius: 6px;

  .sendbird-channel-header__right-icon,
  .sendbird-chat-header__right,
  .sendbird-channel-preview__content__upper__header__total-members,
  .sendbird-channel-header__title__right__user-id {
    display: none !important;
  }

  .sendbird-channel-header {
    background-color: transparent;
    width: auto;
    min-width: 344px;
  }

  .sendbird-channel-header * {
    display: none;
  }

  .sendbird-channel-header::after {
    content: 'All Chats';
    @include typography_h2;
    font-family: var(--sendbird-font-family-default);
    font-weight: 700;
    color: #0b0c0d;

    display: flex;
    align-items: center;
    height: 48px;
    padding-left: 16px;
  }

  .sendbird-channel-list__body {
    padding: 12px;
  }

  .sendbird-channel-list {
    background-color: transparent;
    width: auto;
  }

  .sendbird-channel-preview {
    border-bottom: none;
    border-radius: 6px;
  }

  .sendbird-channel-preview--active {
    background-color: #f2f3f4;
  }

  .sendbird-channel-preview__action {
    display: none !important;
  }

  .sendbird-badge__text {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sendbird-conversation {
    border-top: none;
    border-right: none;
    border-bottom: none;
    background-color: transparent;
  }

  .sendbird-chat-header {
    background-color: transparent;
  }

  .sendbird-message-input__edit {
    background-color: rgba(227, 175, 74, 0.2);

    button {
      text-transform: uppercase;
      background-color: #ffffff;
      width: auto;
      min-width: 74px;

      span {
        font-weight: 700;
        font-size: 13px;
        line-height: 24px;
        letter-spacing: 0.5px;
      }
    }

    .sendbird-button--secondary:hover .sendbird-button__text {
      color: $pray_secondary;
    }

    .sendbird-message-input--edit-action__cancel {
      border-color: rgba(0, 0, 0, 0.88);
    }

    .sendbird-button--primary {
      border-color: $pray_secondary;
      background-color: $pray_secondary;

      span {
        color: rgba(255, 255, 255, 0.88);
      }
    }
  }
}
