@import '../../foundations/theme/variables';

a.chip,
button.chip {
  padding: 0;
  border-radius: var(--radius-round) !important;
  background-color: var(--chip-color-bg-chip-primary-unselected-default);
  padding: 8px 16px;

  &:focus {
    outline: 4px solid var(--chip-color-border-chip-focus);
  }

  &:hover {
    background-color: var(--chip-color-bg-chip-primary-unselected-hover);
  }

  .label {
    color: var(--chip-color-text-chip-primary-unselected);

    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-3);
    text-transform: none;
  }

  &.selected {
    background-color: var(--chip-color-bg-chip-primary-selected-enabled);

    &:hover {
      background-color: var(--chip-color-bg-chip-primary-selected-hover);
    }

    .label {
      color: var(--chip-color-text-chip-primary-selected);
    }
  }

  &.disabled {
    background-color: var(--chip-color-bg-chip-disabled);

    .label {
      color: var(--chip-color-text-chip-disabled);
    }
  }
}
