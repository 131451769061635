@import '../variables';

.mobile-only {
  display: initial;
}

.desktop-only {
  display: none;
}

.underline {
  text-decoration: underline;
}

.sm-show {
  display: inherit;
}
.sm-hide {
  display: none;
}

.flex-1 {
  flex: 1;
}

@media (min-width: $screen_medium) {
  .md-show {
    display: inherit;
  }
  .md-hide {
    display: none;
  }
}

@media (min-width: $screen_large) {
  .lg-show {
    display: inherit;
  }

  .lg-hide {
    display: none;
  }

  .desktop-only {
    display: initial;
  }

  .mobile-only {
    display: none;
  }
}

@media (min-width: $screen_xlarge) {
  .xl-show {
    display: inherit;
  }

  .xl-hide {
    display: none;
  }
}

.dark-bg {
  background-color: var(--background-primary-color);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.noscroll {
  overflow: hidden;

  & > * {
    overflow: hidden;
  }
}
