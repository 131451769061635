/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 900.0;
*
* available axes:
* 'wght' (range from 300.0 to 900.0)
*/

@font-face {
  font-family: 'SF-Mono-Regular';
  font-weight: 500;
  font-style: normal;
  src: url('https://d2alqht3442852.cloudfront.net/fonts/SF-Mono-Regular.woff2') format('woff2'),
    url('https://d2alqht3442852.cloudfront.net/fonts/SF-Mono-Regular.woff') format('woff'),
    url('https://d2alqht3442852.cloudfront.net/fonts/SF-Mono-Regular.ttf') format('truetype');
}
