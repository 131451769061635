@import '@pray/shared/components/foundations';

.fullscreen {
  position: fixed;
  left: 0;
  bottom: env(safe-area-inset-bottom);
  right: 0;
  top: 0;
  background-color: $bg_primary;
  z-index: 100;
  overflow: hidden;
  transition: 0.2s ease-in;

  display: flex;
  flex-direction: column;
}

.minimized {
  background: rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(80px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: $radius_small;

  height: 56px;
  width: calc(100% - 16px);
  flex-direction: row;

  top: unset;
  right: unset;
  bottom: calc(90px + env(safe-area-inset-bottom));
  left: 50%;
  transform: translateX(-50%);
  transition: 0.2s ease-out;
  cursor: pointer;
  overflow: visible;
}

.contentImageMinimized {
  border-radius: $radius_small;
}

.minimizedPlayer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.minimizedContainer {
  flex: 1;
  display: flex;
  align-items: center;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 20px;
  border-radius: 100%;
  margin: 0 8px;

  svg {
    min-width: 16px;
    min-height: 16px;
  }

  &:hover {
    background-color: initial;
    opacity: initial;
  }
}

@media (min-width: 600px) {
  .minimized {
    max-width: 640px;
    height: 68px;
    cursor: default;
  }
}

@media (min-width: 768px) {
  .fullscreen {
    background: rgba(11, 12, 13, 0.8);
    backdrop-filter: blur(160px);
  }

  .bottom {
    padding: 32px 40px;
  }
}

@media (min-width: 992px) {
  .imageContainerMinimized {
    width: 52px;
    height: 52px;
  }
}

@media (min-width: 1024px) {
  .minimized {
    bottom: 0;
    max-width: unset;
    background: rgba(31, 32, 36, 0.6);
    height: 92px;
    width: 100%;
    border: none;
    border-radius: 0;
    margin: 0;
  }

  .contentImageMinimized {
    width: 64px;
    height: 64px;
    margin: 16px;
    object-fit: cover;
  }

  .scrubber {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
