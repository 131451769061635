@import '../../foundations';

.toast {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 36px 12px 16px;
  gap: 12px;

  background: #1f2024;
  color: $pray_primary;
  border: 1px solid transparent;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  @include typography_subhead_small;

  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;

  & > * {
    &:first-child {
      &:not(:last-child) {
        margin-right: 4px;
      }
    }

    &:last-child {
      &:not(:first-child) {
        margin-left: 4px;
      }
    }
  }
}

.enter {
  animation: slide-down 0.15s forwards;
}

.leave {
  animation: slide-up 0.15s forwards;
}

@keyframes slide-down {
  0% {
    top: -50px;
  }
  100% {
    top: 76px;
  }
}

@keyframes slide-up {
  0% {
    top: 76px;
  }
  100% {
    top: -50px;
  }
}
