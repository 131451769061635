@import '@pray/shared/components/foundations';

.extraActionsMenu {
  background-color: $bg_tertiary;
  position: fixed;
  right: 56px;
  bottom: 96px;
  padding: 8px 0;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(80px);
  border-radius: $radius_small;
  z-index: 1;

  display: flex;
  flex-direction: column;

  a.option {
    display: inline-flex;
    gap: 8px;
    justify-content: flex-start;
    text-transform: none;
    @include typography_subhead_small;

    &:hover {
      opacity: 0.8;
    }

    .iconWrap {
      display: flex;
      width: 24px;
      margin-right: 16px;

      svg {
        max-height: 24px;
        max-width: 24px;
      }
    }
  }
}
