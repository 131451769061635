@import '@pray/shared/components/foundations';

.container {
  background: rgba(0, 0, 0, 0.18);

  .modal {
    width: auto;
    padding: 32px;
    background-color: $pray_primary;

    .viewport {
      margin: 0;
      width: 538px;

      .title {
        margin-bottom: 16px;
        @include typography_h2;
      }

      .buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 32px;

        button {
          min-width: 92px;
          margin-left: 16px;
        }
      }
    }
  }
}
