@import '../../foundations';

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 80;
  display: none;
}

.container {
  position: relative;

  &.disabled {
    [data-id='inputWrap'] {
      opacity: 0.5;
      background-color: #eeeef0;

      * {
        color: #6c6e73;
        cursor: default;
      }
    }
  }

  .dropdown {
    position: absolute;
    background-color: var(--select-color-bg);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: $radius_small;
    min-width: 100%;
    max-height: 270px;
    overflow-y: auto;
    z-index: 101;
    margin-top: 6px;

    .groups {
      .group {
        .groupLabel {
          display: flex;
          padding: 8px 16px;
          background-color: #fafafa;
          color: #56585e;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          border-top: 1px solid rgba(14, 15, 12, 0.12);
          border-bottom: 1px solid rgba(14, 15, 12, 0.12);
        }

        &:first-of-type {
          .groupLabel {
            border-top: none;
          }
        }
      }

      .items {
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 24px;
          min-height: 45px;
          cursor: pointer;
          border-radius: 0;

          svg * {
            stroke: #56585e;
          }

          &:hover {
            opacity: 0.7;
            background-color: var(--select-color-bg-hover);
          }

          .leftIcon {
            transform: translateX(-10px);
          }

          .leftImage {
            width: 36px;
            height: 36px;
            border-radius: var(--radius-small);
            margin-right: 16px;
          }

          .itemLabel {
            flex: 1;
            text-transform: none;
            text-align: left;
            font-size: 16px;
            line-height: 24px;
            @include multiline_ellipsis(1);
          }
        }
      }
    }
  }
}
