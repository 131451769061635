@import '@pray/shared/components/foundations';

.formField {
  [data-id='inputWrap'] {
    & > div {
      z-index: 2;
      color: #6c6e73;
    }

    &[class*='disabled'] {
      background-color: #eeeef0;
    }
  }

  .label {
    @include typography_subhead_medium;
    display: block;
    font-weight: 500;
  }

  .description {
    @include typography_subhead_small;
    display: block;
    color: #6c6e73;
    margin-top: 8px;
    margin-bottom: 16px;
  }
}

@media (min-width: $screen_medium) {
  .formField {
    .label {
      @include typography_headline_small;
    }

    .description {
      @include typography_headline_small;
    }
  }
}
