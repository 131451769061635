@import '../../foundations';

.dropzone {
  .info {
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 2px solid transparent;
    box-sizing: border-box;

    .dropzoneInfo {
      position: fixed;
      margin-top: 20vh;

      img {
        margin: 0 auto;
        transition: all 250ms;
        margin-bottom: 16px;
      }

      .title {
        @include typography_h2;
        display: block;
        font-weight: 500;
      }

      .subtitle {
        @include typography_body;
        display: block;
        margin-top: 6px;
        font-weight: 500;
        color: #919191;
      }
    }
  }

  &.visible {
    .info {
      background-color: #ffffff;
      border: 2px dashed #e3af4a;

      .dropzoneInfo {
        .rotate {
          transform: rotate(-180deg);
        }
      }
    }
  }
}
