@import '@pray/shared/components/foundations';

button.backButton {
  display: inline-flex;
  align-items: center;
  margin-left: -16px;
  margin-bottom: 16px;

  * {
    @include typography_headline_small;
    font-weight: 500;
    text-transform: none;
    color: #636363;
  }

  svg {
    margin-right: 8px;
  }
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .info {
    flex: 1;

    .title {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      @include multiline_ellipsis(1);
    }

    .description {
      font-weight: 500;
      font-size: 17px;
      line-height: 22px;
      color: #707070;
      margin-top: 4px;
      @include multiline_ellipsis(2);
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.section {
  $sectionHeight: calc(100vh - 350px);
  min-height: $sectionHeight;
  margin-bottom: 0;
}
