@import '@pray/shared/components/foundations';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 132px;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    background: rgba(227, 175, 74, 0.2);
    border-radius: $radius_huge;
  }

  .title {
    margin-top: 16px;
    @include typography_h2;
  }

  .subtitle {
    margin-top: 4px;
    color: #7a7a7a;
    text-align: center;
    @include typography_headline_small;
  }
}
