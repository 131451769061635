.modal {
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex;
  width: 496px;
  transform: translate(-50%, -50%);
  flex-direction: column;
  gap: 16px;
  border-radius: 6px;
  border: 1px solid #d4d4d4;
  background-color: white;
  padding: 16px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.24);
}

.option {
  flex: none;
  position: relative;
  width: 20px;
  height: 20px;
  appearance: none;
  border-radius: 100%;
  border: 1px solid #8c8f96;
}

.option::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 6px;
  height: 6px;
  transform: translate(-50%, -50%);
  border-radius: 100%;
}

.option:checked {
  border-color: transparent;
  background-color: #000000;
}

.option:checked::after {
  background-color: #ffffff;
}