@import './colors';

// border style
$border_none: 0px none;
$border_button: 1px solid $pray_secondary;
$border_secondary: 1px solid $border_covers;

// border radius
$radius_none: 0px;
$radius_small: 6px;
$radius_medium: 12px;
$radius_big: 24px;
$radius_huge: 100%;
