.scrubberContainer {
  margin-top: 24px;
}

.scrubberContainerMinimized {
  max-width: 100%;
  margin: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 2;
}

.scrubber {
  background-color: rgba(255, 255, 255, 0.15);
  position: absolute;
  width: 100%;
  height: 2px;
  z-index: 2;
}

.timestamps {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.scrubberContainerMinimized .timestamps {
  display: none;
}

.scrubberContainerMinimized .timestamp {
  display: none;
}

.movePoint {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 3px;
}

.seekbar {
  position: relative;
  display: flex;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
}

.seekbar > input {
  background-color: rgba(255, 255, 255, 0.2);
  border: 0;
  height: 4px;
  width: 100%;
  border-radius: 2px;
  display: block;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  margin: 0px;
  overflow: visible;
  outline: none;
  padding: 0;
}
.input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border: 0;
}
/* Тоже самое для Firefox */
.input[type='range']::-moz-range-thumb {
  height: 8px;
  width: 8px;
  border: 0;
  background-color: #ffffff !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}

.scrubberContainerMinimized .input[type='range']::-webkit-slider-thumb {
  display: none;
}

.scrubberContainerMinimized .input[type='range']::-moz-range-thumb {
  display: none;
}

.scrubberContainerMinimized .input[type='range']:hover::-webkit-slider-thumb {
  display: block;
}

.scrubberContainerMinimized .input[type='range']:hover::-moz-range-thumb {
  display: block;
}

@media (min-width: 1024px) {
  .scrubberContainerMinimized {
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  .timestamp {
    display: none;
  }
}

@media (min-width: 1336px) {
  .timestamps {
    margin: 0 0 11px;
  }
}

@media (min-width: 1400px) {
  .scrubberContainer {
    margin-bottom: 16px;
  }
}
