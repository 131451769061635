@import '@pray/shared/components/foundations';

button.dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 14px;
  width: 100%;
  min-width: 230px;
  transition: all ease 0.1s;

  &:hover {
    background: #eeeef0;
  }

  .image {
    width: 32px;
    height: 32px;
    border-radius: $radius_huge;
    margin-right: 12px;
  }

  .name {
    flex: 1;
    text-align: left;
    text-transform: none;
    font-weight: 500;
    @include typography_headline_small;

    label {
      display: block;
      font-weight: 500;
      @include typography_footnote;
    }
  }

  &.collapsed {
    justify-content: center;
    padding: 10px;

    .image {
      margin-right: 0;
    }

    svg {
      display: none;
    }
  }

  &.inputWrap {
    padding: 6px 10px;
    border: 1px solid $border_field;
    border-radius: $radius_small;

    &:hover {
      border-color: $border_field_hover;
    }
  }
}
