.checkbox {
  appearance: none;
  border: none;

  &::before {
    content: '';
    display: block;
    height: 20px;
    width: 20px;
    background-image: url(./assets/checkbox-unchecked-light.svg);
  }

  &.partial::before {
    background-image: url(./assets/checkbox-partial-light.svg);
  }

  &:checked::before {
    background-image: url(./assets/checkbox-checked-light.svg);
  }

  &.dark {
    &::before {
      background-image: url(./assets/checkbox-unchecked-dark.svg);
    }

    &.partial::before {
      background-image: url(./assets/checkbox-partial-dark.svg);
    }

    &:checked::before {
      background-image: url(./assets/checkbox-checked-dark.svg);
    }
  }
}

.checkboxArea {
  display: flex;
  align-items: center;
}

.circular {
  position: relative;
  padding-left: 36px;
}

.circular label {
  border: 2px solid #777;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  width: 24px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.circular label:after {
  border: 2px solid #000;
  border-top: none;
  border-right: none;
  content: '';
  height: 6px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 12px;
}

.circular input[type='checkbox'] {
  display: none;
}

.circular input[type='checkbox']:checked + label {
  background-color: #fff;
  border-color: #fff;
}

.circular input[type='checkbox']:checked + label:after {
  opacity: 1;
}
