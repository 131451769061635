@import '@pray/shared/components/foundations';

a.playlist {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 12px;

  .dragHandle {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    min-width: 24px;
    width: 24px;
    height: 40px;

    &:hover {
      background-color: rgba(203, 205, 210, 0.8);
    }

    span {
      display: block;
      @include typography_subhead_small;
      font-weight: 500;
    }

    img {
      display: none;
    }
  }

  .noDragHandle {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    min-width: 24px;
    width: 24px;
    height: 40px;

    span {
      display: block;
      @include typography_subhead_small;
      font-weight: 500;
    }

    img {
      display: none;
    }
  }

  &.dragging,
  &:hover {
    background-color: #f2f3f4;

    .dragHandle {
      span {
        display: none;
      }

      img {
        display: block;
      }
    }
  }

  .dailyImage {
    height: 138px;
    width: 92px;
  }

  .contentImage {
    height: 92px;
    width: 92px;
  }

  .image {
    min-width: 92px;
    object-fit: cover;
    border-radius: 6px;
    margin-left: 8px;
    margin-right: 20px;
  }

  .title {
    @include multiline_ellipsis(1);
    max-width: 55vw;
    @include typography_headline_medium;
    font-weight: 700;
  }

  .description {
    @include multiline_ellipsis(1);
    max-width: 55vw;
    @include typography_subhead_small;
    font-weight: 500;
    color: #707070;
    margin-top: 4px;
    margin-bottom: 8px;
  }
}
