.loading {
  display: inline-block;
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #2a2e41 5%, #3b3e51 25%, #2a2e41 40%);
  background-size: 1080px 100%;
  border-radius: 4px;
  margin: 8px 0;
}

.light {
  animation: shimmer 16s infinite linear;
  background: linear-gradient(to right, #f2f2f2 5%, #f9f9f9 25%, #f2f2f2 40%);
}

@keyframes shimmer {
  0% {
    background-position: -1080px 0;
  }

  100% {
    background-position: 1080px 0;
  }
}
