@import '@pray/shared/components/foundations';

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  margin-top: 16px;
  border-radius: 12px;
  background: #f3f3f3;

  .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }

  .description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
  }
}
