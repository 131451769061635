@import '@pray/shared/components/foundations';

.social {
  justify-content: center;
  align-items: center;
  margin-bottom: $spacing_medium_to_large;
}

.shareButton {
  padding: 0;
  margin: 0 $spacing_small;
}

@media (min-width: $screen_medium) {
  .social {
    margin-top: $spacing_large_to_huge;
    display: flex;
  }
}
