@import '@pray/shared/components/foundations';

@media (min-width: $xs) {
  .container {
    display: flex;
    flex-direction: column;
    background-color: $bg_primary;
    width: 100%;
  }

  .appBarContainer {
    display: none;
  }

  @media (min-height: 800px) {
    .appBarContainer {
      display: block;
    }
  }

  .header {
    display: none;
  }

  .imageContainer {
    width: 100%;
    max-height: 335px;
    overflow-y: hidden;
    background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%);

    img {
      position: relative;
      display: block;
      aspect-ratio: auto 502/335;
      width: 100%;
      height: auto;
      mask-image: linear-gradient(to top, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 1) 100%);
      -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 1) 100%);
    }
  }

  .content {
    padding: 0 18px;
    margin-top: -64px;
    margin-bottom: 136px;
    z-index: 1;

    .headline {
      display: flex;
      flex-direction: column;
      text-align: left;
      gap: 12px;

      .textExpander {
        button {
          font-size: 13px;
          line-height: 24px;
          margin-top: 6px;
          padding: 0;
          color: $text_secondary;
        }
      }
    }

    .frequencyContainer {
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: flex-start;
      margin-top: 24px;
      gap: 12px;
      text-align: center;

      .frequencyTitle {
        display: block;
        margin-bottom: 12px;
      }

      .frequencies {
        display: flex;
        gap: 12px;
        justify-content: center;
      }
    }

    .optionsContainer {
      display: flex;
      flex-direction: column;
      margin-top: 24px;
      margin-bottom: 40px;
      gap: 12px;
    }

    .optionContainer {
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 100%;
    }

    .optionRow1 {
      display: flex;
      justify-content: space-between;
    }

    .optionTitle {
      text-align: left;
    }

    .optionAmount {
      text-align: right;
    }

    .optionRow2 {
      display: flex;
    }

    .optionSubtitle {
      flex: 1;
      color: $text_secondary;
      text-align: left;
    }

    .continue {
      height: 48px;
      position: fixed;
      bottom: 16px;
      left: 16px;
      right: 16px;
      color: $text_tertiary;
      background-color: $pray_featured;
      z-index: 1;
    }
  }
}

@media (min-width: $md) {
  .container {
    margin: 0 auto;
    max-width: 500px;
  }

  .header {
    display: block;
    margin: 24px 0;
  }

  .imageContainer {
    margin-top: 16px;
    position: relative;
  }

  .content {
    margin: 0;
    margin-top: -40px;

    .frequencyContainer {
      flex-direction: column;
      justify-items: center;
      align-items: center;
      text-align: center;

      .frequencies {
        gap: 16px;
        justify-content: center;
      }
    }

    .continue {
      width: 100%;
      position: static;
    }
  }
}

.inlineCustomAmountContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 40px;
  gap: 16px;
}

.customAmountTitle {
  text-align: left;
}

.customAmountInputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
  max-width: 300px;

  .customAmountInput {
    height: 48px;
    padding: 12px 16px;
    font-size: 24px;
    font-weight: 500;
    border: 1px solid $border_field;
    border-radius: 8px;
    background-color: $bg_field;
    color: $text_primary;
    text-align: center;

    &:focus {
      outline: none;
      border-color: $pray_featured;
    }

    &::placeholder {
      color: $text_secondary;
    }
  }

  .customAmountInfo {
    display: flex;
    justify-content: center;
    align-items: center;

    .customAmountNote {
      flex: 1;
      text-align: center;
    }

    .intervalLabel {
      color: $text_secondary;
    }
  }
}
