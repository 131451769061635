.phoneBorder {
  width: 300px;
  height: 595px;
  border-radius: 50px;
  margin: 0 0 0 0;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .phoneIsland {
    top: 20px;
    background-color: #000;
    position: absolute;
    content: '';
    height: 20px;
    width: 80px;
    border-radius: 30px;
    transition: width 0.5s ease;

    &:hover {
      width: 120px;
      /* Add 20px to the initial width */
    }
  }

  .childScreen {
    width: 95%;
    height: 97%;
    border-radius: 40px;
    background-color: #1e1e1e;
    color: #fff;
    overflow: hidden;
  }

  .phoneScreen {
    width: 95%;
    height: 97%;
    border-radius: 40px;
    background-color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    overflow: hidden;

    .textView {
      position: absolute;
      padding: 23px;

      color: #ffffff;
      width: 100%;
      text-align: left;

      .body {
        font-size: 15px;
        line-height: 20px;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.35);
        margin-bottom: 16px;
        display: block;
        font-family: 'Satoshi';
        font-style: normal;
        font-weight: 700;
      }

      .reference {
        text-transform: uppercase;
        font-weight: 700;
        text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
      }
    }

    img,
    video {
      height: 100%;
      object-fit: cover;
    }

    .bottomRow {
      position: absolute;
      bottom: 40px;
      left: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        width: 15px;
      }

      .progressBar {
        width: 180px;
        height: 4px;
        /* adjust to make the bar thicker or thinner */
        background-color: rgba(255, 255, 255, 0.46);
        margin-left: 20px;

        .progress {
          width: 0%;
          /* adjust this to represent the current progress */
          height: 100%;
          background-color: #fff;
        }
      }
    }

    .previewPlaceholder {
      max-width: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;

      .text {
        padding-top: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }
    }

    &.scrollable {
      overflow-y: auto;
    }
  }
}
