@import '@pray/shared/components/foundations';

.coverSection {
  margin-top: 32px;
  margin-bottom: 0;
}

.formField {
  margin-top: 24px;

  [data-id='inputWrap'] {
    & > div {
      z-index: 2;
      color: #6c6e73;
    }

    &[class*='disabled'] {
      background-color: #eeeef0;
    }
  }

  .label {
    @include typography_subhead_medium;
    display: block;
    font-weight: 500;
  }

  .description {
    @include typography_subhead_small;
    display: block;
    color: #6c6e73;
    margin-top: 8px;
    margin-bottom: 16px;
  }
}

.mobileSaveButtonWrap {
  background-color: #fff;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.16);
  padding: 12px 16px;
  display: flex;
  z-index: 5;

  button {
    @include button_large;

    flex: 1;

    span {
      font-weight: 700;
    }
  }
}

@media (min-width: $screen_medium) {
  .stickyHeader {
    background-color: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);

    // title text
    & > div > span {
      @include typography_hero;
    }

    hr {
      visibility: hidden;
    }
  }

  .formField {
    .label {
      @include typography_headline_small;
    }

    .description {
      @include typography_headline_small;
    }

    .leaderUrl {
      input {
        padding-left: 169px;
      }
    }
  }
}
