@import '../variables/utils';

.theme-light {
  /* color */
  --color-bg-ai: var(--color-purple-100);
  --color-bg-ai-hover: var(--color-purple-400);
  --color-bg-smart: var(--color-purple-400);
  --color-bg-avatar: var(--color-gray-50);
  --color-bg-comp: var(--color-white);
  --color-bg-destructive: var(--color-red-500);
  --color-bg-destructive-hover: var(--color-red-400);
  --color-bg-destructive-weak: var(--color-red-50);
  --color-bg-disabled: var(--color-gray-50);
  --color-bg-draft: var(--color-gray-50);
  --color-bg-highlight: var(--color-system-crome-highlight);
  --color-bg-informative-subtle: var(--color-blue-100);
  --color-bg-informative-weak: var(--color-blue-50);
  --color-bg-nav: var(--color-gray-900);
  --color-bg-null: var(--opacity-no-contrast-on-light);
  --color-bg-overlay-high-contrast: var(--opacity-high-contrast-on-light);
  --color-bg-overlay-low-contrast: var(--opacity-low-contrast-on-light);
  --color-bg-overlay-medium-contrast: var(--opacity-medium-contrast-on-light);
  --color-bg-overlay-very-high-contrast: var(--opacity-modal-constrast);
  --color-bg-premium: var(--color-gold-200);
  --color-bg-premium-subtle: var(--color-gold-100);
  --color-bg-premium-weak: var(--color-gold-50);
  --color-bg-primary: var(--color-gray-50);
  --color-bg-primary-enabled: var(--color-gray-50);
  --color-bg-primary-hover: var(--color-gray-50);
  --color-bg-primary-inverse: var(--color-black);
  --color-bg-primary-pressed: var(--color-gray-100);
  --color-bg-primary-selected: var(--color-gray-900);
  --color-bg-primary-unslelected: var(--color-gray-50);
  --color-bg-publish: var(--color-green-100);
  --color-bg-schedule: var(--color-purple-100);
  --color-bg-secondary: var(--color-gray-100);
  --color-bg-secondary-inverse: var(--color-gray-800);
  --color-bg-secondary-selected: var(--color-gray-600);
  --color-bg-skeleton: var(--color-gray-50);
  --color-bg-smart: var(--color-purple-50);
  --color-bg-smart-subtle: var(--color-purple-100);
  --color-bg-smart-weak: var(--color-purple-50);
  --color-bg-success: var(--color-green-400);
  --color-bg-success-subtle: var(--color-green-100);
  --color-bg-success-weak: var(--color-green-50);
  --color-bg-surface: var(--color-white);
  --color-bg-tertiary: var(--color-gray-200);
  --color-bg-unselected-secondary: var(--color-gray-100);
  --color-border-ai: var(--color-purple-200);
  --color-border-ai-hover: var(--color-purple-400);
  --color-border-destructive: var(--color-red-100);
  --color-border-disabled: var(--color-gray-50);
  --color-border-focus: var(--color-system-crome-focus);
  --color-border-high-contrast: var(--opacity-high-contrast-on-light);
  --color-border-informative: var(--color-blue-100);
  --color-border-medium-contrast: var(--opacity-medium-contrast-on-light);
  --color-border-positive: var(--color-green-100);
  --color-border-premium: var(--color-gold-100);
  --color-border-primary: var(--color-gray-900);
  --color-border-secondary: var(--color-gray-300);
  --color-border-smart: var(--color-purple-100);
  --color-border-tertiary: var(--color-gray-100);
  --color-border-warning: var(--color-orange-100);
  --color-border-weak: var(--color-gray-50);
  --color-icon-ai: var(--color-purple-400);
  --color-icon-ai-hover: var(--color-white);
  --color-icon-alert: var(--color-orange-500);
  --color-icon-disabled: var(--color-gray-200);
  --color-icon-error: var(--color-red-600);
  --color-icon-info: var(--color-blue-500);
  --color-icon-inverse-primary: var(--color-white);
  --color-icon-premium: var(--color-gold-300);
  --color-icon-primary: var(--color-gray-700);
  --color-icon-primary-disabled: var(--color-gray-200);
  --color-icon-secondary: var(--color-gray-500);
  --color-icon-smart: var(--color-purple-400);
  --color-icon-success: var(--color-green-500);
  --color-icon-voice-blue: var(--color-blue-500);
  --color-icon-voice-green: var(--color-green-500);
  --color-icon-voice-pink: var(--color-pink-500);
  --color-icon-voice-purple: var(--color-purple-500);
  --color-icon-voice-sky: var(--color-tuquoise-500);
  --color-text-alert: var(--color-orange-400);
  --color-text-disabled: var(--color-gray-300);
  --color-text-draft: var(--color-gray-500);
  --color-text-error: var(--color-red-600);
  --color-text-informative: var(--color-blue-500);
  --color-text-informative-high-contrast: var(--color-blue-800);
  --color-text-informative-low-contrast: var(--color-blue-700);
  --color-text-positive: var(--color-green-500);
  --color-text-positive-high-contrast: var(--color-green-800);
  --color-text-positive-low-contrast: var(--color-green-700);
  --color-text-premium-high-contrast: var(--color-gold-800);
  --color-text-premium-low-contrast: var(--color-gold-700);
  --color-text-primary: var(--color-gray-900);
  --color-text-primary-inverse: var(--color-white);
  --color-text-publish: var(--color-green-700);
  --color-text-schedule: var(--color-purple-700);
  --color-text-secondary: var(--color-gray-500);
  --color-text-smart-high-contrast: var(--color-purple-800);
  --color-text-smart-low-contrast: var(--color-purple-700);
  --color-text-tertiary: var(--color-gray-400);
}
.theme-dark {
  /* color */
  --color-bg-ai: var(--color-purple-400);
  --color-bg-ai-hover: var(--color-purple-200);
  --color-bg-smart: var(--color-purple-200);
  --color-bg-avatar: var(--color-gray-700);
  --color-bg-comp: var(--color-gray-800);
  --color-bg-destructive: var(--color-red-300);
  --color-bg-destructive-hover: var(--color-red-200);
  --color-bg-destructive-weak: var(--color-red-900);
  --color-bg-disabled: var(--color-gray-600);
  --color-bg-draft: var(--opacity-medium-contrast-on-dark);
  --color-bg-highlight: var(--color-system-crome-highlight);
  --color-bg-informative-subtle: var(--color-blue-800);
  --color-bg-informative-weak: var(--color-blue-900);
  --color-bg-nav: var(--color-gray-900);
  --color-bg-null: var(--opacity-no-contrast-on-dark);
  --color-bg-overlay-high-contrast: var(--opacity-high-contrast-on-dark);
  --color-bg-overlay-low-contrast: var(--opacity-low-contrast-on-dark);
  --color-bg-overlay-medium-contrast: var(--opacity-medium-contrast-on-dark);
  --color-bg-overlay-very-high-contrast: var(--opacity-modal-constrast);
  --color-bg-premium: var(--color-gold-100);
  --color-bg-premium-subtle: var(--color-gold-800);
  --color-bg-premium-weak: var(--color-gold-900);
  --color-bg-primary: var(--color-gray-900);
  --color-bg-primary-enabled: var(--color-gray-700);
  --color-bg-primary-hover: var(--opacity-medium-contrast-on-dark);
  --color-bg-primary-inverse: var(--color-white);
  --color-bg-primary-pressed: var(--opacity-high-contrast-on-dark);
  --color-bg-primary-selected: var(--color-white);
  --color-bg-primary-unslelected: var(--color-gray-800);
  --color-bg-publish: var(--color-green-700);
  --color-bg-schedule: var(--color-purple-700);
  --color-bg-secondary: var(--color-gray-800);
  --color-bg-secondary-inverse: var(--color-gray-50);
  --color-bg-secondary-selected: var(--color-gray-50);
  --color-bg-skeleton: var(--color-gray-700);
  --color-bg-smart: var(--color-purple-900);
  --color-bg-smart-subtle: var(--color-purple-800);
  --color-bg-smart-weak: var(--color-purple-900);
  --color-bg-success: var(--color-green-200);
  --color-bg-success-subtle: var(--color-green-800);
  --color-bg-success-weak: var(--color-green-900);
  --color-bg-surface: var(--color-gray-900);
  --color-bg-tertiary: var(--color-gray-700);
  --color-bg-unselected-secondary: var(--color-gray-700);
  --color-border-ai: var(--color-purple-600);
  --color-border-ai-hover: var(--color-purple-200);
  --color-border-destructive: var(--color-red-700);
  --color-border-disabled: var(--color-gray-800);
  --color-border-focus: var(--color-system-crome-focus);
  --color-border-high-contrast: var(--opacity-high-contrast-on-dark);
  --color-border-informative: var(--color-blue-700);
  --color-border-medium-contrast: var(--opacity-medium-contrast-on-dark);
  --color-border-positive: var(--color-green-700);
  --color-border-premium: var(--color-gold-700);
  --color-border-primary: var(--color-white);
  --color-border-secondary: var(--color-gray-500);
  --color-border-smart: var(--color-purple-700);
  --color-border-tertiary: var(--color-gray-600);
  --color-border-warning: var(--color-orange-700);
  --color-border-weak: var(--color-gray-600);
  --color-icon-ai: var(--color-white);
  --color-icon-ai-hover: var(--color-white);
  --color-icon-alert: var(--color-orange-200);
  --color-icon-disabled: var(--color-gray-500);
  --color-icon-error: var(--color-red-200);
  --color-icon-info: var(--color-blue-200);
  --color-icon-inverse-primary: var(--color-black);
  --color-icon-premium: var(--color-gold-200);
  --color-icon-primary: var(--color-white);
  --color-icon-primary-disabled: var(--color-gray-500);
  --color-icon-secondary: var(--color-gray-100);
  --color-icon-smart: var(--color-purple-200);
  --color-icon-success: var(--color-green-200);
  --color-icon-voice-blue: var(--color-blue-200);
  --color-icon-voice-green: var(--color-green-200);
  --color-icon-voice-pink: var(--color-pink-200);
  --color-icon-voice-purple: var(--color-purple-200);
  --color-icon-voice-sky: var(--color-tuquoise-200);
  --color-text-alert: var(--color-orange-200);
  --color-text-disabled: var(--color-gray-900);
  --color-text-draft: var(--color-gray-200);
  --color-text-error: var(--color-red-200);
  --color-text-informative: var(--color-blue-200);
  --color-text-informative-high-contrast: var(--color-blue-50);
  --color-text-informative-low-contrast: var(--color-blue-100);
  --color-text-positive: var(--color-green-200);
  --color-text-positive-high-contrast: var(--color-green-50);
  --color-text-positive-low-contrast: var(--color-green-100);
  --color-text-premium-high-contrast: var(--color-gold-50);
  --color-text-premium-low-contrast: var(--color-gold-100);
  --color-text-primary: var(--color-white);
  --color-text-primary-inverse: var(--color-gray-900);
  --color-text-publish: var(--color-green-200);
  --color-text-schedule: var(--color-purple-200);
  --color-text-secondary: var(--color-gray-100);
  --color-text-smart-high-contrast: var(--color-purple-50);
  --color-text-smart-low-contrast: var(--color-purple-100);
  --color-text-tertiary: var(--color-gray-200);
}
:root {
  /* color */
  --color-black: #000000;
  --color-white: #ffffff;
  --color-blue-100: #c5d0fb;
  --color-blue-200: #90a9f8;
  --color-blue-300: #638cf8;
  --color-blue-400: #387af0;
  --color-blue-50: #f1f3fe;
  --color-blue-500: #2261d3;
  --color-blue-600: #184087;
  --color-blue-700: #082559;
  --color-blue-800: #061e4c;
  --color-blue-900: #010b23;
  --color-gold-100: #f9ce8e;
  --color-gold-200: #e3af4a;
  --color-gold-300: #b38937;
  --color-gold-400: #947333;
  --color-gold-50: #fdf2e5;
  --color-gold-500: #775a22;
  --color-gold-600: #4d3a13;
  --color-gold-700: #2d2008;
  --color-gold-800: #241a05;
  --color-gold-900: #120b02;
  --color-gray-100: #dedfe3;
  --color-gray-200: #a6a8ad;
  --color-gray-300: #8c8f96;
  --color-gray-400: #71747b;
  --color-gray-50: #f2f3f4;
  --color-gray-500: #56585e;
  --color-gray-600: #3a3c40;
  --color-gray-700: #1f2024;
  --color-gray-800: #18191c;
  --color-gray-900: #0b0c0d;
  --color-green-100: #b6e2cb;
  --color-green-200: #85c2a1;
  --color-green-300: #52b27f;
  --color-green-400: #1cab5f;
  --color-green-50: #ecf9ed;
  --color-green-500: #188149;
  --color-green-600: #0b5b30;
  --color-green-700: #04341a;
  --color-green-800: #052916;
  --color-green-900: #010f08;
  --color-orange-100: #f7c1b5;
  --color-orange-200: #f68f6f;
  --color-orange-300: #e57038;
  --color-orange-400: #c95b22;
  --color-orange-50: #fdf1ef;
  --color-orange-500: #a34515;
  --color-orange-600: #6b2d0d;
  --color-orange-700: #441803;
  --color-orange-800: #381100;
  --color-orange-900: #1b0601;
  --color-pink-100: #fdbcc8;
  --color-pink-200: #fb839f;
  --color-pink-300: #fa5686;
  --color-pink-400: #ea3979;
  --color-pink-50: #fef0f2;
  --color-pink-500: #da1b60;
  --color-pink-600: #84153d;
  --color-pink-700: #5c0523;
  --color-pink-800: #48051b;
  --color-pink-900: #210109;
  --color-purple-100: #d2c7f9;
  --color-purple-200: #b097fc;
  --color-purple-300: #9872f8;
  --color-purple-400: #8854f2;
  --color-purple-50: #f4f2fe;
  --color-purple-500: #7c32e6;
  --color-purple-600: #541aa8;
  --color-purple-700: #360a76;
  --color-purple-800: #2c0665;
  --color-purple-900: #12022f;
  --color-red-100: #fcbebe;
  --color-red-200: #fa8686;
  --color-red-300: #fa6061;
  --color-red-400: #ea4345;
  --color-red-50: #fef0f0;
  --color-red-500: #e71d20;
  --color-red-600: #aa0e0f;
  --color-red-700: #670405;
  --color-red-800: #540304;
  --color-red-900: #220101;
  --color-system-crome-focus: #5e9ed6;
  --color-system-crome-highlight: #accef7;
  --color-tuquoise-100: #ade1df;
  --color-tuquoise-200: #7ebebe;
  --color-tuquoise-300: #4ba9a8;
  --color-tuquoise-400: #239998;
  --color-tuquoise-50: #dcf9f9;
  --color-tuquoise-500: #1a7a79;
  --color-tuquoise-600: #144d4c;
  --color-tuquoise-700: #042f2e;
  --color-tuquoise-800: #042525;
  --color-tuquoise-900: #010e0e;
  /* number */
  --font-font-size-12: 0.75rem; /* 12px */
  --font-font-size-14: 0.875rem; /* 14px */
  --font-font-size-16: 1rem; /* 16px */
  --font-font-size-20: 1.25rem; /* 20px */
  --font-font-size-24: 1.5rem; /* 24px */
  --font-font-size-28: 1.75rem; /* 28px */
  --font-font-size-32: 2rem; /* 32px */
  --font-font-size-40: 2.5rem; /* 40px */
  --font-font-size-48: 3rem; /* 48px */
  --font-font-size-64: 4rem; /* 64px */
  --font-font-size-76: 4.75rem; /* 76px */
  --font-font-size-96: 6rem; /* 96px */
  --font-font-size-120: 7.5rem; /* 120px */
  --font-letter-spacing--1_5: -0.09375rem; /* -1.5px */
  --font-letter-spacing--0_5: -0.03125rem; /* -0.5px */
  --font-letter-spacing-0: 0rem; /* 0px */
  --font-letter-spacing-0_5: 0.03125rem; /* 0.5px */
  --font-letter-spacing-1_5: 0.09375rem; /* 1.5px */
  --font-line-height-16: 1rem; /* 16px */
  --font-line-height-20: 1.25rem; /* 20px */
  --font-line-height-24: 1.5rem; /* 24px */
  --font-line-height-28: 1.75rem; /* 28px */
  --font-line-height-32: 2rem; /* 32px */
  --font-line-height-40: 2.5rem; /* 40px */
  --font-line-height-48: 3rem; /* 48px */
  --font-line-height-56: 3.5rem; /* 56px */
  --font-line-height-64: 4rem; /* 64px */
  --font-paragraph-spacing-null: 0rem; /* 0px */
  --font-paragraph-spacing-16: 1rem; /* 16px */
  --font-paragraph-spacing-20: 1.25rem; /* 20px */
  --font-paragraph-spacing-24: 1.5rem; /* 24px */
  --font-paragraph-spacing-28: 1.75rem; /* 28px */
  --font-paragraph-spacing-32: 2rem; /* 32px */
  --font-paragraph-spacing-40: 2.5rem; /* 40px */
  --font-paragraph-spacing-48: 3rem; /* 48px */
  --motion-duration-xxs: 3.125rem; /* 50px */
  --motion-duration-xs: 6.25rem; /* 100px */
  --motion-duration-sm: 12.5rem; /* 200px */
  --motion-duration-md: 18.75rem; /* 300px */
  --motion-duration-lg: 25rem; /* 400px */
  --motion-duration-xl: 31.25rem; /* 500px */
  --motion-duration-2xl: 37.5rem; /* 600px */
  --motion-duration-3xl: 43.75rem; /* 700px */
  --radius-small: 0.375rem; /* 6px */
  --radius-medium: 0.75rem; /* 12px */
  --radius-large: 1.5rem; /* 24px */
  --radius-round: 624.9375rem; /* 9999px */
  --scale-4: 0.25rem; /* 4px */
  --scale-6: 0.375rem; /* 6px */
  --scale-8: 0.5rem; /* 8px */
  --scale-12: 0.75rem; /* 12px */
  --scale-16: 1rem; /* 16px */
  --scale-20: 1.25rem; /* 20px */
  --scale-24: 1.5rem; /* 24px */
  --scale-32: 2rem; /* 32px */
  --scale-40: 2.5rem; /* 40px */
  --scale-48: 3rem; /* 48px */
  --scale-56: 3.5rem; /* 56px */
  --scale-64: 4rem; /* 64px */
  --scale-72: 4.5rem; /* 72px */
  --scale-80: 5rem; /* 80px */
  --scale-108: 6.75rem; /* 108px */
  --scale-128: 8rem; /* 128px */
  --scale-180: 11.25rem; /* 180px */
  --scale-9999: 624.9375rem; /* 9999px */
  /* string */
  --font-font-case-uppercase: uppercase;
  --font-font-family-base-font-sans: Satoshi;
  --font-font-weight-bold: bold;
  --font-font-weight-medium: medium;
  --font-font-weight-regular: regular;
}
.theme-dark,
.theme-light {
  /* color */
  --progress-circle-color-bg-progress-circle-high-contrast: var(--color-bg-overlay-high-contrast);
  --progress-circle-color-bg-progress-circle-primary-active: var(--color-bg-primary-inverse);
  --toolbar-global-color-bg-default: var(--color-bg-comp);
  --toolbar-global-color-border-default: var(--color-border-tertiary);
  --toolbar-global-color-text-default: var(--color-text-primary);
  --avatar-color-bg-avatar-default: var(--color-bg-avatar);
  --avatar-color-border-avatar-default: var(--color-border-high-contrast);
  --avatar-color-text-avatar-default: var(--color-text-primary);
  --button-group-color-bg-button-group-disabled: var(--color-bg-disabled);
  --button-group-color-bg-button-group-enabled: var(--color-bg-null);
  --button-group-color-bg-button-group-hover: var(--color-bg-primary);
  --button-group-color-bg-button-secondary-hover: var(--color-bg-primary);
  --button-group-color-border-button-group-enabled: var(--color-border-tertiary);
  --button-group-color-border-button-group-focus: var(--color-border-focus);
  --button-group-color-border-button-group-hover: var(--color-border-primary);
  --button-group-color-icon-button-group-default: var(--color-icon-primary);
  --button-group-color-icon-button-group-disabled: var(--color-icon-disabled);
  --buttons-color-bg-ai-default: var(--color-bg-ai);
  --buttons-color-bg-ai-hover: var(--color-bg-ai-hover);
  --buttons-color-bg-button-danger-enabled: var(--color-bg-destructive);
  --buttons-color-bg-button-danger-hover: var(--color-bg-destructive-hover);
  --buttons-color-bg-button-disabled: var(--color-bg-disabled);
  --buttons-color-bg-button-primary-enabled: var(--color-bg-primary-inverse);
  --buttons-color-bg-button-primary-hover: var(--color-bg-secondary-inverse);
  --buttons-color-bg-button-secondary-enabled: var(--color-bg-comp);
  --buttons-color-bg-button-secondary-hover: var(--color-bg-primary);
  --buttons-color-bg-button-tertiary-enabled: var(--color-bg-null);
  --buttons-color-bg-button-tertiary-hover: var(--color-bg-primary);
  --buttons-color-border-ai-default: var(--color-border-ai);
  --buttons-color-border-ai-hover: var(--color-border-ai-hover);
  --buttons-color-border-button-focus: var(--color-border-focus);
  --buttons-color-border-button-secondary-enabled: var(--color-border-secondary);
  --buttons-color-border-button-secondary-hover: var(--color-border-primary);
  --buttons-color-icon-ai-default: var(--color-icon-ai);
  --buttons-color-icon-ai-hover: var(--color-icon-ai-hover);
  --buttons-color-icon-button-disabled: var(--color-icon-disabled);
  --buttons-color-icon-button-primary-enabled: var(--color-icon-inverse-primary);
  --buttons-color-icon-button-primary-hover: var(--color-icon-inverse-primary);
  --buttons-color-icon-secondary-default: var(--color-icon-primary);
  --buttons-color-icon-secondary-disabled: var(--color-icon-disabled);
  --buttons-color-icon-secondary-hover: var(--color-icon-primary);
  --buttons-color-text-button-danger: var(--color-text-primary-inverse);
  --buttons-color-text-button-disabled: var(--color-text-disabled);
  --buttons-color-text-button-primary: var(--color-text-primary-inverse);
  --buttons-color-text-button-secondary: var(--color-text-primary);
  --buttons-color-text-button-tertiary: var(--color-text-primary);
  --buttons-color-text-secondary-disabled: var(--color-text-disabled);
  --calendar-color-bg-primary-default: var(--color-bg-comp);
  --calendar-day-color-bg-primary-default: var(--color-bg-comp);
  --calendar-day-color-bg-primary-hover: var(--color-bg-secondary);
  --calendar-day-color-bg-primary-selected: var(--color-bg-primary-selected);
  --calendar-day-color-bg-primary-selected-hover: var(--color-bg-secondary-selected);
  --calendar-day-color-text-primary-default: var(--color-text-primary);
  --calendar-day-color-text-primary-disabled: var(--color-text-disabled);
  --calendar-day-color-text-primary-empty: var(--color-text-primary-inverse);
  --calendar-day-color-text-primary-selected: var(--color-text-primary-inverse);
  --calendar-header-color-bg-primary-default: var(--color-bg-comp);
  --calendar-header-color-bg-primary-hover: var(--color-bg-secondary);
  --calendar-header-color-text-primary: var(--color-text-primary);
  --calendar-month-color-bg-primary-default: var(--color-bg-comp);
  --calendar-month-color-bg-primary-hover: var(--color-bg-secondary);
  --calendar-month-color-bg-primary-selected: var(--color-bg-primary-selected);
  --calendar-month-color-bg-primary-selected-hover: var(--color-bg-secondary-selected);
  --calendar-month-color-text-primary-default: var(--color-text-primary);
  --calendar-month-color-text-primary-disabled: var(--color-text-disabled);
  --calendar-month-color-text-primary-selected: var(--color-text-primary-inverse);
  --calendar-navigation-color-bg-primary-default: var(--color-bg-comp);
  --calendar-navigation-color-bg-primary-hover: var(--color-bg-secondary);
  --calendar-navigation-color-icon-primary-default: var(--color-icon-primary);
  --calendar-week-color-bg-primary-default: var(--color-bg-comp);
  --calendar-week-color-text-weak: var(--color-text-tertiary);
  --checkbox-color-bg-primary-active: var(--color-bg-comp);
  --checkbox-color-bg-primary-default: var(--color-bg-comp);
  --checkbox-color-bg-primary-disabled: var(--color-bg-disabled);
  --checkbox-color-bg-primary-hover: var(--color-bg-comp);
  --checkbox-color-bg-primary-selected: var(--color-bg-primary-inverse);
  --checkbox-color-border-focus: var(--color-border-focus);
  --checkbox-color-border-primary-default: var(--color-border-secondary);
  --checkbox-color-border-primary-disabled: var(--color-border-disabled);
  --checkbox-color-border-primary-hover: var(--color-border-primary);
  --checkbox-color-icon-primary: var(--color-icon-inverse-primary);
  --checkbox-color-icon-primary-disabled: var(--color-icon-disabled);
  --checkbox-color-text-primary: var(--color-text-primary);
  --checkbox-color-text-weak: var(--color-text-tertiary);
  --chip-color-bg-chip-disabled: var(--color-bg-disabled);
  --chip-color-bg-chip-primary-active-hover: var(--color-bg-secondary-selected);
  --chip-color-bg-chip-primary-selected-enabled: var(--color-bg-primary-selected);
  --chip-color-bg-chip-primary-selected-hover: var(--color-bg-secondary-selected);
  --chip-color-bg-chip-primary-unselected-default: var(--color-bg-primary-unslelected);
  --chip-color-bg-chip-primary-unselected-hover: var(--color-bg-unselected-secondary);
  --chip-color-border-chip-focus: var(--color-border-focus);
  --chip-color-icon-chip-active-default: var(--color-icon-inverse-primary);
  --chip-color-icon-chip-disabled: var(--color-icon-primary-disabled);
  --chip-color-icon-chip-selected-default: var(--color-icon-inverse-primary);
  --chip-color-icon-chip-unselected-default: var(--color-icon-primary);
  --chip-color-text-chip-disabled: var(--color-text-disabled);
  --chip-color-text-chip-primary-active: var(--color-text-primary-inverse);
  --chip-color-text-chip-primary-selected: var(--color-text-primary-inverse);
  --chip-color-text-chip-primary-unselected: var(--color-text-primary);
  --combo-button-color-bg-combo-button-disabled: var(--color-bg-disabled);
  --combo-button-color-bg-combo-button-primary-enabled: var(--color-bg-primary-inverse);
  --combo-button-color-bg-combo-button-primary-hover: var(--color-bg-secondary-inverse);
  --combo-button-color-border-combo-button-default: var(--color-border-secondary);
  --combo-button-color-border-combo-button-disabled: var(--color-border-disabled);
  --combo-button-color-border-combo-button-focus: var(--color-border-focus);
  --combo-button-color-icon-combo-button-default: var(--color-icon-inverse-primary);
  --combo-button-color-icon-combo-button-disabled: var(--color-icon-disabled);
  --combo-button-color-text-combo-button-disabled: var(--color-text-disabled);
  --combo-button-color-text-combo-button-primary: var(--color-text-primary-inverse);
  --datepicker-color-bg-highlight: var(--color-bg-highlight);
  --datepicker-color-bg-primary-active: var(--color-bg-comp);
  --datepicker-color-bg-primary-default: var(--color-bg-comp);
  --datepicker-color-bg-primary-disabled: var(--color-bg-disabled);
  --datepicker-color-bg-primary-hover: var(--color-bg-comp);
  --datepicker-color-border-focus: var(--color-border-focus);
  --datepicker-color-border-primary-active: var(--color-border-primary);
  --datepicker-color-border-primary-default: var(--color-border-secondary);
  --datepicker-color-border-primary-disabled: var(--color-border-disabled);
  --datepicker-color-border-primary-hover: var(--color-border-primary);
  --datepicker-color-icon-primary-active: var(--color-icon-primary);
  --datepicker-color-icon-primary-default: var(--color-icon-primary);
  --datepicker-color-icon-primary-disabled: var(--color-icon-disabled);
  --datepicker-color-icon-primary-error: var(--color-icon-error);
  --datepicker-color-icon-primary-hover: var(--color-icon-primary);
  --datepicker-color-text-primary-disabled: var(--color-text-disabled);
  --datepicker-color-text-primary-error: var(--color-text-error);
  --datepicker-color-text-primary-selected: var(--color-text-primary);
  --datepicker-color-text-weak: var(--color-text-secondary);
  --dropdown-color-bg-primary-active: var(--color-bg-comp);
  --dropdown-color-bg-primary-default: var(--color-bg-comp);
  --dropdown-color-bg-primary-disabled: var(--color-bg-disabled);
  --dropdown-color-border-focus: var(--color-border-focus);
  --dropdown-color-border-primary-default: var(--color-border-secondary);
  --dropdown-color-border-primary-disabled: var(--color-border-disabled);
  --dropdown-color-border-primary-hover: var(--color-border-primary);
  --dropdown-color-icon-disabled: var(--color-icon-disabled);
  --dropdown-color-icon-error: var(--color-icon-error);
  --dropdown-color-icon-primary: var(--color-icon-primary);
  --dropdown-color-text-error: var(--color-text-error);
  --dropdown-color-text-label-weak: var(--color-text-secondary);
  --dropdown-color-text-primary-selected: var(--color-text-primary);
  --dropdown-color-text-selected-disabled: var(--color-text-disabled);
  --dropdown-color-text-weak-unselected: var(--color-text-tertiary);
  --header-color-bg-primary-default: var(--color-bg-surface);
  --header-color-text-primary-default: var(--color-text-primary);
  --header-color-text-tertiary-default: var(--color-text-tertiary);
  --header-headernav-color-bg-primary-default: var(--color-bg-surface);
  --header-headernav-color-bg-primary-hover: var(--color-bg-primary-hover);
  --header-headernav-color-bg-primary-pressed: var(--color-bg-primary-pressed);
  --header-headernav-color-border-focus: var(--color-border-focus);
  --header-headernav-color-icon-primary: var(--color-icon-primary);
  --header-headernav-color-text-primary: var(--color-text-primary);
  --labels-color-bg-draft: var(--color-bg-draft);
  --labels-color-bg-publish: var(--color-bg-publish);
  --labels-color-bg-schedule: var(--color-bg-schedule);
  --labels-color-text-draft: var(--color-text-draft);
  --labels-color-text-publish: var(--color-text-publish);
  --labels-color-text-schedule: var(--color-text-schedule);
  --navigation-color-bg-primary: var(--color-bg-nav);
  --navigation-icon-color-primary: var(--color-icon-inverse-primary);
  --navigation-icono-color-weak: #ffffff;
  --radiobuttons-color-bg-primary-default: var(--color-bg-comp);
  --radiobuttons-color-bg-primary-disabled: var(--color-bg-disabled);
  --radiobuttons-color-bg-primary-hover: var(--color-bg-comp);
  --radiobuttons-color-bg-primary-selected: var(--color-bg-primary-inverse);
  --radiobuttons-color-border-focus: var(--color-border-focus);
  --radiobuttons-color-border-primary-default: var(--color-border-secondary);
  --radiobuttons-color-border-primary-disabled: var(--color-border-disabled);
  --radiobuttons-color-border-primary-selected: var(--color-border-primary);
  --radiobuttons-color-icon-primary-disabled: var(--color-icon-disabled);
  --radiobuttons-color-icon-primary-selected: var(--color-icon-inverse-primary);
  --radiobuttons-color-text-primary: var(--color-text-primary);
  --radiobuttons-color-text-weak: var(--color-text-tertiary);
  --sidebar-color-bg-primary: var(--color-bg-comp);
  --sidebar-color-border-weak: var(--color-border-tertiary);
  --sidebar-color-icon-primary: var(--color-icon-primary);
  --sidebar-color-text-primary: var(--color-text-primary);
  --sidebar-menuitem-color-bg-primary-default: var(--color-bg-comp);
  --sidebar-menuitem-color-bg-primary-hover: var(--color-bg-primary-hover);
  --sidebar-menuitem-color-bg-primary-pressed: var(--color-bg-primary-pressed);
  --sidebar-menuitem-color-border-focus: var(--color-border-focus);
  --sidebar-menuitem-color-icon-primary: var(--color-icon-primary);
  --sidebar-menuitem-color-text-primary: var(--color-text-primary);
  --textarea-color-bg-primary-active: var(--color-bg-comp);
  --textarea-color-bg-primary-default: var(--color-bg-comp);
  --textarea-color-bg-primary-disabled: var(--color-bg-disabled);
  --textarea-color-border-focus: var(--color-border-focus);
  --textarea-color-border-primary-active: var(--color-border-primary);
  --textarea-color-border-primary-default: var(--color-border-secondary);
  --textarea-color-border-primary-disabled: var(--color-border-disabled);
  --textarea-color-icon-error: var(--color-icon-error);
  --textarea-color-text-error: var(--color-text-error);
  --textarea-color-text-input-disabled: var(--color-text-disabled);
  --textarea-color-text-input-primary: var(--color-text-primary);
  --textarea-color-text-label-disabled: var(--color-text-disabled);
  --textarea-color-text-label-weak: var(--color-text-secondary);
  --textinput-color-bg-primary-default: var(--color-bg-comp);
  --textinput-color-bg-primary-disabled: var(--color-bg-disabled);
  --textinput-color-border-focus: var(--color-border-focus);
  --textinput-color-border-primary-active: var(--color-border-primary);
  --textinput-color-border-primary-default: var(--color-border-secondary);
  --textinput-color-border-primary-disabled: var(--color-border-disabled);
  --textinput-color-border-error: var(--color-border-error);
  --textinput-color-icon-error: var(--color-icon-error);
  --textinput-color-text-error: var(--color-text-error);
  --textinput-color-text-input-disabled: var(--color-text-disabled);
  --textinput-color-text-input-primary: var(--color-text-primary);
  --textinput-color-text-input-weak: var(--color-text-tertiary);
  --textinput-color-text-label-disabled: var(--color-text-disabled);
  --textinput-color-text-label-weak: var(--color-text-secondary);
  --thumbnail-color-border-thumbnail-default: var(--color-border-high-contrast);
  --timepicker-color-bg-primary-active: var(--color-bg-comp);
  --timepicker-color-bg-primary-default: var(--color-bg-comp);
  --timepicker-color-bg-primary-disabled: var(--color-bg-disabled);
  --timepicker-color-bg-primary-hover: var(--color-bg-comp);
  --timepicker-color-border-primary-active: #ffffff;
  --timepicker-color-border-primary-default: var(--color-border-secondary);
  --timepicker-color-border-primary-disabled: #ffffff;
  --timepicker-color-border-primary-hover: #ffffff;
  --timepicker-color-text-primary-disabled: var(--color-text-disabled);
  --timepicker-color-text-primary-error: var(--color-text-error);
  --timepicker-color-text-primary-selected: var(--color-text-primary);
  --timepicker-color-text-weak: var(--color-text-secondary);
}
@media (min-width: $xl) {
  :root {
    /* number */
    --type-scale-body-lg-letter-spacing: var(--font-letter-spacing-0);
    --type-scale-body-lg-font-size: var(--font-font-size-16);
    --type-scale-body-lg-paragraph-spacing: var(--font-paragraph-spacing-16);
    --type-scale-body-lg-line-height: var(--font-line-height-24);
    --type-scale-body-md-letter-spacing: var(--font-letter-spacing-0);
    --type-scale-body-md-font-size: var(--font-font-size-14);
    --type-scale-body-md-paragraph-spacing: var(--font-paragraph-spacing-16);
    --type-scale-body-md-line-height: var(--font-line-height-20);
    --type-scale-body-sm-letter-spacing: var(--font-letter-spacing-0_5);
    --type-scale-body-sm-font-size: var(--font-font-size-12);
    --type-scale-body-sm-line-height: var(--font-line-height-16);
    --type-scale-body-sm-paragraph-spacing: var(--font-paragraph-spacing-16);
    --type-scale-button-lg-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-button-lg-letter-spacing: var(--font-letter-spacing-0_5);
    --type-scale-button-lg-font-size: var(--font-font-size-16);
    --type-scale-button-lg-line-height: var(--font-line-height-24);
    --type-scale-button-md-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-button-md-letter-spacing: var(--font-letter-spacing-0_5);
    --type-scale-button-md-font-size: var(--font-font-size-14);
    --type-scale-button-md-line-height: var(--font-line-height-24);
    --type-scale-button-sm-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-button-sm-letter-spacing: var(--font-letter-spacing-0_5);
    --type-scale-button-sm-font-size: var(--font-font-size-12);
    --type-scale-button-sm-line-height: var(--font-line-height-16);
    --type-scale-heading-2xl-letter-spacing: var(--font-letter-spacing-0);
    --type-scale-heading-2xl-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-heading-2xl-font-size: var(--font-font-size-28);
    --type-scale-heading-2xl-line-height: var(--font-line-height-40);
    --type-scale-heading-3xl-letter-spacing: var(--font-letter-spacing-0);
    --type-scale-heading-3xl-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-heading-3xl-font-size: var(--font-font-size-32);
    --type-scale-heading-3xl-line-height: var(--font-line-height-48);
    --type-scale-heading-4xl-letter-spacing: var(--font-letter-spacing--0_5);
    --type-scale-heading-4xl-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-heading-4xl-font-size: var(--font-font-size-40);
    --type-scale-heading-4xl-line-height: var(--font-line-height-56);
    --type-scale-heading-5xl-letter-spacing: var(--font-letter-spacing--1_5);
    --type-scale-heading-5xl-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-heading-5xl-font-size: var(--font-font-size-48);
    --type-scale-heading-5xl-line-height: var(--font-line-height-64);
    --type-scale-heading-lg-letter-spacing: var(--font-letter-spacing-0);
    --type-scale-heading-lg-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-heading-lg-font-size: var(--font-font-size-20);
    --type-scale-heading-lg-line-height: var(--font-line-height-28);
    --type-scale-heading-md-letter-spacing: var(--font-letter-spacing-0);
    --type-scale-heading-md-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-heading-md-font-size: var(--font-font-size-16);
    --type-scale-heading-md-line-height: var(--font-line-height-24);
    --type-scale-heading-sm-letter-spacing: var(--font-letter-spacing-0);
    --type-scale-heading-sm-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-heading-sm-font-size: var(--font-font-size-14);
    --type-scale-heading-sm-line-height: var(--font-line-height-20);
    --type-scale-heading-xl-letter-spacing: var(--font-letter-spacing-0);
    --type-scale-heading-xl-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-heading-xl-font-size: var(--font-font-size-24);
    --type-scale-heading-xl-line-height: var(--font-line-height-32);
    /* string */
    --type-scale-body-lg-font-family: var(--font-font-family-base-font-sans);
    --type-scale-body-lg-font-weight: var(--font-font-weight-medium);
    --type-scale-body-md-font-family: var(--font-font-family-base-font-sans);
    --type-scale-body-md-font-weight: var(--font-font-weight-medium);
    --type-scale-body-sm-font-family: var(--font-font-family-base-font-sans);
    --type-scale-body-sm-font-weight: var(--font-font-weight-medium);
    --type-scale-button-lg-font-case: var(--font-font-case-uppercase);
    --type-scale-button-lg-font-family: var(--font-font-family-base-font-sans);
    --type-scale-button-lg-font-weight: var(--font-font-weight-bold);
    --type-scale-button-md-font-case: var(--font-font-case-uppercase);
    --type-scale-button-md-font-family: var(--font-font-family-base-font-sans);
    --type-scale-button-md-font-weight: var(--font-font-weight-bold);
    --type-scale-button-sm-font-case: var(--font-font-case-uppercase);
    --type-scale-button-sm-font-family: var(--font-font-family-base-font-sans);
    --type-scale-button-sm-font-weight: var(--font-font-weight-bold);
    --type-scale-heading-2xl-font-family: var(--font-font-family-base-font-sans);
    --type-scale-heading-2xl-font-weight: var(--font-font-weight-bold);
    --type-scale-heading-3xl-font-family: var(--font-font-family-base-font-sans);
    --type-scale-heading-3xl-font-weight: var(--font-font-weight-bold);
    --type-scale-heading-4xl-font-family: var(--font-font-family-base-font-sans);
    --type-scale-heading-4xl-font-weight: var(--font-font-weight-bold);
    --type-scale-heading-5xl-font-family: var(--font-font-family-base-font-sans);
    --type-scale-heading-5xl-font-weight: var(--font-font-weight-bold);
    --type-scale-heading-lg-font-family: var(--font-font-family-base-font-sans);
    --type-scale-heading-lg-font-weight: var(--font-font-weight-bold);
    --type-scale-heading-md-font-family: var(--font-font-family-base-font-sans);
    --type-scale-heading-md-font-weight: var(--font-font-weight-bold);
    --type-scale-heading-sm-font-family: var(--font-font-family-base-font-sans);
    --type-scale-heading-sm-font-weight: var(--font-font-weight-bold);
    --type-scale-heading-xl-font-family: var(--font-font-family-base-font-sans);
    --type-scale-heading-xl-font-weight: var(--font-font-weight-bold);
  }
}
@media (min-width: $xs) {
  :root {
    /* number */
    --type-scale-body-lg-letter-spacing: var(--font-letter-spacing-0);
    --type-scale-body-lg-font-size: var(--font-font-size-16);
    --type-scale-body-lg-paragraph-spacing: var(--font-paragraph-spacing-16);
    --type-scale-body-lg-line-height: var(--font-line-height-24);
    --type-scale-body-md-letter-spacing: var(--font-letter-spacing-0);
    --type-scale-body-md-font-size: var(--font-font-size-14);
    --type-scale-body-md-paragraph-spacing: var(--font-paragraph-spacing-16);
    --type-scale-body-md-line-height: var(--font-line-height-20);
    --type-scale-body-sm-letter-spacing: var(--font-letter-spacing-0_5);
    --type-scale-body-sm-font-size: var(--font-font-size-12);
    --type-scale-body-sm-line-height: var(--font-line-height-16);
    --type-scale-body-sm-paragraph-spacing: var(--font-paragraph-spacing-16);
    --type-scale-button-lg-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-button-lg-letter-spacing: var(--font-letter-spacing-0_5);
    --type-scale-button-lg-font-size: var(--font-font-size-16);
    --type-scale-button-lg-line-height: var(--font-line-height-24);
    --type-scale-button-md-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-button-md-letter-spacing: var(--font-letter-spacing-0_5);
    --type-scale-button-md-font-size: var(--font-font-size-14);
    --type-scale-button-md-line-height: var(--font-line-height-24);
    --type-scale-button-sm-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-button-sm-letter-spacing: var(--font-letter-spacing-0_5);
    --type-scale-button-sm-font-size: var(--font-font-size-12);
    --type-scale-button-sm-line-height: var(--font-line-height-16);
    --type-scale-heading-2xl-letter-spacing: var(--font-letter-spacing-0);
    --type-scale-heading-2xl-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-heading-2xl-font-size: var(--font-font-size-24);
    --type-scale-heading-2xl-line-height: var(--font-line-height-32);
    --type-scale-heading-3xl-letter-spacing: var(--font-letter-spacing-0);
    --type-scale-heading-3xl-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-heading-3xl-font-size: var(--font-font-size-28);
    --type-scale-heading-3xl-line-height: var(--font-line-height-40);
    --type-scale-heading-4xl-letter-spacing: var(--font-letter-spacing-0);
    --type-scale-heading-4xl-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-heading-4xl-font-size: var(--font-font-size-32);
    --type-scale-heading-4xl-line-height: var(--font-line-height-48);
    --type-scale-heading-5xl-letter-spacing: var(--font-letter-spacing--0_5);
    --type-scale-heading-5xl-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-heading-5xl-font-size: var(--font-font-size-40);
    --type-scale-heading-5xl-line-height: var(--font-line-height-56);
    --type-scale-heading-lg-letter-spacing: var(--font-letter-spacing-0);
    --type-scale-heading-lg-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-heading-lg-font-size: var(--font-font-size-16);
    --type-scale-heading-lg-line-height: var(--font-line-height-24);
    --type-scale-heading-md-letter-spacing: var(--font-letter-spacing-0);
    --type-scale-heading-md-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-heading-md-font-size: var(--font-font-size-14);
    --type-scale-heading-md-line-height: var(--font-line-height-20);
    --type-scale-heading-sm-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-heading-sm-letter-spacing: var(--font-letter-spacing-0_5);
    --type-scale-heading-sm-font-size: var(--font-font-size-12);
    --type-scale-heading-sm-line-height: var(--font-line-height-16);
    --type-scale-heading-xl-letter-spacing: var(--font-letter-spacing-0);
    --type-scale-heading-xl-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-heading-xl-font-size: var(--font-font-size-20);
    --type-scale-heading-xl-line-height: var(--font-line-height-28);
    /* string */
    --type-scale-body-lg-font-family: var(--font-font-family-base-font-sans);
    --type-scale-body-lg-font-weight: var(--font-font-weight-medium);
    --type-scale-body-md-font-family: var(--font-font-family-base-font-sans);
    --type-scale-body-md-font-weight: var(--font-font-weight-medium);
    --type-scale-body-sm-font-family: var(--font-font-family-base-font-sans);
    --type-scale-body-sm-font-weight: var(--font-font-weight-medium);
    --type-scale-button-lg-font-case: var(--font-font-case-uppercase);
    --type-scale-button-lg-font-family: var(--font-font-family-base-font-sans);
    --type-scale-button-lg-font-weight: var(--font-font-weight-bold);
    --type-scale-button-md-font-case: var(--font-font-case-uppercase);
    --type-scale-button-md-font-family: var(--font-font-family-base-font-sans);
    --type-scale-button-md-font-weight: var(--font-font-weight-bold);
    --type-scale-button-sm-font-case: var(--font-font-case-uppercase);
    --type-scale-button-sm-font-family: var(--font-font-family-base-font-sans);
    --type-scale-button-sm-font-weight: var(--font-font-weight-bold);
    --type-scale-heading-2xl-font-family: var(--font-font-family-base-font-sans);
    --type-scale-heading-2xl-font-weight: var(--font-font-weight-bold);
    --type-scale-heading-3xl-font-family: var(--font-font-family-base-font-sans);
    --type-scale-heading-3xl-font-weight: var(--font-font-weight-bold);
    --type-scale-heading-4xl-font-family: var(--font-font-family-base-font-sans);
    --type-scale-heading-4xl-font-weight: var(--font-font-weight-bold);
    --type-scale-heading-5xl-font-family: var(--font-font-family-base-font-sans);
    --type-scale-heading-5xl-font-weight: var(--font-font-weight-bold);
    --type-scale-heading-lg-font-family: var(--font-font-family-base-font-sans);
    --type-scale-heading-lg-font-weight: var(--font-font-weight-bold);
    --type-scale-heading-md-font-family: var(--font-font-family-base-font-sans);
    --type-scale-heading-md-font-weight: var(--font-font-weight-bold);
    --type-scale-heading-sm-font-family: var(--font-font-family-base-font-sans);
    --type-scale-heading-sm-font-weight: var(--font-font-weight-bold);
    --type-scale-heading-xl-font-family: var(--font-font-family-base-font-sans);
    --type-scale-heading-xl-font-weight: var(--font-font-weight-bold);
  }
}
@media (min-width: $md) {
  :root {
    /* number */
    --type-scale-body-lg-letter-spacing: var(--font-letter-spacing-0);
    --type-scale-body-lg-font-size: var(--font-font-size-16);
    --type-scale-body-lg-paragraph-spacing: var(--font-paragraph-spacing-16);
    --type-scale-body-lg-line-height: var(--font-line-height-24);
    --type-scale-body-md-letter-spacing: var(--font-letter-spacing-0);
    --type-scale-body-md-font-size: var(--font-font-size-14);
    --type-scale-body-md-paragraph-spacing: var(--font-paragraph-spacing-16);
    --type-scale-body-md-line-height: var(--font-line-height-20);
    --type-scale-body-sm-letter-spacing: var(--font-letter-spacing-0_5);
    --type-scale-body-sm-font-size: var(--font-font-size-12);
    --type-scale-body-sm-line-height: var(--font-line-height-16);
    --type-scale-body-sm-paragraph-spacing: var(--font-paragraph-spacing-16);
    --type-scale-button-lg-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-button-lg-letter-spacing: var(--font-letter-spacing-0_5);
    --type-scale-button-lg-font-size: var(--font-font-size-16);
    --type-scale-button-lg-line-height: var(--font-line-height-24);
    --type-scale-button-md-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-button-md-letter-spacing: var(--font-letter-spacing-0_5);
    --type-scale-button-md-font-size: var(--font-font-size-14);
    --type-scale-button-md-line-height: var(--font-line-height-24);
    --type-scale-button-sm-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-button-sm-letter-spacing: var(--font-letter-spacing-0_5);
    --type-scale-button-sm-font-size: var(--font-font-size-12);
    --type-scale-button-sm-line-height: var(--font-line-height-16);
    --type-scale-heading-2xl-letter-spacing: var(--font-letter-spacing-0);
    --type-scale-heading-2xl-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-heading-2xl-font-size: var(--font-font-size-28);
    --type-scale-heading-2xl-line-height: var(--font-line-height-40);
    --type-scale-heading-3xl-letter-spacing: var(--font-letter-spacing-0);
    --type-scale-heading-3xl-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-heading-3xl-font-size: var(--font-font-size-32);
    --type-scale-heading-3xl-line-height: var(--font-line-height-48);
    --type-scale-heading-4xl-letter-spacing: var(--font-letter-spacing--0_5);
    --type-scale-heading-4xl-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-heading-4xl-font-size: var(--font-font-size-40);
    --type-scale-heading-4xl-line-height: var(--font-line-height-56);
    --type-scale-heading-5xl-letter-spacing: var(--font-letter-spacing--1_5);
    --type-scale-heading-5xl-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-heading-5xl-font-size: var(--font-font-size-48);
    --type-scale-heading-5xl-line-height: var(--font-line-height-64);
    --type-scale-heading-lg-letter-spacing: var(--font-letter-spacing-0);
    --type-scale-heading-lg-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-heading-lg-font-size: var(--font-font-size-20);
    --type-scale-heading-lg-line-height: var(--font-line-height-28);
    --type-scale-heading-md-letter-spacing: var(--font-letter-spacing-0);
    --type-scale-heading-md-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-heading-md-font-size: var(--font-font-size-16);
    --type-scale-heading-md-line-height: var(--font-line-height-24);
    --type-scale-heading-sm-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-heading-sm-letter-spacing: var(--font-letter-spacing-0_5);
    --type-scale-heading-sm-font-size: var(--font-font-size-14);
    --type-scale-heading-sm-line-height: var(--font-line-height-20);
    --type-scale-heading-xl-letter-spacing: var(--font-letter-spacing-0);
    --type-scale-heading-xl-paragraph-spacing: var(--font-paragraph-spacing-null);
    --type-scale-heading-xl-font-size: var(--font-font-size-24);
    --type-scale-heading-xl-line-height: var(--font-line-height-32);
    /* string */
    --type-scale-body-lg-font-family: var(--font-font-family-base-font-sans);
    --type-scale-body-lg-font-weight: var(--font-font-weight-medium);
    --type-scale-body-md-font-family: var(--font-font-family-base-font-sans);
    --type-scale-body-md-font-weight: var(--font-font-weight-medium);
    --type-scale-body-sm-font-family: var(--font-font-family-base-font-sans);
    --type-scale-body-sm-font-weight: var(--font-font-weight-medium);
    --type-scale-button-lg-font-case: var(--font-font-case-uppercase);
    --type-scale-button-lg-font-family: var(--font-font-family-base-font-sans);
    --type-scale-button-lg-font-weight: var(--font-font-weight-bold);
    --type-scale-button-md-font-case: var(--font-font-case-uppercase);
    --type-scale-button-md-font-family: var(--font-font-family-base-font-sans);
    --type-scale-button-md-font-weight: var(--font-font-weight-bold);
    --type-scale-button-sm-font-case: var(--font-font-case-uppercase);
    --type-scale-button-sm-font-family: var(--font-font-family-base-font-sans);
    --type-scale-button-sm-font-weight: var(--font-font-weight-bold);
    --type-scale-heading-2xl-font-family: var(--font-font-family-base-font-sans);
    --type-scale-heading-2xl-font-weight: var(--font-font-weight-bold);
    --type-scale-heading-3xl-font-family: var(--font-font-family-base-font-sans);
    --type-scale-heading-3xl-font-weight: var(--font-font-weight-bold);
    --type-scale-heading-4xl-font-family: var(--font-font-family-base-font-sans);
    --type-scale-heading-4xl-font-weight: var(--font-font-weight-bold);
    --type-scale-heading-5xl-font-family: var(--font-font-family-base-font-sans);
    --type-scale-heading-5xl-font-weight: var(--font-font-weight-bold);
    --type-scale-heading-lg-font-family: var(--font-font-family-base-font-sans);
    --type-scale-heading-lg-font-weight: var(--font-font-weight-bold);
    --type-scale-heading-md-font-family: var(--font-font-family-base-font-sans);
    --type-scale-heading-md-font-weight: var(--font-font-weight-bold);
    --type-scale-heading-sm-font-family: var(--font-font-family-base-font-sans);
    --type-scale-heading-sm-font-weight: var(--font-font-weight-bold);
    --type-scale-heading-xl-font-family: var(--font-font-family-base-font-sans);
    --type-scale-heading-xl-font-weight: var(--font-font-weight-bold);
  }
}
:root {
  /* color */
  --opacity-high-contrast-on-dark: #ffffff33;
  --opacity-high-contrast-on-light: #0b0c0d33;
  --opacity-low-contrast-on-dark: #ffffff0d;
  --opacity-low-contrast-on-light: #0b0c0d0d;
  --opacity-medium-contrast-on-dark: #ffffff1a;
  --opacity-medium-contrast-on-light: #0b0c0d1a;
  --opacity-modal-constrast: #0b0c0d66;
  --opacity-no-contrast-on-dark: #ffffff00;
  --opacity-no-contrast-on-light: #0b0c0d00;
}
:root {
  /* number */
  --small: var(--scale-6);
  --medium: var(--scale-12);
  --large: var(--scale-24);
  --round: var(--scale-9999);
}
:root {
  /* number */
  --spacing-3xs: var(--scale-4);
  --spacing-2xs: var(--scale-6);
  --spacing-xs: var(--scale-8);
  --spacing-sm: var(--scale-16);
  --spacing-md: var(--scale-24);
  --spacing-lg: var(--scale-32);
  --spacing-xl: var(--scale-40);
  --spacing-xxl: var(--scale-48);
  --spacing-xxxl: var(--scale-64);
}
:root {
  /* number */
  --size-xxs: var(--scale-4);
  --size-xs: var(--scale-8);
  --size-sm: var(--scale-16);
  --size-md: var(--scale-24);
  --size-lg: var(--scale-32);
  --size-xl: var(--scale-40);
  --size-2xl: var(--scale-48);
  --size-3xl: var(--scale-64);
  --size-4xl: var(--scale-72);
  --size-5xl: var(--scale-80);
  --size-6xl: var(--scale-108);
  --size-7xl: var(--scale-128);
}
