$spacing_none: 0px;
$spacing_minuscule: 2px;
$spacing_tiny: 4px;
$spacing_small: 8px;
$spacing_small_to_medium: 12px;
$spacing_medium: 16px;
$spacing_medium_to_large: 20px;
$spacing_large: 24px;
$spacing_large_to_huge: 32px;
$spacing_huge: 40px;
