@import '@pray/shared/components/foundations';

.formField {
  margin-top: 20px;

  &:first-of-type {
    margin-top: 8px;
  }

  [data-id='inputWrap'] {
    & > div {
      z-index: 2;
      color: #6c6e73;
    }

    &[class*='disabled'] {
      background-color: #eeeef0;
    }
  }

  $img_size: 132px;

  [data-id='imageFileWrap'] {
    img {
      width: $img_size;
      height: auto;
      max-height: $img_size;
    }
  }

  &.videoThumb {
    [data-id='imageFileWrap'] {
      img {
        width: $img_size;
        height: calc($img_size / 16 * 9);
        object-fit: cover;
      }
    }
  }
}

.scheduleDateTimeGroup {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-top: 20px;

  .scheduleDate {
    margin-right: 20px;
    width: 50%;
  }

  .scheduleTime {
    width: 50%;
  }
}
