@import '../../foundations';

:root {
  --textinput-color-border-primary-default: var(--color-gray-300);
  --textinput-color-border-primary-active: var(--color-gray-900);
}

*:-webkit-autofill,
*:-webkit-autofill:active,
*:-webkit-autofill:hover,
*:-webkit-autofill:focus {
  background-color: transparent !important;
  -webkit-text-fill-color: $text_primary !important;
  -webkit-box-shadow: 0 0 0 40px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.inputWrap {
  border: 1px solid $border_field;
  background-color: $bg_field;
  box-sizing: border-box;
  border-radius: $radius_small;
  height: 48px;
  padding: 6px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &.disabled {
    opacity: 0.5;
  }

  &.withLeftIcon {
    padding-left: 44px;
  }

  &.withRightIcon {
    padding-right: 44px;
  }

  &.withoutStatusIndicator {
    padding-right: 16px;
  }
}

.inputWrap:hover,
.inputWrapActive {
  border: 1px solid $border_field_hover;
  box-shadow: 0 0 0 1px $border_field_hover;
}

.inputSelectWrap {
  padding: 6px 4px 6px 12px;
}

.inputWrapError,
.inputWrapError:hover {
  border-color: $attention;
}

.input {
  border: none;
  padding: 8px 0;
  font-family: var(--font-family-default);
  font-size: var(--font-size-3);
  line-height: var(--font-line-height-3);
  color: $text_primary;
  outline: none;
  background: transparent;

  &[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  &.withRightIcon {
    padding-right: 32px;
  }
}

.withLabel {
  position: relative;
  top: -15px;
  z-index: 1;
}

.floating .input {
  top: 6px;
}

.placeholder {
  position: absolute;
  z-index: 1;
  text-align: left;
  font-family: var(--font-family-default);
  font-weight: var(--font-weight-medium);
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.18px;
  color: $text_primary;
  transition: ease-in-out 100ms;
  top: 12px;
}

.floating .placeholder {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: $text_primary;
  top: 3px;
}

.select {
  .input {
    top: 2px;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background: url(./assets/images/arrow-down.svg) no-repeat;
    background-size: 12px;
    background-position: 98%;
    background-repeat: no-repeat;
    height: 100%;
  }

  .placeholder {
    top: 12px;
  }

  &.floating {
    .placeholder {
      top: 0;
    }
  }
}

.textarea {
  height: auto;

  .input {
    padding-top: 8px;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.3);
      border-radius: 6px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 6px;
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .placeholder {
    height: 16px;
    top: 6px;
  }

  textarea {
    resize: none;
    margin-right: -6px;
    padding-right: 8px;

    &.withRightIcon {
      padding-right: 24px;
    }
  }

  &.floating {
    .placeholder {
      top: 3px;
      display: block;
      width: calc(100% - 32px);
      background-color: inherit;
    }
  }

  .indicator {
    top: 10px;
  }
}

.leftIcon {
  position: absolute;
  left: 12px;
}

.rightDetails {
  position: absolute;
  right: 18px;
  bottom: 8px;
  z-index: 2;
}

.rightIcon {
  position: absolute;
  right: 12px;
  bottom: 12px;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 24px;
  width: 24px;
}

.indicator {
  display: flex;
  position: absolute;
  right: 12px;
  top: 16px;
}

.success {
  width: 16px;
  height: 16px;
}

span.error {
  @include typography_caption_small;
  margin-top: 4px;
  font-weight: 500;
  color: $attention;
  margin-left: 12px;

  a {
    color: inherit;
    text-decoration: underline;
  }
}

span.helperText {
  @include typography_caption_small;
  margin-top: 4px;
  color: #6c6e73;
  padding: 0 12px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clearButton {
  bottom: initial;
  right: 2px;
}
