@import '../variables';

.button {
  display: inline-block;
  padding: 8px 16px;
  outline: none;
  border: 1px solid transparent;
  border-radius: var(--radius-small);
  background-color: transparent;
  font-family: var(--font-font-family-base-font-sans);
  color: var(--buttons-color-text-button-tertiary);
  cursor: pointer;
  @include typography_button_medium;

  [data-spinner] {
    border-color: var(--color-text-primary);
  }

  &:focus {
    outline: 2px solid var(--buttons-color-border-button-focus);
    outline-offset: 2px;
  }

  &.full {
    width: 100%;
  }

  .button-tooltip {
    display: none;
    position: absolute;
    padding: 3px 8px;
    left: 50%;
    transform: translate(-50%, -175%);
    background-color: var(--color-bg-primary);
    border-radius: var(--radius-small);
    color: var(--color-text-primary);
    text-transform: none;
    font-weight: normal;
    white-space: nowrap;
    @include typography_caption_medium;
  }

  &:active {
    outline: none;
  }

  &:hover {
    .button-tooltip {
      display: block;
    }
  }

  &:disabled,
  &[disabled] {
    color: var(--buttons-color-text-button-disabled);
    background-color: var(--buttons-color-bg-button-disabled);

    &.tertiary {
      background-color: var(--buttons-color-bg-button-tertiary-enabled);
    }

    & * {
      opacity: 0.4;
    }
  }

  &:not(:disabled):not([disabled]) {
    &.primary {
      color: var(--buttons-color-text-button-primary);
      border-color: var(--buttons-color-bg-button-primary-enabled);
      background-color: var(--buttons-color-bg-button-primary-enabled);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);

      &:hover {
        background-color: var(--buttons-color-bg-button-primary-hover);
      }

      &.pray_featured {
        border-color: var(--pray-featured-color);
        background-color: var(--pray-featured-color);
      }

      [data-spinner] {
        border-color: var(--buttons-color-text-button-primary);
      }
    }

    &.secondary {
      color: var(--buttons-color-text-button-secondary);
      border-color: var(--buttons-color-border-button-secondary-enabled);
      background-color: var(--buttons-color-bg-button-secondary-enabled);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);

      &:hover {
        border-color: var(--buttons-color-border-button-secondary-hover);
        background-color: var(--buttons-color-bg-button-secondary-hover);
      }

      &.pray_featured {
        border-color: var(--pray-featured-color);
        color: var(--pray-featured-color);
      }

      [data-spinner] {
        border-color: var(--buttons-color-text-button-secondary);
      }
    }

    &.tertiary {
      color: var(--buttons-color-text-button-tertiary);
      background-color: var(--buttons-color-bg-button-tertiary-enabled);

      &:hover {
        background-color: var(--buttons-color-bg-button-tertiary-hover);
      }

      [data-spinner] {
        border-color: var(--buttons-color-text-button-tertiary);
      }
    }

    &.danger {
      color: var(--buttons-color-text-button-danger);
      border-color: var(--buttons-color-border-button-danger-enabled);
      background-color: var(--buttons-color-bg-button-danger-enabled);

      &:hover {
        background-color: var(--buttons-color-bg-button-danger-hover);
      }

      [data-spinner] {
        border-color: var(--buttons-color-text-button-danger);
      }
    }
  }

  &.xsmall {
    padding: 8px 12px;
    @include typography_button_xsmall;
  }

  &.small {
    padding: 12px;
    @include typography_button_small;
  }

  &.medium {
    padding: 12px 16px;
    @include typography_button_medium;
  }

  &.large {
    padding: 16px;
    @include typography_button_large;
  }
}
