@import '@pray/shared/components/foundations';

.tabActions {
  display: none;
}

.section {
  margin-top: 16px;
  $sectionHeight: calc(100vh - 340px);
  min-height: $sectionHeight;
  position: relative;

  .dropzone {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@media (min-width: $screen_medium) {
  .tabActions {
    display: flex;

    .bulkActions {
      display: flex;
      align-items: center;

      .selectedText {
        @include typography_headline_small;
        color: #6c6e73;
      }

      button {
        display: inline-flex;
        align-items: center;
        margin-left: 16px;

        svg {
          height: 20px;
          width: 20px;
        }

        span {
          display: none;
        }
      }

      .verticalSeparator {
        width: 1px;
        height: 40px;
        margin: 0 16px;
        border-right: 1px solid #e8e8e8;
      }
    }
  }
}

@media (min-width: 1200px) {
  .tabActions {
    .bulkActions {
      button {
        svg {
          margin-right: 8px;
        }

        span {
          display: initial;
        }
      }
    }
  }
}
