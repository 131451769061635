@import '@pray/shared/components/foundations';

.uploadButton {
  width: 273px;
  display: flex;
  justify-content: center;
  align-self: center;
}

.hiddenInput {
  display: none;
}

.selectedFile {
  width: 100%;
  padding: 10px;
  background-color: #f2f3f4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;

  .filePreviewWrapper {
    display: flex;
    align-items: center;

    img {
      height: 40px;
      width: 40px;
      border-radius: 6px;
    }

    .fileName {
      margin-left: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  button[disabled] {
    cursor: not-allowed;
  }
}

.selectedFile.disabledSelectedFile {
  color: #8c8f96;
  cursor: not-allowed;
}

.error {
  @include typography_footnote;
  margin-top: 4px;
  font-weight: 700;
  color: $attention;
}
