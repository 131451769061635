@import '@pray/shared/components/foundations';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 76px;
}

.headerTitle > span {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
}
