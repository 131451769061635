@import '@pray/shared/components/foundations';

.messageModalWrap {
  text-align: center;
  top: 0;
  right: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  background: #00000090;
  position: fixed;
}

.modal {
  margin: 20vh auto 0;
  width: 90%;
  background: $bg_tertiary;
  border-radius: 10px;
  max-width: 370px;
  position: relative;
}
.modalContent {
  padding: 30px 20px 25px;
}
.message {
  margin: 5px 0 15px;
  font-size: 17px;
  line-height: 20px;
}
.button {
  margin-top: 40px;
}
