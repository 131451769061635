// Overrides on React datepicker
.datePickerContainer {
  width: 100%;

  :global(.react-datepicker-wrapper) {
    width: 100%;
  }

  :global(.react-datepicker) {
    width: 100%;
    font-family: Satoshi, sans-serif;
    color: #0b0c0d;
    background: #fff;
    box-shadow: 0px 0px 40px rgba(69, 71, 69, 0.2);
    border-radius: 16px;
    border: none;
  }

  :global(.react-datepicker__header:not(.react-datepicker__header--has-time-select)) {
    border-top-right-radius: 20em;
    border-top-left-radius: 20em;
  }

  :global(.react-datepicker__header) {
    background-color: #fff;
    border-bottom: none;
  }

  :global(.react-datepicker__day--selected:hover) {
    background-color: #000;
  }

  :global(.react-datepicker__day--keyboard-selected:hover),
  :global(.react-datepicker__day--keyboard-selected) {
    background: none;
  }

  :global(.react-datepicker__day:hover) {
    border-radius: 100%;
  }

  :global(.react-datepicker__day--outside-month) {
    position: relative;
    z-index: -1;
    opacity: 0;
  }

  :global(.react-datepicker__day--selected) {
    border-radius: 100%;
    background-color: #000;
    color: #fff;
  }

  :global(.react-datepicker__year-wrapper) {
    max-width: 400px;
  }

  :global(.react-datepicker__current-month),
  :global(.react-datepicker__current-year) {
    font-size: 16px;
    padding: 16px 0;
  }

  :global(.react-datepicker__monthPicker),
  :global(.react-datepicker__yearPicker) {
    margin-top: 0;
  }

  :global(.react-datepicker__month-text),
  :global(.react-datepicker__year-text) {
    font-size: 16px;
    line-height: 24px;
    border-radius: 6px;
    padding: 6px;
    width: 92px;
    margin: 4px;
  }

  :global(.react-datepicker__month-text--keyboard-selected) {
    background-color: #f2f3f4;
  }

  :global(.react-datepicker__month-text--selected),
  :global(.react-datepicker__year-text--selected) {
    color: #fff;
    background-color: #0b0c0d;
    font-weight: 700;
  }

  :global(.react-datepicker__month-text--today),
  :global(.react-datepicker__year-text--today) {
    font-weight: normal;
  }
}

.calendar {
  padding: 6px;
  font-size: 14px;
  line-height: 20px;
}

.day,
.weekDay {
  text-align: center;
  padding: 8px;
  width: 44px;
  height: 44px;
}

.day {
  font-weight: 500;

  &:focus {
    outline: none !important;
  }
}

.weekDay {
  font-size: 16px;
  font-weight: 700;
  color: #71747b;
}

// Custom Styles
.datePickerInputContainer {
  position: relative;

  .datePickerIcon {
    position: absolute;
    right: 10px;
    top: 16px;
  }

  &.datePickerInputDisabled {
    .datePickerIcon {
      opacity: 0.3;
    }
  }
}
