@import '../../foundations';

.container {
  flex: 1;

  .inputBase {
    display: flex;
    align-items: center;
    gap: 8px;

    position: relative;
    min-height: 50px;

    background-color: var(--textarea-color-bg-primary-default);
    border: 1px solid var(--textarea-color-border-primary-default);
    border-radius: var(--radius-small);
    padding: var(--spacing-2xs) 12px;

    &:focus-within {
      outline: 2px solid var(--textarea-color-border-focus);
      outline-offset: 2px;
    }

    &:not(.disabled) {
      &:hover::before,
      &:focus-within::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: var(--radius-small);
        outline: 2px solid var(--textarea-color-border-primary-active);
      }
    }

    .inputWrap {
      flex: 1;
      display: flex;
      flex-direction: column;

      .labelRow {
        display: flex;
        align-items: center;
        gap: 4px;

        .label {
          color: var(--textarea-color-text-label-weak);
          @include typography_heading_sm;
        }

        .required {
          color: var(--textarea-color-text-error);
          @include typography_heading_sm;
        }
      }

      textarea {
        color: var(--textarea-color-text-input-primary);
        font-family: var(--font-family-default);
        border: none;
        outline: none;
        resize: none;
        background: transparent;
        z-index: 1;

        &::placeholder {
          color: var(--textarea-color-text-label-weak);
        }
      }
    }

    &.disabled {
      border-color: var(--textarea-color-border-primary-disabled);
      background-color: var(--textarea-color-bg-primary-disabled);
      color: var(--textarea-color-text-input-disabled);

      .labelRow {
        .label {
          color: var(--textarea-color-text-label-disabled);
        }

        svg * {
          stroke: var(--textarea-color-text-label-disabled);
        }
      }

      textarea {
        color: var(--textarea-color-text-input-disabled);

        &::placeholder {
          color: var(--textarea-color-text-input-disabled);
        }
      }
    }

    .rightIcon {
      z-index: 1;
      display: flex;
      align-items: flex-end;
      transform: translateY(-25%);
      align-self: flex-end;
    }
  }

  .footer {
    display: flex;
    gap: 8px;
    margin-top: 8px;

    .footerCol {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .errorMessage {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-bottom: 4px;

        font-size: var(--font-size-2);
        font-weight: var(--font-weight-medium);
        color: var(--textarea-color-text-error);
      }

      .description {
        color: var(--textarea-color-text-input-weak);
      }
    }

    .info {
      margin-left: auto;
      text-align: right;
      white-space: nowrap;
    }
  }
}
