.datePickerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 6px;

  .navigationButton {
    padding: 10px;
    border-radius: 100%;
  }

  .title {
    font-size: 17px;
    line-height: 22px;
    font-weight: 700;
    text-transform: none;
  }

  .title,
  .navigationButton {
    &:active,
    &:hover {
      background-color: #f2f3f4;
    }
  }
}
