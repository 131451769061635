@import '@pray/shared/components/foundations';

.testimonialCard {
  padding: 24px;

  border: 1px solid #cbcdd2;
  border-radius: 8px;
}

// Show the actions for the testimonial once the user hovers on the testimonial card
.testimonialCard:hover .header .actions {
  display: flex;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 16px;

  .infoBlock {
    display: flex;
    align-items: center;
  }

  .actions {
    display: none;
    align-items: center;

    .deleteButton {
      padding: 8px;
    }
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 16px;
    object-fit: cover;
  }

  .info {
    display: flex;
    flex-direction: column;

    .subtitle {
      display: flex;

      .sentiment {
        padding-left: 8px;
        border-left: 1px solid #cbcdd2;
        margin-left: 8px;
      }
    }
  }
}

.testimonial {
  .title {
    margin-bottom: 7px;
  }
}

.testimonialBadge {
  font-weight: 700;
  text-transform: capitalize !important;

  padding: 2px 6px;
  border-radius: 4px;
}

.testimonialBadge.positiveTestimonialBadge {
  color: #188149;
  background-color: #ecf9ed;
}

.testimonialBadge.negativeTestimonialBadge {
  color: #e71d20;
  background-color: #fef0f0;
}

.testimonialBadge.neutralTestimonialBadge {
  color: #3a3c40;
  background-color: #cbcdd2;
}

.readMoreButton .readMoreButtonText {
  font-weight: 500;
  text-decoration: underline;
  line-height: 24px;
}
