@import '@pray/shared/components/foundations/tokens';
@import '@pray/shared/components/foundations/theme';

/* General styles */

* {
  box-sizing: border-box;
}

body {
  background-color: $bg_primary;
}

.custom-admin-background {
}
