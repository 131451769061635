@import '@pray/shared/components/foundations';

.live {
  position: absolute;
  top: 26px;
  left: 26px;
  background: rgba(0, 0, 0, 0.45);
  border-radius: 20px;
  z-index: 1;
  padding: 4px 8px;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    background-color: $attention;
    border-radius: 100%;
    margin-right: 6px;
  }
}
