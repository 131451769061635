@import '../../foundations';

a.iconButton,
button.iconButton {
  background: none;
  outline: none;
  border: 0;
  cursor: pointer;
  position: relative;
  border-radius: $radius_huge;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px;

  &:disabled {
    background: transparent;
  }

  &.md {
    width: 40px;
    height: 40px;
  }

  &.lg {
    width: 60px;
    height: 60px;
  }
}
