@import '@pray/shared/components/foundations';
.fullscreen {
  position: fixed;
  left: 0;
  bottom: env(safe-area-inset-bottom);
  right: 0;
  top: 0;
  background-color: $bg_primary;
  z-index: 90;
  overflow: hidden;
  transition: 0.2s ease-in;

  display: flex;
  flex-direction: column;
}

.appBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  height: 75px;
  padding: 0 16px;
}

.appBarButton {
  display: block;
  padding: 4px 0 0 0;
  border-radius: $radius_huge;
  background: rgba($pray_primary, 0.5);
}

.imageContainer {
  flex: 1;
  min-height: 0;
  display: flex;
  align-items: center;
  padding: 24px 24px 0;
}

.contentImage {
  max-width: min(344px, 100%);
  max-height: min(344px, 100%);
  margin: 0 auto;
  border-radius: $radius_small;
  object-fit: cover;
  aspect-ratio: 1;
}

.bottom {
  padding: 24px;
}

@media (min-width: 768px) {
  .fullscreen {
    background: rgba(11, 12, 13, 0.8);
    backdrop-filter: blur(160px);
  }

  .appBar {
    padding: 0 24px;
  }

  .appBarButton {
    padding: initial;
    border-radius: 100px;
  }

  .imageContainer {
    margin-bottom: 60px;
  }

  .bottom {
    padding: 32px 40px;
  }
}

@media (min-width: 992px) {
  .appBar {
    height: 80px;
    padding: 0 32px;
  }

  .contentImage {
    max-width: 242px;
    max-height: 242px;
  }
}

@media (min-width: 1024px) {
  .appBarButton.close {
    visibility: hidden;
  }

  .bottom {
    padding: 32px 64px;
  }
}

@media (min-width: 1400px) {
  .controlsAndActions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .contentImage {
    max-width: min(344px, 100%);
    max-height: min(344px, 100%);
  }
}
