@import '@pray/shared/components/foundations';

.rowActions {
  display: flex;

  button {
    margin-left: 10px;

    &.remove {
      color: $attention;
      border-color: $attention;
    }
  }
}
