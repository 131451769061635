@import '@pray/shared/components/foundations';

a.actionButton,
button.actionButton {
  background-color: #fff;
  border-radius: 50%;
  padding: 8px;
}

button.addDescription:hover {
  text-decoration: underline;
  color: $pray_secondary;
}