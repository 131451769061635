@import '@pray/shared/components/foundations';

.social-auth__container {
  position: relative;
  margin-top: 24px;
  padding-top: 24px;

  .social-auth__top-label {
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;

    span {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: #d7d8da;

      &::before,
      &::after {
        content: '';
        border-top: 2px solid rgba(255, 255, 255, 0.1);
        flex: 1;
      }

      &::before {
        margin-right: 16px;
      }

      &::after {
        margin-left: 16px;
      }
    }
  }

  &.compact {
    .social-auth__buttons {
      flex-direction: row;

      button {
        flex: 1;

        &:not(:first-of-type) {
          margin-left: 8px;
        }

        &:not(:last-of-type) {
          margin-right: 8px;
        }

        img {
          margin-right: 0;
        }
      }
    }
  }

  &.hideTopLabel {
    margin-top: 0;
    padding-top: 0;
  }

  .social-auth__buttons {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;
      background-color: rgba(255, 255, 255, 0.15);
      height: 48px;

      img {
        margin-right: 8px;
      }
    }
  }

  .social-auth__error {
    @include typography_caption_small;
    color: $attention;
    margin-bottom: 12px;
  }
}
