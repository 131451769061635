.theme-light {
  --color-pray_primary: #ffffff;
  --color-pray_secondary: #0b0c0d;
  --color-pray_featured: #e3af4a;

  // background
  --color-bg_primary: #ffffff;
  --color-bg_secondary: #18191c;
  --color-bg_secondary_opacity_50: #18191c50;
  --color-bg_tertiary: #ffffff;
  --color-bg_field: #ffffff;

  // overlays
  --color-overlay_bg_primary: #0b0c0d45;
  --color-overlay_bg_secondary: #0b0c0d20;
  --color-overlay_bg_featured: #e3af4a20;

  // text
  --color-text_primary: #0b0c0d;
  --color-text_secondary: #ffffff;
  --color-text_tertiary: #0b0c0d60;
  --color-text_featured: #e3af4a;
  --color-text_muted: #6c6e73;

  // borders
  --color-border_divider: #2c2e33;
  --color-border_highlight: #0b0c0d;
  --color-border_covers: #0b0c0d10;
  --color-border_field: #8c8f96;
  --color-border_field_hover: #0b0c0d;

  // status
  --color-attention: #ff5b59;
  --color-success: #0dce94;

  // icons
  --color-icon_primary: #0b0c0d;
  --color-icon_secondary: #3c3c3d;
}