@import '../../foundations';

.placeholder {
  height: 75px;
}

.primaryAppBar {
  background: rgba(11, 12, 13, 0.8) !important;
  backdrop-filter: blur(80px);
}

.appbar {
  position: relative;
  height: 75px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: $bg_primary;
  transition: all 300ms linear;

  &.absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }

  &.transparent {
    background: transparent;
  }

  &.translucent {
    background: linear-gradient(180deg, $bg_primary 50.67%, rgba(11, 12, 13, 0) 100%);
  }

  .left {
    position: absolute;
    left: 0;
  }

  .center {
    margin: 0 auto;
  }

  .right {
    position: absolute;
    right: 0;
    padding-right: 16px;
  }
}

@media (min-width: $md) {
  .appbar {
    .left {
      padding-left: 24px;
    }

    .right {
      padding-right: 24px;
    }
  }
}
