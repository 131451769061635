html {
  font-family: Muli, Helvetica, Inter, Poppins, sans-serif;
  -webkit-tap-highlight-color: transparent;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

input {
  font-size: 17px;
  padding: 12px;
  margin: 5px 0;
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-sizing: border-box;
  appearance: none;
}

input:focus {
  outline: none;
}

input::placeholder {
  color: #b5bbc7;
}

button:disabled,
button[disabled] {
  opacity: 0.5;
}

img:not([src]) {
  visibility: hidden;
}

body.light,
.theme-light {
  color: #0b0c0d;
  background-color: #fbfcff;
}

body.dark,
.theme-dark {
  color: #ffffff;
  background-color: #0b0c0d;
}
