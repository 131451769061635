@import '@pray/shared/components/foundations';

button.backButton {
  display: inline-flex;
  align-items: center;
  margin-left: -16px;
  margin-bottom: 16px;

  * {
    @include typography_headline_small;
    font-weight: 500;
    text-transform: none;
    color: #636363;
  }

  svg {
    margin-right: 8px;
  }
}

.header {
  display: flex;
  align-items: center;

  .image {
    width: 108px;
    height: 162px;
    border-radius: 10px;
    object-fit: cover;
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding: 0 24px;

    .titleWrap {
      display: flex;
      align-items: center;

      .title {
        @include typography_hero;
        @include multiline_ellipsis(1);
        max-width: 640px;
        font-weight: 700;
      }
    }

    .description {
      max-width: 640px;
      font-weight: 500;
      font-size: 17px;
      line-height: 22px;
      color: #707070;
      @include multiline_ellipsis(1);
      margin: 4px 0 8px 0;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .deleteButton {
      width: 92px;
      border-color: #e11900;
      color: #e11900;
    }
  }
}

.section {
  $sectionHeight: calc(100vh - 350px);
  min-height: $sectionHeight;
  margin-bottom: 0;

  .message {
    display: flex;
    align-items: center;
    background-color: #eff3fe;
    border: 1px solid #a0bff8;
    border-radius: 6px;
    padding: 16px;
    margin-top: 16px;

    .icon {
      margin-right: 16px;
      color: #276ef1;
    }
  }

  button.publishButton,
  button.unpublishButton {
    width: 90px;
    margin-left: 16px;
    justify-content: center;
  }

  button.publishButton {
    background-color: #0b0c0d !important;
    color: white;
  }
}

span.status {
  display: inline-flex;
  align-items: center;

  &::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #a6a8ad;
    margin-right: 6px;
    border-radius: 100%;
  }

  &.published {
    &::before {
      background-color: #048848;
    }
  }
}
