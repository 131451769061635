@import '@pray/shared/components/foundations';

@media (min-width: $xs) {
  .container {
    .modal {
      padding: 16px;

      .viewport {
        margin-top: 8px;
        max-width: 720px;

        .title {
          margin-bottom: 16px;
          @include typography_headline_medium;
          @include multiline_ellipsis(2);
        }

        [data-vjs-player] {
          padding-top: 75%;
        }
      }
    }
  }
}

@media (min-width: $md) {
  .container {
    .modal {
      width: 720px;

      .viewport {
        .title {
          @include typography_h2;
        }
      }
    }
  }
}

@media (min-width: $lg) {
  .container {
    .modal {
      padding: 32px;

      .viewport {
        margin-top: 0px;
      }
    }
  }
}
