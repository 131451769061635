@import '@pray/shared/components/foundations';

.eventsCalendarWrapper {
  @include typography_subhead_small;

  .navigationActionableRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 10px;

    .calendarNavigationWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .todayButton {
        margin: 0 10px;
        font-size: 10px;
        padding: 2px 13px;
        font-weight: 700;
        border: 1px solid #0b0c0d;
      }

      .currentMonthYearText {
        font-size: 26px;
        font-weight: 700;
        margin-right: 10px;
      }
    }
  }

  .eventsCalendarSelectContainer {
    display: flex;
  }

  .eventsCalendarSelectContainer .eventsCalendarSelectWrapper:first-child {
    margin-left: 0;
  }

  .eventsCalendarSelectWrapper {
    background-color: #f2f3f4;
    padding: 8px 15px;
    border-radius: 100px;
    margin-left: 21px;

    .eventsCalendarSelectLabel {
      text-align: right;
      font-weight: 500;
      font-size: 15px;
    }

    .eventsCalendarSelectInput {
      font-family: Satoshi, sans-serif;
      margin-left: 8px;
      background: none;
      appearance: none;
      outline: none;
    }
  }

  .tableWrapper {
    position: relative;

    .spinnerWrapper {
      height: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .calendarTable.withHourLabels {
    width: calc(100% - 60px);
    margin-left: 60px;
  }

  .calendarTable {
    width: 100%;
    line-height: 16px;
    table-layout: fixed;
    border-collapse: collapse;

    .calendarTableRow {
      height: 136px;
      text-align: right;
      border: 1px solid #dbdbdb;
      overflow: hidden;

      .calendarHourTableRowLabel {
        position: absolute;
        left: -60px;
        top: -10px;
        font-family: Satoshi;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: #808389;
      }

      .calendarTableRowItem::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      .calendarTableRowItem {
        overflow: hidden;
        width: 14.28%;
        vertical-align: baseline;
        padding: 5px 3px;
        border: 1px solid #dbdbdb;
        font-size: 13px;
        font-weight: 500;
        transition: background-color 0.2s ease-in-out;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &:hover {
          background-color: #f2f3f4;
        }

        .wrapperTd {
          height: 100px;
          margin-top: 5px;
        }

        .eventsMoreItems {
          text-align: left;
          width: 100%;
          margin-top: 5px;
          padding: 0;

          &Text {
            @include typography_subhead_small;
            font-size: 13px;
            font-weight: 700;
            color: #0b0c0d;
            opacity: 0.8;
            margin-left: 5px;

            &:hover {
              opacity: 1;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }

        .calendarTableEventItem {
          position: relative;
          text-align: left;
          background-size: 125%;
          border-radius: 6px;
          margin-top: 2px;
          color: #fff;
          cursor: pointer;

          .calendarTableEventItemText {
            background-color: rgba(0, 0, 0, 0.4);
            backdrop-filter: blur(20px);
            border-radius: 6px;
            padding: 8px;
            text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.55);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 500;
          }

          .calendarTableEventItemArtistBubble {
            $artist_bubble_size: 24px;
            z-index: 1;
            position: absolute;
            right: 4px;
            top: calc(50% - ($artist_bubble_size / 2));

            img {
              width: $artist_bubble_size;
              height: $artist_bubble_size;
              border-radius: $artist_bubble_size / 2;
              border: solid 1px rgba(255, 255, 255, 0.6);
            }
          }
        }
      }
    }

    .calendarTableRow.rowForHour {
      position: relative;
      height: 56px;
      text-align: right;
      border: 1px solid #dbdbdb;
      overflow: hidden;

      .calendarTableRowItem {
        overflow: hidden;
        width: 100%;
        border: 0;
        padding: 0;

        .wrapperTd {
          margin-top: 0;
          height: fit-content;
        }

        .calendarTableEventItem {
          margin-top: 0;

          .calendarTableEventItemText {
            height: 100%;
            display: flex;
            align-items: center;
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }

    .today {
      background-color: #000;
      border-radius: 24px;
      padding: 3px 7px;
      text-align: center;
      color: #fff !important;
    }

    .notCurrent {
      color: #707070;

      &:hover {
        cursor: default;
      }
    }

    .current {
      color: #000;

      &:hover {
        cursor: pointer;
      }
    }

    .calendarTableHeaderItem {
      color: #707070;
      padding-bottom: 10px;
      font-weight: 500;
    }
  }
}

.calendarTimeNowIndicator {
  .calendarTimeNowLabel {
    position: absolute;
    left: -70px;
    top: -10px;

    background-color: #000000;
    padding: 2px 8px;

    border-radius: 20px;

    font-family: Satoshi;
    font-size: 11px;
    font-weight: 700;
    line-height: 16px;
    color: #ffffff;

    z-index: 1;
  }

  .calendarTimeNowIndicatorLine {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 4px;
    background-color: #000000;
    z-index: 99;
  }

  .calendarTimeNowIndicatorCircle {
    position: absolute;
    top: -3px;
    left: -6px;
    width: 10px;
    height: 10px;
    background-color: #000000;
    border-radius: 50%;
  }
}
