@import '@pray/shared/components/foundations';

@media (min-width: $xs) {
  .sidebar {
    display: flex;
    flex-direction: column;
    transition: all ease 300ms;
    width: 100vw;
    border-right: 1px solid #eeeef0;
    transition: all ease 0.1s;

    position: absolute;
    top: 0;
    bottom: 0;
    background-color: white;
    z-index: 90;

    .separator {
      border: none;
      border-bottom: 1px solid #e8e8e8;
    }

    &.collapsed {
      display: none;
    }

    .item {
      margin: 8px;
    }
  }
}

@media (min-width: $md) {
  .sidebar {
    position: relative;
    width: 264px;

    &.collapsed {
      display: block;
    }
  }
}
