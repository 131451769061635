@import '@pray/shared/components/foundations';

.header {
  display: flex;
  padding: 24px 0px 24px 0px;
  gap: 16px;
  
  .image {
    width: 108px;
    height: 108px;
    border-radius: 6px;
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding: 0 24px;
    max-width: 800px;

    .title {
      @include typography_hero;
      font-weight: 700;
      @include multiline_ellipsis(1);

      height: 40px;
      font-size: 28px;
      line-height: 40px;
      color: #0B0C0D;
    }

    .description {
      @include multiline_ellipsis(1);
      margin: 4px 0 8px 0;

      height: 24px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #71747B;
    }
  }

  .actions {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 2px;

    .bulkActions {
      display: flex;
      align-items: center;
      gap: 16px;

      .selectedText {
        color: #6c6e73;
        white-space: nowrap;
        @include typography_headline_small;
      }

      button {
        display: inline-flex;
        align-items: center;

        span {
          display: block;
          margin-left: 8px;
          white-space: nowrap;
        }

        svg {
          height: 20px;
          width: 20px;
        }
      }

      .verticalSeparator {
        width: 1px;
        height: 40px;
        margin-left: 16px;
        margin-right: 4px;
        border-right: 1px solid #e8e8e8;
      }
    }
  }
}

.header .actions .bulkActions button span {
  display: none;
}

@media (min-width: 1200px) {
  .header .actions .bulkActions button span {
    display: initial;
  }
}
