@import '@pray/shared/components/foundations';

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.menu {
  position: relative;
  z-index: 20;

  &.disabled {
    pointer-events: none;
  }

  &.itemSeparator {
    .items {
      .itemWrap:not(:first-of-type) {
        border-top: 1px solid #dddddd;
      }
    }
  }

  .items {
    position: absolute;
    right: 0;
    margin-top: 8px;
    background-color: #fff;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.16));
    border-radius: 6px;
    width: max-content;
    display: flex;
    flex-direction: column;
    padding: 8px;

    .itemWrap {
      display: flex;

      a.item,
      button.item {
        flex: 1;
        border-radius: 6px;
        text-align: left;
        padding: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        &:disabled {
          opacity: 0.8;
          color: black;
          background: none;

          &:hover {
            background: none;
          }
        }

        &:hover {
          background: #f2f3f4;
        }

        .itemLabel {
          flex: 1;

          .title {
            @include typography_subhead_small;
            font-weight: 500;
            text-transform: none;
          }

          .description {
            @include typography_body_small;
            display: block;
            text-transform: none;
            color: #888888;
          }
        }
      }
    }
  }
}
