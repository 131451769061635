.disabled {
  .slide-in {
    position: absolute;
    animation: slide-in 0.3s forwards;
    left: 100%;
  }

  @keyframes slide-in {
    99% {
      left: 0;
    }
    100% {
      position: unset;
    }
  }

  .slide {
    position: absolute;
    animation: slide 0.25s forwards;
    left: 0;
  }

  @keyframes slide {
    100% {
      left: 100%;
    }
  }

  .slide-up {
    animation: slide-up 0.5s forwards;
    top: 200vh;
  }

  @keyframes slide-up {
    100% {
      top: initial;
    }
  }

  .slide-down {
    animation: slide-down 0.5s forwards;
    top: initial;
  }

  @keyframes slide-down {
    100% {
      top: 150%;
    }
  }
}
