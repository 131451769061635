span.label {
  padding: 4px 8px;
  border-radius: 100px;

  font-weight: bold;
  font-size: 11px;
  line-height: 16px;

  &.scheduled {
    background-color: var(--color-bg-schedule);
    color: var(--color-text-schedule);
  }

  &.published {
    background-color: var(--color-bg-publish);
    color: var(--color-text-publish);
  }

  &.draft {
    background-color: var(--color-bg-draft);
    color: var(--color-text-draft);
  }

  &.unpublished {
    background-color: var(--color-bg-unpublished);
    color: var(--color-text-unpublished);
  }
}
