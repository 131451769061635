@import '@pray/shared/components/foundations';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  height: 72px;
  min-height: 72px;
  background: $pray_secondary;
  z-index: 100;
}
