.loading {
  margin: 0 auto;
  border-radius: 50%;
  border: 3px solid #fff;
  border-top: 3px solid transparent !important;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

.small {
  border: 2px solid #fff;
  border-top: 2px solid transparent !important;
  width: 16px;
  height: 16px;
}

.medium {
  border: 2px solid #fff;
  border-top: 2px solid transparent !important;
  width: 32px;
  height: 32px;
}

.large {
  border: 4px solid #fff;
  border-top: 4px solid transparent !important;
  width: 48px;
  height: 48px;
}

.dark {
  border: 3px solid #000;
  border-top: 3px solid transparent !important;
}

.purple {
  border: 3px solid #8854f2;
  border-top: 3px solid transparent !important;
}

.gold {
  border: 3px solid #e3af4a66;
  border-top: 3px solid #e3af4a !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
