@import '@pray/shared/components/foundations';

.container {
  background: rgba(0, 0, 0, 0.18);

  .modal {
    width: auto;
    padding: 32px;
    background-color: $pray_primary;

    .viewport {
      margin: 0;
      width: 538px;

      .title {
        margin-bottom: 16px;
        @include typography_h2;
      }

      .formField {
        margin-top: 20px;

        &:first-of-type {
          margin-top: 8px;
        }

        [data-id='inputWrap'] {
          & > div {
            z-index: 2;
            color: #6c6e73;
          }

          &[class*='disabled'] {
            background-color: #eeeef0;
          }
        }

        .typeItem {
          padding: 0 8px;

          &:first-of-type {
            padding-top: 8px;
          }

          &:last-of-type {
            padding-bottom: 8px;
          }

          button {
            margin: 0;
            width: 140px;
            height: 36px;

            div span {
              font-size: 15px !important;
              font-weight: 500 !important;
              line-height: 20px !important;
              text-transform: initial !important;
            }
          }
        }

        .itemName {
          flex: 1;
          @include multiline_ellipsis(2);
        }
      }

      .buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 32px;

        button {
          min-width: 92px;
          margin-left: 16px;
        }
      }
    }
  }
}
