@import '@pray/shared/components/foundations';

.mobilePublishButtonWrap {
  background-color: #fff;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.16);
  padding: 12px 16px;
  display: flex;
  z-index: 5;

  button {
    @include button_large;

    flex: 1;

    span {
      font-weight: 700;
    }
  }
}
