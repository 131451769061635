:root,
.theme-dark {
  --color-pray_primary: #0b0c0d;
  --color-pray_secondary: #ffffff;
  --color-pray_featured: #e3af4a;

  // background
  --color-bg_primary: #0b0c0d;
  --color-bg_secondary: #18191c;
  --color-bg_secondary_opacity_50: #18191c50;
  --color-bg_tertiary: #1f2024;
  --color-bg_field: #1f2024;

  // overlays
  --color-overlay_bg_primary: #00000045;
  --color-overlay_bg_secondary: #ffffff20;
  --color-overlay_bg_featured: #e3af4a20;

  // text
  --color-text_primary: #ffffff;
  --color-text_secondary: #a6a8ad;
  --color-text_tertiary: #0b0c0d;
  --color-text_featured: #e3af4a;

  // borders
  --color-border_divider: #2c2e33;
  --color-border_highlight: #ffffff;
  --color-border_covers: #ffffff10;
  --color-border_field: rgba(255, 255, 255, 0.1);
  --color-border_field_hover: #ebeef5;

  // status
  --color-attention: #ff5b59;
  --color-success: #0dce94;

  // icons
  --color-icon_primary: #ffffff;
  --color-icon_secondary: #3c3c3d;
}