@import '@pray/shared/components/foundations';

span.status {
  display: inline-flex;
  align-items: center;

  &::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #a6a8ad;
    margin-right: 6px;
    border-radius: 100%;
  }

  &.published {
    &::before {
      background-color: #048848;
    }
  }
}

a.actionButton,
button.actionButton {
  background-color: #fff;
  border-radius: 50%;
  padding: 8px;
}
