.volume {
  display: none;
}

.input {
  background-color: rgba(255, 255, 255, 0.2);
  border: 0;
  height: 4px;
  width: 100%;
  display: block;
  -webkit-appearance: none;
  cursor: pointer;
  position: relative;
  margin: 0px;
  overflow: visible;
  outline: none;
  padding: 0;
  -webkit-appearance: none;
}

.input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border: 0;
  display: none;
}

.input[type='range']:hover::-webkit-slider-thumb {
  display: block;
}

/* Тоже самое для Firefox */
.input[type='range']::-moz-range-thumb {
  height: 8px;
  width: 8px;
  border: 0;
  background-color: #ffffff !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  display: none;
}

.input[type='range']:hover::-moz-range-thumb {
  display: block;
}

@media (min-width: 1400px) {
  .volume {
    display: flex;
    align-items: center;
  }

  .volume button {
    padding: 0;
  }

  .volumeBar {
    margin-right: 8px;
    width: 90px;
  }
}
