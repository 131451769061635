// -------------------------------------------------
// caption_small
// -------------------------------------------------

$caption_small_font_size: 11px;
$caption_small_line_height: 16px;

@mixin typography_caption_small {
  font-size: $caption_small_font_size;
  line-height: $caption_small_line_height;
}

// ------------------------------------------------
// caption_link
// ------------------------------------------------

$caption_link_font_size: 11px;
$caption_link_line_height: 16px;
$caption_link_text_transform: initial;

@mixin typography_caption_link {
  font-size: $caption_link_font_size;
  line-height: $caption_link_line_height;
  text-transform: $caption_link_text_transform;
}

// ------------------------------------------------
// caption_medium
// ------------------------------------------------

$caption_medium_font_size: 12px;
$caption_medium_line_height: 16px;

@mixin typography_caption_medium {
  font-size: $caption_medium_font_size;
  line-height: $caption_medium_line_height;
}

// ------------------------------------------------
// footnote
// ------------------------------------------------

$footnote_font_size: 13px;
$footnote_line_height: 18px;
$footnote_text_transform: initial;

@mixin typography_footnote {
  font-size: $footnote_font_size;
  line-height: $footnote_line_height;
  text-transform: $footnote_text_transform;
}

// ------------------------------------------------
// body
// ------------------------------------------------

$body_font_size: 17px;
$body_line_height: 22px;
$body_text_transform: initial;

@mixin typography_body {
  font-size: $body_font_size;
  line-height: $body_line_height;
  text-transform: $body_text_transform;
}

// ------------------------------------------------
// body_small
// ------------------------------------------------

$body_small_font_size: 15px;
$body_small_line_height: 20px;
$body_small_text_transform: initial;

@mixin typography_body_small {
  font-size: $body_small_font_size;
  line-height: $body_small_line_height;
  text-transform: $body_small_text_transform;
}

// ------------------------------------------------
// button_xsmall
// ------------------------------------------------

$button_xsmall_font_weight: var(--type-scale-button-sm-font-weight);
$button_xsmall_font_size: var(--type-scale-button-sm-font-size);
$button_xsmall_line_height: var(--type-scale-button-sm-line-height);

$button_xsmall_letter_spacing: var(--type-scale-button-sm-letter-spacing);
$button_xsmall_text_transform: var(--type-scale-button-sm-font-case);

@mixin typography_button_xsmall {
  font-weight: $button_xsmall_font_weight;
  font-size: $button_xsmall_font_size;
  line-height: $button_xsmall_line_height;

  letter-spacing: $button_xsmall_letter_spacing;
  text-transform: $button_xsmall_text_transform;
}

// ------------------------------------------------
// button_small
// ------------------------------------------------

$button_small_font_weight: var(--type-scale-button-sm-font-weight);
$button_small_font_size: var(--type-scale-button-sm-font-size);
$button_small_line_height: var(--type-scale-button-sm-line-height);

$button_small_letter_spacing: var(--type-scale-button-sm-letter-spacing);
$button_small_text_transform: var(--type-scale-button-sm-font-case);

@mixin typography_button_small {
  font-weight: $button_small_font_weight;
  font-size: $button_small_font_size;
  line-height: $button_small_line_height;

  letter-spacing: $button_small_letter_spacing;
  text-transform: $button_small_text_transform;
}

// ------------------------------------------------
// button_medium
// ------------------------------------------------

$button_medium_font_weight: var(--type-scale-button-md-font-weight);
$button_medium_font_size: var(--type-scale-button-md-font-size);
$button_medium_line_height: var(--type-scale-button-md-line-height);

$button_medium_letter_spacing: var(--type-scale-button-md-letter-spacing);
$button_medium_text_transform: var(--type-scale-button-md-font-case);

@mixin typography_button_medium {
  font-weight: $button_medium_font_weight;
  font-size: $button_medium_font_size;
  line-height: $button_medium_line_height;

  letter-spacing: $button_medium_letter_spacing;
  text-transform: $button_medium_text_transform;
}

// ------------------------------------------------
// button_large
// ------------------------------------------------

$button_large_font_weight: var(--type-scale-button-lg-font-weight);
$button_large_font_size: var(--type-scale-button-lg-font-size);
$button_large_line_height: var(--type-scale-button-lg-line-height);

$button_large_letter_spacing: var(--type-scale-button-lg-letter-spacing);
$button_large_text_transform: var(--type-scale-button-lg-font-case);

@mixin typography_button_large {
  font-weight: $button_large_font_weight;
  font-size: $button_large_font_size;
  line-height: $button_large_line_height;

  // inline-block is required otherwise line height is not respected when container is floated
  display: inline-block;

  letter-spacing: $button_large_letter_spacing;
  text-transform: $button_large_text_transform;
}

// ------------------------------------------------
// button_full
// ------------------------------------------------

$button_full_font_weight: 700;
$button_full_font_size: 13px;
$button_full_line_height: 24px;

$button_full_letter_spacing: 0.5px;
$button_full_text_transform: uppercase;

@mixin typography_button_full {
  font-weight: $button_full_font_weight;
  font-size: $button_full_font_size;
  line-height: $button_full_line_height;

  letter-spacing: $button_full_letter_spacing;
  text-transform: $button_full_text_transform;
}

// ------------------------------------------------
// subhead_small
// ------------------------------------------------

$subhead_small_font_size: 15px;
$subhead_small_line_height: 20px;
$subhead_small_line_text_transform: initial;
$subhead_small_font_weight: 500;

@mixin typography_subhead_small {
  font-size: $subhead_small_font_size;
  line-height: $subhead_small_line_height;
  text-transform: $subhead_small_line_text_transform;
  font-weight: $subhead_small_font_weight;
}

// ------------------------------------------------
// subhead_medium
// ------------------------------------------------

$subhead_medium_font_weight: bold;
$subhead_medium_font_size: 15px;
$subhead_medium_line_height: 20px;
$subhead_medium_line_text_transform: initial;

@mixin typography_subhead_medium {
  font-weight: $subhead_medium_font_weight;
  font-size: $subhead_medium_font_size;
  line-height: $subhead_medium_line_height;
  text-transform: $subhead_medium_line_text_transform;
}

// ------------------------------------------------
// headline_small
// ------------------------------------------------

$headline_small_font_size: 17px;
$headline_small_line_height: 22px;

@mixin typography_headline_small {
  font-size: $headline_small_font_size;
  line-height: $headline_small_line_height;
}

// ------------------------------------------------
// headline_medium
// ------------------------------------------------

$headline_medium_font_weight: bold;
$headline_medium_font_size: 17px;
$headline_medium_line_height: 22px;
$headline_medium_text_transform: initial;

@mixin typography_headline_medium {
  font-weight: $headline_medium_font_weight;
  font-size: $headline_medium_font_size;
  line-height: $headline_medium_line_height;
  text-transform: $headline_medium_text_transform;
}

// ------------------------------------------------
// headline_large
// ------------------------------------------------

$headline_large_font_weight: 700;
$headline_large_font_size: 20px;
$headline_large_line_height: 24px;
$headline_large_text_transform: initial;

@mixin typography_headline_large {
  font-weight: $headline_large_font_weight;
  font-size: $headline_large_font_size;
  line-height: $headline_large_line_height;
  text-transform: $headline_large_text_transform;
}

// ------------------------------------------------
// h2
// ------------------------------------------------

$h2_font_weight: bold;
$h2_font_size: 22px;
$h2_line_height: 30px;

@mixin typography_h2 {
  font-weight: $h2_font_weight;
  font-size: $h2_font_size;
  line-height: $h2_line_height;
}

// ------------------------------------------------
// h1
// ------------------------------------------------

$h1_font_weight: 700;
$h1_font_size: 26px;
$h1_line_height: 34px;

@mixin typography_h1 {
  font-weight: $h1_font_weight;
  font-size: $h1_font_size;
  line-height: $h1_line_height;
}

// ------------------------------------------------
// hero
// ------------------------------------------------

$hero_font_weight: bold;
$hero_font_size: 30px;
$hero_line_height: 36px;

@mixin typography_hero {
  font-weight: $hero_font_weight;
  font-size: $hero_font_size;
  line-height: $hero_line_height;
}

// ------------------------------------------------
// display_small
// ------------------------------------------------

$display_small_font_weight: bold;
$display_small_font_size: 50px;
$display_small_line_height: 62px;

@mixin typography_display_small {
  font-weight: $display_small_font_weight;
  font-size: $display_small_font_size;
  line-height: $display_small_line_height;
}

// ------------------------------------------------
// display_medium
// ------------------------------------------------

$display_medium_font_weight: bold;
$display_medium_font_size: 56px;
$display_medium_line_height: 70px;

@mixin typography_display_medium {
  font-weight: $display_medium_font_weight;
  font-size: $display_medium_font_size;
  line-height: $display_medium_line_height;
}

$display_medium_font_weight: bold;
$display_medium_font_size: 56px;
$display_medium_line_height: 70px;

@mixin typography_display_medium {
  font-weight: $display_medium_font_weight;
  font-size: $display_medium_font_size;
  line-height: $display_medium_line_height;
}

// ------------------------------------------------
// heading_sm
// ------------------------------------------------

$heading_sm_font_weight: var(--type-scale-heading-sm-font-weight);
$heading_sm_font_size: var(--type-scale-heading-sm-font-size);
$heading_sm_line_height: var(--type-scale-heading-sm-line-height);

@mixin typography_heading_sm {
  font-weight: $heading_sm_font_weight;
  font-size: $heading_sm_font_size;
  line-height: $heading_sm_line_height;
}

// ------------------------------------------------
// heading_lg
// ------------------------------------------------

$heading_lg_font_weight: var(--type-scale-heading-lg-font-weight);
$heading_lg_font_size: var(--type-scale-heading-lg-font-size);
$heading_lg_line_height: var(--type-scale-heading-lg-line-height);

@mixin typography_heading_lg {
  font-weight: $heading_lg_font_weight;
  font-size: $heading_lg_font_size;
  line-height: $heading_lg_line_height;
}
