@import '../../foundations';

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}
.modal {
  background-color: $bg_primary;
  color: $pray_secondary;
  padding: 20px;
  box-sizing: border-box;
  position: fixed;
  width: 100vw;
  height: 100vh;
  animation-name: modal-animation;
  animation-duration: 0.35s;
  overflow-y: auto;
}
.close {
  position: absolute;
  top: 16px;
  right: 16px;
}
.viewport {
  max-width: 380px;
  margin: 32px auto 0;
}
.viewportCustom {
  max-width: 380px;
}

.zLevel999 {
  z-index: 999;
}

.large {
  .viewportCustom {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .large {
    .modal {
      width: 1000px;
      padding: 0 0;
    }

    .close {
      top: 10px;
      right: 2px;
    }
  }

  .container {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .modal {
    background-color: $bg_tertiary;
    box-shadow: 0px 1px 4px rgba(25, 37, 77, 0.16), 0px 4px 8px rgba(25, 37, 77, 0.12);
    border-radius: $radius_medium;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: unset;
    width: 500px;
    padding: 36px 24px;
  }
  .viewport,
  .viewportCustom {
    max-width: unset;
  }

  .close {
    top: 16px;
    right: 2px;
  }
}

@keyframes modal-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
