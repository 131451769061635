@import '../../foundations';

.inputWrapper {
  display: flex;
  align-items: center;
  border: 1px solid $border_field;
  background-color: $bg_field;
  border-radius: $radius_small;
  height: 48px;
  padding: 6px 10px;

  & > * {
    &:first-child {
      &:not(:last-child) {
        margin-right: 4px;
      }
    }

    &:last-child {
      &:not(:first-child) {
        margin-left: 4px;
      }
    }
  }

  &:hover,
  &:focus-within {
    border-color: $border_field_hover;
  }

  &:has(:global(.inputError)),
  &:has(:global(.inputError)):hover {
    border-color: $attention;
  }
}
